import React, { useState, useEffect } from 'react';
import PageWrapper from '../layouts/PageWrapper';

import { useParams } from 'react-router';
import {
    IonButton,
    IonLoading,
    useIonViewWillEnter
} from '@ionic/react';

import { useAuth } from '../../context/Auth';
import { fetchDocumentPreview } from '../../services/DocumentServices';
import { getFileUrl } from '../../helpers/DevHelper';

interface Documents {
    id: number;
    type: string;
    createdAt: string;
    related: any;
    title?: string;
    viewItems?: any;
    fileId?: string;
    sub_title?: string;
}

const DocumentsViewPage: React.FC = () => {
    const { checkAxiosError } = useAuth();
    const { id }: { id: string } = useParams();

    const [ document, setDocument ] = useState<Documents>(null);

    useEffect(() => {
        fetchDocumentPreview(id)
            .then(res => {
                if (res.data.data) {
                    setDocument({
                        id: res.data.data.id,
                        type: res.data.data.related_type,
                        createdAt: res.data.data.created_at,
                        related: res.data.data.related,
                        title: res.data.data.mypatent_title,
                        sub_title: res.data.data.mypatent_sub_title??"",
                        fileId: res.data.data.file?.access_hash,
                        viewItems: res.data.data.mypatent_view,
                    });
                }
            }).catch(err => {
                checkAxiosError(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useIonViewWillEnter(() => {
        setDocument(null);
    });

    return (
        <PageWrapper>
            {document !== null 
            ?
                <>
                    <h1 className="pageTitle">{document.title??''}</h1>

                    {document.fileId &&
                        <IonButton href={getFileUrl() + "/" + document.fileId} target="_blank" color="success" expand="block">
                            PDF megnyitása
                        </IonButton>
                    }
                    <br/>

                    {(document.viewItems !== null && document.viewItems.length > 0) &&
                        <>
                            {document.viewItems.map((item: any, key: any) => (
                                <div className="list-between" key={key}>
                                    <span>{item.label??''}</span>
                                    <span>{item.value??''}</span>
                                </div>
                            ))}
                        </>
                    }
                </>
            :
                <IonLoading
                    isOpen={Boolean(document === null)}
                    message={'Dokumentum betöltése...'}
                />
            }

        </PageWrapper>
    );
};

export default DocumentsViewPage;
