import React, { useState } from 'react';
import {
    IonPage,
    IonContent,
    IonGrid,
} from "@ionic/react";

import MyPatentHeader from '../../components/MyPatentHeader';

type Props = {
    children: any;
    className?: string;
}

const PageWrapper: React.FC<Props> = props => {
    const [ isLogoHidden, setIsLogoHidden ] = useState(false);
    const { children, className } = props;

    const getScrollTop = (e: CustomEvent) => {
        if(e.detail.scrollTop >= 60) {
            setIsLogoHidden(true);
        } else {
            setIsLogoHidden(false);
        }
    }

    return (
        <IonPage className={className??""}> 
            <MyPatentHeader isLogoHidden={isLogoHidden} hasMenu={true} />

            <IonContent className="backgroundTransparent" scrollEvents={true} onIonScroll={(e) => getScrollTop(e)}>
                <IonGrid className="ion-padding" id="ionGridInner" fixed>
                    <div className="page-content">
                        {children}
                    </div>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default PageWrapper;
