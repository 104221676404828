const LocalStorage = {
    get(item: string) {
        return localStorage.getItem(item);
    },
    set(item: string, value: string | null) {
        if (value === null) {
            localStorage.removeItem(item);
        } else {
            localStorage.setItem(item, value);
        }
        return value;
    },
}
export default LocalStorage;
