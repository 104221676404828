import React, { useState } from 'react';
import {
    IonItem,
    IonIcon,
    IonLabel,
} from "@ionic/react";

import { chevronUpOutline, chevronDownOutline, ellipse } from 'ionicons/icons';

import './AccordionItem.scss';
import { useNotification } from '../context/Notifications';
import Axios from 'axios';
import { useAuth } from '../context/Auth';
import { postReadMessage } from '../services/GlobalServices';

type Props = {
    children: any;
    uid: string;
    title?: string;
    time?: Date;
    unread: boolean;
    filters?: any;
}

const NotificationAccordionItem: React.FC<Props> = props => {
    const { children, title, time, unread, uid, filters } = props;
    const { authToken } = useAuth();
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isUnread, setIsUnread ] = useState(unread);
    const { removeReadNotification } = useNotification();
    var dateFormat = require('dateformat');

    const handleOpen = (uid: string) => {
        if (filters.filter((filter: any) => filter.key === "softdeletes").length > 0 && 
            filters.filter((filter: any) => filter.key === "softdeletes")[0].value === "1") {
            setIsOpen(!isOpen);

            if(isUnread) {
                postReadMessage(uid)
                    .catch((err: any) => {
                        console.error(err);
                    });
            }
            
            return;
        }

        if(isUnread) {
            setIsUnread(false);
            removeReadNotification(uid);
        }
        setIsOpen(!isOpen);
    }

    return (
        <div className="accordionItemWrapper">
            <IonItem 
                className={"accordionItem " + (isOpen ? "isOpen" : "")} 
                lines="none" 
                button={true} 
                detail={false} 
                onClick={() => handleOpen(uid)}
            >
                <IonIcon icon={ellipse} slot="start" className={isUnread ? 'danger' : 'read'} size="small" />
                <IonLabel>
                    {title && 
                        <h2 className={"title " + (isUnread ? 'danger' : 'read')}>{title??''}</h2>
                    }
                    {time && 
                        <p>{time ? dateFormat(time, 'yyyy.mm.dd HH:MM') : ''}</p>
                    }
                </IonLabel>
                {isOpen 
                ? <IonIcon icon={chevronUpOutline} slot="end" size="small" />
                : <IonIcon icon={chevronDownOutline} slot="end" size="small" />
                }
            </IonItem>
            <div className={"subItem " + (isOpen ? "isOpen" : "")}>
                <div className="subItem-inner">
                    {children??''}
                </div>
            </div>
        </div>
    );
};

export default NotificationAccordionItem;
