import React, { useState } from "react";
import { IonItem, IonIcon, IonLabel, IonText, IonChip } from "@ionic/react";

import { chevronDownOutline, checkmarkCircle, hourglass, warning } from "ionicons/icons";
import NumberFormat from "react-number-format";

import "./AccordionItem.scss";

type Props = {
  children?: any;
  type?: string;
  createdAt?: string;
  docDueDate?: string;
  related: any;
  transactions: any;
  barion_transactions?: any;
};

const InvoiceAccordionItem: React.FC<Props> = ({ children, docDueDate, related, transactions, barion_transactions }) => {
  const [isOpen, setIsOpen] = useState(false);
  var dateFormat = require("dateformat");

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getIcon = () => {
    switch (getStatus()) {
      case "late":
        return warning;
      case "waiting":
        return hourglass;
      default:
        return checkmarkCircle;
    }
  };

  const getColor = () => {
    switch (getStatus()) {
      case "waiting":
        return "warning";
      case "paid":
        return "success";
      case "late":
        return "danger";
      default:
        return "primary";
    }
  };

  const getStatus = () => {
    if (related.U_CPH_PaymentCreated !== undefined && related.U_CPH_PaymentCreated !== null && related.U_CPH_PaymentCreated === "Y") {
      return "paid";
    }

    if (transactions !== undefined && transactions.length > 0 && transactions[0] !== undefined && transactions[0].status === "FINISHED") {
      return "paid";
    }

    if (barion_transactions !== undefined && barion_transactions.length > 0) {
      const succeeded = barion_transactions.filter((t: any) => t.status === "Succeeded").length > 0;
      if (succeeded) {
        return "paid";
      }
    }

    const d1 = new Date();
    const d2 = new Date(related.DocDueDate);
    if (d1 > d2) {
      return "late";
    } else {
      return "waiting";
    }
  };

  return (
    <div className="accordionItemWrapper">
      <IonItem className={"accordionItem " + (isOpen ? "isOpen" : "")} lines="none" button={true} detail={false} onClick={() => handleOpen()}>
        <IonIcon icon={getIcon()} slot="start" color={getColor()} />
        <IonLabel>
          <IonText color="secondary">
            <h2 className="title">
              Számla:
              {" "}
              <NumberFormat value={related.DoCTotal} displayType={"text"} thousandSeparator={" "} suffix={" Ft"} decimalScale={0} />
            </h2>
          </IonText>
          {docDueDate && (
            <IonText color={getColor()}>
              <small>Fizetési határidő: {docDueDate ? dateFormat(docDueDate, "yyyy.mm.dd") : ""}</small>
            </IonText>
          )}
        </IonLabel>
        <IonIcon icon={chevronDownOutline} slot="end" size="small" className="chevronIcon" />
      </IonItem>
      <div className={"subItem " + (isOpen ? "isOpen" : "")}>
        <div className="subItem-inner">{children ?? ""}</div>
      </div>
    </div>
  );
};

export default InvoiceAccordionItem;
