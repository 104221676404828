
import React, { useContext } from 'react';

export interface NotificationType {
    uid: string;
    title: string;
    message: string;
    time: Date;
    read: boolean;
    document: any|null;
    deleted_at?: Date|null;
};

export const NotificationsContext = React.createContext({
    notifications: [],
    clearNotifications: () => {},
    updateNotificationsCount: () => {},
    addNotifications: (notifications: NotificationType[], before: boolean) => {},
    unreadNotifictaions: 0,
    removeReadNotification: (uid: string) => {},
    addSingleNotification: (notification: NotificationType) => {},
});

export function useNotification() {
    return useContext(NotificationsContext);
}
