import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import {
    IonAlert,
    IonButton,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    useIonLoading,
} from "@ionic/react";

import { useAuth } from '../../context/Auth';
import PageWrapper from '../layouts/PageWrapper';
import EmptyText from '../../components/EmptyText';
import { deleteRelation, fetchRelationsForBasedata } from '../../services/RelationServices';

import './DatamodPage.scss';

const RelationsPage: React.FC = () => {
    const [presentLoading, dismissLoading] = useIonLoading();
    const { checkAxiosError, assigns } = useAuth();

    const [selectedFilter, setSelectedFilter] = useState<number|null>(
        (assigns !== null && assigns.length > 0 && assigns[0].length > 0) ? assigns[0][0].base_data_id : null
    );
    const [users, setUsers] = useState<{
        loading: boolean;
        users: any;
    }>({
        loading: true,
        users: [],
    });
    const [showDelete, setShowDelete] = useState<number|null>(null);
    const [showAlert, setShowAlert] = useState<string|null>(null);

    const setSelectedFilterWithAlert = (value: any) => {
        setSelectedFilter(value);
    }

    const deleteSystemRelation = (relation_id: number) => {
        setShowDelete(null);
        if (relation_id !== null) {
            presentLoading("Kérem várjon...");
            deleteRelation(relation_id)
                .then(res => {
                    getRelations();
                    dismissLoading();
                    setShowAlert("Sikeres törlés!");
                }).catch(err => {
                    dismissLoading();
                    checkAxiosError(err);
                });
        }
    }

    const getRelations = () => {
        if (selectedFilter) {
            presentLoading("Kérem várjon...");
            setUsers({
                loading: true,
                users: [],
            });
            fetchRelationsForBasedata(selectedFilter)
                .then(res => {
                    setUsers({
                        loading: false,
                        users: res.data.data,
                    });
                    dismissLoading();
                }).catch(err => {
                    dismissLoading();
                    checkAxiosError(err);
                    setUsers({
                        loading: false,
                        users: [],
                    });
                });
        }
    }

    useEffect(() => {
        getRelations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilter]);

    return (
        <PageWrapper>
            <div className="hasButton">
                <h1 className="pageTitle">Hozzárendelések</h1>
            </div>

            {(assigns !== null && assigns.length > 0) &&
                <>
                    {(assigns[0].filter((item: any, i: number) => {
                        return item.isFirst === true;
                    }).length > 0) ?
                        <>
                            <div className="inputWithModalWrapper">
                                <IonItem className="inputWithModal highlightedSelect" lines="none">
                                    {assigns[0].length > 0 
                                    ?
                                        <IonSelect 
                                            cancelText="Mégse" 
                                            interface="action-sheet" 
                                            className="fullLength" 
                                            value={selectedFilter} 
                                            placeholder="Kérem válasszon" 
                                            onIonChange={(e) => setSelectedFilterWithAlert(e.detail.value)}
                                        >
                                            {
                                                assigns[0].filter((item: any, i: number) => {
                                                    return item.isFirst === true;
                                                }).map((item: any, i: number) => 
                                                    <IonSelectOption key={i} value={item.base_data_id}>{item.base_data.name}</IonSelectOption>
                                                )
                                            }
                                        </IonSelect>
                                    :
                                        <IonLabel color="secondary">Betöltés...</IonLabel>
                                    }
                                </IonItem>
                            </div>
                        </>
                    :
                        <>
                            <Redirect to="/my/dashboard" />
                        </>
                    }
                </>
            }
            <br/>

            {!users.loading &&
                <>
                    {users.users.length === 0 
                    ?
                        <EmptyText text="Nincs hozzárendelve több felhasználóhoz." subtext="" />
                    :
                        <>
                            {users.users.map((item: any, i: number) => 
                                    <div key={i}>
                                        <IonItem lines="none" button={false} detail={false}>
                                            <IonLabel>
                                                {item.user.name}
                                                <br/>
                                                <small className="ion-margin-top">{item.user.email}</small>
                                            </IonLabel>
                                            <IonButton fill="outline" color="danger" slot="end" onClick={() => setShowDelete(item.id)}>Törlés</IonButton>
                                        </IonItem>
                                        <br/>
                                    </div>
                                )
                            }
                        </>
                    }
                </>
            }

            {showDelete !== null &&
                <IonAlert
                    isOpen={(showDelete !== null)}
                    onDidDismiss={() => setShowDelete(null)}
                    header={'Figyelem'}
                    message={'Biztosan törölni szeretné ezt a hozzárendelést?'}
                    buttons={[
                        {
                            text: 'Nem',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                setShowDelete(null);
                            }
                        },
                        {
                            text: 'Igen',
                            handler: () => {
                                deleteSystemRelation(showDelete);
                            }
                        }
                    ]}
                />
            }

            <IonAlert
                isOpen={(showAlert !== null)}
                onDidDismiss={() => setShowAlert(null)}
                header={'Siker'}
                message={showAlert}
                buttons={[
                    {
                        text: 'Rendben',
                        role: 'cancel',
                    }
                ]}
            />
        </PageWrapper>
    );
};

export default RelationsPage;
