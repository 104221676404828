import { HttpClient } from "../helpers/HttpClient";

export const deleteRelation = async (relation_id: number) => {
    return await HttpClient({
        url: `/v1/delete-relation`,
        method: "post",
        data: {
            relation_id
        },
    });
};

export const fetchRelationsForBasedata = async (base_data_id: number) => {
    return await HttpClient({
        url: `/v1/get-users-for-basedata`,
        method: "post",
        data: {
            base_data_id
        },
    });
};

