import React from 'react';
import PageWrapper from '../layouts/PageWrapper';
import {
    IonRow,
    IonCol,
    IonCard,
} from "@ionic/react";

import iconSas from '../../assets/images/icon-sas.png';
import iconSasDark from '../../assets/images/icon-sas-dark.png';
import iconGate from '../../assets/images/icon-gate.png';

const SubsystemsPage: React.FC = () => {
    const launchApp = (appName: string) => {
        /*var ret = App.canOpenUrl({ url: appName });
        if(ret) {
            App.openUrl({ url: appName });
        }*/
        
        /* onClick={() => launchApp('com.facebook.orca')} */

        window.open(appName, '_blank');
    }

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    return (
        <PageWrapper>
            <h1 className="pageTitle">Alrendszerek</h1>

            <IonRow>
                <IonCol size="6" sizeMd="3" sizeLg="4">
                    <IonCard button={true} className="cardButton" onClick={() => launchApp("https://securitypatent.hu/")}>
                        {prefersDark.matches ?
                            <img src={iconSasDark} alt="Patent SAS" width="120" />
                        :
                            <img src={iconSas} alt="Patent SAS" width="120" />
                        }
                        <h2>Patent SAS</h2>
                    </IonCard>
                </IonCol>
                <IonCol size="6" sizeMd="3" sizeLg="4">
                    <IonCard button={true} className="cardButton" onClick={() => launchApp("https://gate.patentapp.eu/")}>
                        <img src={iconGate} alt="Patent Gate" width="120" />
                        <h2>Patent Gate</h2>
                    </IonCard>
                </IonCol>
            </IonRow>

        </PageWrapper>
    );
};

export default SubsystemsPage;
