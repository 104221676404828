import React, { useEffect, useState } from "react";

import { IonButton, IonIcon, IonLoading, IonText, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { checkmarkCircleOutline, closeCircleOutline, hourglassOutline, syncCircleOutline } from "ionicons/icons";

import { useAuth } from "../../context/Auth";
import PageWrapper from "../layouts/PageWrapper";
import { getPaymentResponse } from "../../services/InvoiceServices";

import logo from "../../assets/images/logo.svg";

let intval: any;

const done = ["Succeeded", "Expired", "Canceled", "Failed"];

const PublicPaymentResponsePage: React.FC = () => {
  const { checkAxiosError } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [paymentId, setPaymentId] = useState<string | null>(null);
  const [statusResponse, setStatusResponse] = useState<string | null>(null);

  const getParams = (search: any) => {
    let hashes = search.slice(search.indexOf("?") + 1).split("&");
    return hashes.reduce((params: any, hash: any) => {
      let [key, val] = hash.split("=");
      return Object.assign(params, { [key]: val });
    }, {});
  };

  const getUrlParams = () => {
    if (isLoading) {
      let params = getParams(window.location.href);
      setPaymentId(params["paymentId"]);
      sendPaymentStatus(params["paymentId"]);
    }
  };

  const sendPaymentStatus = (paymentId: string) => {
    getPaymentResponse(paymentId)
      .then((res: any) => {
        setStatusResponse(res.data.status);
      })
      .catch((err: any) => {
        checkAxiosError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (statusResponse) {
      if (done.includes(statusResponse)) {
        // Ha már sikeres vagy sikertelen a fizetés akkor nem kell lekérni a szervertől
        clearInterval(intval);
      }
    }
  }, [statusResponse]);

  /*
    useIonViewDidEnter(() => {
        alert("das");
        getUrlParams();
        intval = setInterval(() => {
            getUrlParams();
        }, 15000);
    });

    useIonViewDidLeave(() => {
        clearInterval(intval);
        setIsLoading(true);
        setPaymentId(null);
        setStatusResponse(null);
    });
    */

  useEffect(() => {
    getUrlParams();
    intval = setInterval(() => {
      getUrlParams();
    }, 15000);

    return () => {
      clearInterval(intval);
    };
  }, []);

  const getStatusComponent = () => {
    switch (statusResponse) {
      case "Failed":
        return (
          <>
            <IonText color="danger">
              <h1 className="ion-text-center alertIcon">
                <IonIcon icon={closeCircleOutline} />
              </h1>
              <h2 className="ion-text-center alertText">Sikertelen tranzakció.</h2>
              <p className="ion-text-center alertText">
                Tranzakció azonosító: <b>{paymentId ?? "-"} </b>
              </p>
            </IonText>
          </>
        );
      case "Succeeded":
        return (
          <>
            <IonText color="success">
              <h1 className="ion-text-center alertIcon">
                <IonIcon icon={checkmarkCircleOutline} />
              </h1>
              <h2 className="ion-text-center alertText">Sikeres tranzakció.</h2>
              <p className="ion-text-center alertText">
                Tranzakció azonosító: <b>{paymentId ?? "-"} </b>
              </p>
            </IonText>
          </>
        );
      case "Expired":
        return (
          <>
            <IonText color="danger">
              <h1 className="ion-text-center alertIcon">
                <IonIcon icon={closeCircleOutline} />
              </h1>
              <h2 className="ion-text-center alertText">Ön túllépte a tranzakció elindításának lehetséges maximális idejét.</h2>
              <p className="ion-text-center alertText">
                Tranzakció azonosító: <b>{paymentId ?? "-"} </b>
              </p>
            </IonText>
          </>
        );
      case "Canceled":
        return (
          <>
            <IonText color="danger">
              <h1 className="ion-text-center alertIcon">
                <IonIcon icon={closeCircleOutline} />
              </h1>
              <h2 className="ion-text-center alertText">Megszakított tranzakció!</h2>
              <p className="ion-text-center alertText">
                Tranzakció azonosító: <b>{paymentId ?? "-"} </b>
              </p>
            </IonText>
          </>
        );
      case "Authorized":
      case "Waiting":
      case "PartiallySucceeded":
      case "InProgress":
        return (
          <>
            <IonText color="warning">
              <h1 className="ion-text-center alertIcon">
                <IonIcon icon={hourglassOutline} />
              </h1>
              <h2 className="ion-text-center alertText">Kérem várjon, feldolgozás folyamatban...</h2>
              <p className="ion-text-center alertText">
                Tranzakció azonosító: <b>{paymentId ?? "-"} </b>
                <br />
                <br />
                Kérjük ne zárja be az alkalmazást, az adatok feldolgozás alatt!
              </p>
            </IonText>
          </>
        );
      case "NotStarted":
      case "Prepared":
      case "Started":
      default:
        return (
          <>
            <IonText color="warning">
              <h1 className="ion-text-center alertIcon">
                <IonIcon icon={syncCircleOutline} />
              </h1>
              <h2 className="ion-text-center alertText">Tranzakció feldolgozása</h2>
              <p className="ion-text-center alertText">
                Tranzakció azonosító: <b>{paymentId ?? "-"} </b>
                <br />
                <br />
                Kérjük ne zárja be az alkalmazást, az adatok feldolgozás alatt!
              </p>
            </IonText>
          </>
        );
    }
  };

  return (
    <PageWrapper className="smallCenterContentDesktop">
      <img src={logo} alt="myPatent Logo" className="desktopLogo" />

      {!isLoading && (
        <>
          {getStatusComponent()}
        </>
      )}

      {isLoading && <IonLoading isOpen={isLoading} message={"Kérem várjon..."} />}
    </PageWrapper>
  );
};

export default PublicPaymentResponsePage;
