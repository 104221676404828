import { HttpClient } from "../helpers/HttpClient";
import Axios from "axios";

export const fetchDatamodFilters = async () => {
    return await HttpClient({
        url: `/v2/datamod/selectors`,
        method: "get",
    });
};

let cancelFetchDatamodToken: any;
export const fetchDatamod = async (
    base_data_id: number,
    document_id: number | "",
) => {
    if (typeof cancelFetchDatamodToken != typeof undefined) {
        cancelFetchDatamodToken.cancel("REQ_CANCEL");
    }

    cancelFetchDatamodToken = Axios.CancelToken.source();

    return await HttpClient({
        url: `/v2/datamod`,
        method: "get",
        params: {
            base_data_id,
            document_id,
            is_service: 0,
        },
        cancelToken: cancelFetchDatamodToken.token,
    });
};

export const postDatamodStore = async (
    base_data_id: number,
    document_id: number | "",
    model_type: string,
    model_id: number|null,
    key: string,
    value: string,
    datamod_new_item_id?: number,
) => {
    return await HttpClient({
        url: `/v2/datamod`,
        method: "post",
        data: {
            base_data_id,
            document_id,
            model_type,
            model_id,
            key,
            value,
            is_service: 0,
            datamod_new_item_id
        },
    });
};

export const postDatamodDelete = async (
    base_data_id: number,
    document_id: number | "",
    model_type: string,
    model_id: number,
    is_delete: number,
) => {
    return await HttpClient({
        url: `/v2/datamod/delete`,
        method: "post",
        data: {
            base_data_id,
            document_id,
            model_type,
            model_id,
            is_delete,
        },
    });
};

export const postDatamodNew = async (
    base_data_id: number,
    document_id: number | "",
    model_type: string,
    fields: any,
) => {
    return await HttpClient({
        url: `/v2/datamod/new`,
        method: "post",
        data: {
            base_data_id,
            document_id,
            model_type,
            fields
        },
    });
};

export const getDatamodStatus = async (
    base_data_id: number,
    document_id: number | "",
    is_service: number,
) => {
    return await HttpClient({
        url: `/v2/datamod/status`,
        method: "get",
        params: {
            base_data_id,
            document_id,
            is_service,
        },
    });
};

export const postDatamodReady = async (
    base_data_id: number,
    document_id: number | "",
    is_service: number,
) => {
    return await HttpClient({
        url: `/v2/datamod/ready`,
        method: "post",
        data: {
            base_data_id,
            document_id,
            is_service,
        },
    });
};

export const postDatamodNewDelete = async (
    base_data_id: number,
    document_id: number | "",
    datamod_new_item_id: number,
) => {
    return await HttpClient({
        url: `/v2/datamod/delete-new`,
        method: "post",
        data: {
            base_data_id,
            document_id,
            datamod_new_item_id
        },
    });
};

export const postDatamodAllDelete = async (
    base_data_id: number,
    document_id: number | "",
    is_service: number,
) => {
    return await HttpClient({
        url: `/v2/datamod/delete-all`,
        method: "post",
        data: {
            base_data_id,
            document_id,
            is_service,
        },
    });
};

export const postDatamodNewChange = async (
    base_data_id: number,
    document_id: number | "",
    datamod_new_item_id: number,
    key: string,
    value: string,
) => {
    return await HttpClient({
        url: `/v2/datamod/change-new`,
        method: "post",
        data: {
            base_data_id,
            document_id,
            datamod_new_item_id,
            key,
            value,
        },
    });
};
