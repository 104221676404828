import React from 'react';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import PageWrapper from '../layouts/PageWrapper';

const ServicesCompletePage: React.FC = () => {


    return (
        <PageWrapper>
            <IonText color="success">
                <h1 className="ion-text-center alertIcon">
                    <IonIcon icon={checkmarkCircleOutline} />
                </h1>
                <h2 className="ion-text-center alertText">
                    Kérelem sikeresen beküldve
                </h2>
            </IonText>

            <p className="ion-text-center">Kollégáink hamarosan felveszik Önnel a kapcsolatot.</p>

            <IonButton color="success" expand="block" routerDirection="back" routerLink="/my/services">Vissza a Szolgáltatások oldalra</IonButton>
            <IonButton color="medium" expand="block" routerDirection="back" routerLink="/my/dashboard">Főoldal</IonButton>
        </PageWrapper>
    );
};

export default ServicesCompletePage;
