import { Capacitor } from "@capacitor/core";
import { FilterQueryObject } from "../helpers/FilterHelper";
import { HttpClient } from "../helpers/HttpClient";

export const fetchInvoiceFilters = async () => {
    return await HttpClient({
        url: `/v1/invoices/filters`,
        method: "get",
    });
};

export const fetchInvoices = async (queryObject: FilterQueryObject) => {
    return await HttpClient({
        url: `/v1/invoices/view`,
        method: "post",
        data: queryObject,
    });
};

export const fetchInvoicePreview = async (id: string) => {
    return await HttpClient({
        url: `/v1/invoices/show`,
        method: "post",
        data: {
            id,
        },
    });
};

export const fetchUnpaidInvoices = async () => {
    return await HttpClient({
        url: `/v1/invoices/unpaid`,
        method: "get",
    });
};

export const postOtpsp = async (docId: number, url: string, isUserless?: boolean) => {
    return await HttpClient({
        url: `/v1/barion-start/${docId}`,
        method: "post",
        data: {
            url,
            isMobileApp: Capacitor.isNativePlatform() ? 1 : 0,
            isUserless: isUserless ? 1 : 0,
        },
    });
};

export const getPaymentResponse = async (paymentId: string) => {
    return await HttpClient({
        url: `/v1/barion-response`,
        method: "post",
        data: {
            paymentId,
        },
    });
};

export const getInvoiceByHash = async (hash: string) => {
    return await HttpClient({
        url: `/v1/invoice-by-hash`,
        method: "get",
        params: {
            hash,
        },
    });
};
