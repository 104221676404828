export default function ValidateCancel(values: any) {
    let errors:any = {};

    if(!values.password) {
        errors.password = 'A "Jelszó" mező kötelező!';
    } else if(values.password.length < 6) {
        errors.password = 'A "Jelszó" mező legalább 6 karakter!';
    }

    return errors;
}
