import React from 'react';
import { Capacitor } from "@capacitor/core";
import { App } from '@capacitor/app';
import { IonButton, IonIcon } from '@ionic/react';
import { callOutline, mailOutline } from 'ionicons/icons';
import { useAuth } from '../../context/Auth';

interface Props {
    setCurrentPage: (num: number) => void;
    relation?: any;
}

const AssignWaitPage: React.FC<Props> = ({ setCurrentPage, relation }) => {
    const { assigns } = useAuth();

    const exitApp = () => {
        if (Capacitor.isNativePlatform()) {
            App.exitApp();
        }
    }

    return (
        <>
            <h4>Telefonszám beküldésre került!</h4>
            <p>
                A megadott számon&nbsp;
                {relation !== undefined && <>({relation.client_phone_number})&nbsp;</>}
                kollégáink fel fogják keresni Önt ellenőrzés céljából.
                <br/><br/>
                Kérjük várjon, míg az azonosítóhoz tartozó hozzárendelést jóváhagyják.
                <br/>
            </p>

            {assigns.length !== 0 ?
                <IonButton color="success" expand="block" routerLink="/my/dashboard" routerDirection="none">Vissza a főoldalra</IonButton>
            :
                <>
                    <IonButton color="success" expand="block" onClick={() => setCurrentPage(0)}>Új hozzárendelés</IonButton>
                    {Capacitor.isNativePlatform() && 
                        <IonButton color="medium" expand="block" onClick={exitApp}>Kilépés az alkalmazásból</IonButton>
                    }
                </>
            }

            <br/><br/>
            <p>
                Ha problémát észlelt kérjük keresse fel ügyintézőnket az alábbi elérhetőségeken.
            </p>
            <IonButton color="medium" expand="block" href="mailto:hello@mypatent.hu" target="_blank">
                <IonIcon slot="start" icon={mailOutline} />
                hello@mypatent.hu
            </IonButton>
            <IonButton color="medium" expand="block" href="tel:+36305060111" target="_blank">
                <IonIcon slot="start" icon={callOutline} />
                +36 30 50 60 111
            </IonButton>
        </>
    );
};

export default AssignWaitPage;
