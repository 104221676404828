import React, { useState } from 'react';
import {
    IonContent,
    IonGrid,
    IonButton,
    IonLoading,
    IonInput,
    IonItem,
    IonPage,
    IonRow,
    IonCol,
    IonRouterLink,
    IonLabel,
    IonAlert
} from "@ionic/react";
import { Redirect } from 'react-router-dom';
import { useAuth } from '../context/Auth';
import Axios from 'axios';

import useFormValidation from '../helpers/FormValidationHelper';
import ValidatePasswordRecovery from '../helpers/validators/ValidatePasswordRecovery';
import MyPatentHeader from '../components/MyPatentHeader';
import { setLoading, setInitError, hasError, addApiErrors, ErrorsList } from '../helpers/GlobalHelpers';

import logo from '../assets/images/logo.svg';

import './LoginPage.scss';
import { postLostPassword } from '../services/GlobalServices';

const INIT_STATE = {
    email: '',
};

const LostPasswordPage: React.FC = () => {
    const { handleChange, values } = useFormValidation(INIT_STATE);
    const { loggedIn } = useAuth();
    const [status, setStatus] = useState({
        loading: false,
        error_list: INIT_STATE,
    });
    const [alertSent, setAlertSent] = useState(false);
    const [alertRedirct, setAlertRedirct] = useState(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === 'Enter') {
            handleSendPasswordRecovery();
        }
    }

    const handlePasswordRecoverySuccess = async () => {
        setAlertSent(false);
        setAlertRedirct(true);
    }

    const handleSendPasswordRecovery = async () => {
        setLoading(true, setStatus);
        setInitError(INIT_STATE, setStatus);

        const errors = ValidatePasswordRecovery(values);
        if (hasError(errors)) {
            setLoading(false, setStatus);
            addApiErrors(errors, setStatus);
        } else {
            postLostPassword(values.email)
                .then((res: any) => {
                    setLoading(false, setStatus);
                    setAlertSent(true);
                }).catch((err: any) => {
                    setLoading(false, setStatus);
                    if(!err.response) {
                        addApiErrors('Hálózati hiba.', setStatus);
                    } else {
                        addApiErrors(err.response.data.message, setStatus);
                    }
                });
        }
    }

    if (alertRedirct) {
        return <Redirect to="/login" />;
    }

    if (loggedIn !== null && loggedIn === true) {
        return <Redirect to="/my/dashboard" />;
    }
    
    return (
        <IonPage>
            <IonContent id="loginPage">
                <MyPatentHeader isLogoHidden={false} />

                <IonContent className="backgroundTransparent">
                    <IonGrid className="ion-padding" id="ionGridInner" fixed>
                        <div className="page-content">
                            <img src={logo} alt="myPatent Logo" className="desktopLogo" />

                            <h1 className="pageTitle">Elfelejtett jelszó</h1>

                            <div className="form">
                                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.email ? 'error' : '')}>
                                    <IonLabel position="floating">E-mail cím</IonLabel>
                                    <IonInput onKeyDown={handleKeyDown} clearOnEdit={false} type="email" inputmode="email" pattern="email" name="email" onIonChange={handleChange} value={values.email} required autofocus={true}></IonInput>
                                </IonItem>

                                <ErrorsList errors={status.error_list} />

                                <div className="ion-text-center">
                                    <IonButton size="large" expand="block" onClick={handleSendPasswordRecovery}>Emlékeztető küldése</IonButton>
                                </div>
                                <br />
                                <IonRow>
                                    <IonCol size="6">
                                        <IonRouterLink routerDirection="back" routerLink="/login">
                                            Vissza
                                        </IonRouterLink>
                                    </IonCol>
                                </IonRow>
                                <br />
                            </div>

                            <IonLoading
                                isOpen={status.loading}
                                message={'Kérem várjon...'}
                            />

                            <IonAlert
                                isOpen={alertSent}
                                onDidDismiss={handlePasswordRecoverySuccess}
                                header={'Siker'}
                                message={'Az emlékeztetőt kiküldtük e-mailben!'}
                                buttons={[
                                    {
                                        text: 'Rendben',
                                        role: 'cancel',
                                    }
                                ]}
                            />
                        </div>
                    </IonGrid>
                </IonContent>
            </IonContent>
        </IonPage>
    );
}

export default LostPasswordPage;
