import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
    IonRouterOutlet,
    IonSplitPane,
    IonLoading,
    createAnimation,
} from '@ionic/react';
import { useAuth } from './context/Auth';

import { NotificationsContext, NotificationType } from "./context/Notifications";

import DashboardPage from "./pages/protected/DashboardPage";
import NotificationsPage from './pages/protected/NotificationsPage';
import DocumentsPage from './pages/protected/DocumentsPage';
import InvoicesPage from './pages/protected/InvoicesPage';
import InvoicesPaymentPage from './pages/protected/InvoicesPaymentPage';
import SubsystemsPage from './pages/protected/SubsystemsPage';
import DatamodNewPage from './pages/protected/DatamodNewPage';

import Menu from './components/Menu';

import PushNotificationHelper from './helpers/PushNotificationHelper';

import DocumentsViewPage from './pages/protected/DocumentsViewPage';
import DatamodCompletePage from './pages/protected/DatamodCompletePage';
import PaymentResponsePage from './pages/protected/PaymentResponsePage';
import UserPage from './pages/protected/UserPage';
import RelationsPage from './pages/protected/RelationsPage';
import ServicesNewPage from './pages/protected/ServicesNewPage';
import ServicesCompletePage from './pages/protected/ServicesCompletePage';
import { getUnreadMessageCount, postReadMessage } from './services/GlobalServices';
import CancelPage from './pages/protected/CancelPage';


interface Props {
    onLogout: () => void;
}

const AppTabs: React.FC<Props> = () => {
    const { loggedIn, assigns, assignsLoaded, userInfo, authToken } = useAuth();
    const [ notifications, setNotifications ] = useState([]);
    const [ unreadNotifictaions, setUnreadNotification ] = useState(0);

    const addNotifications = (messages: NotificationType[], before: boolean = false) => {
        if(before) {
            setNotifications(prevState => [
                ...messages,
                ...prevState,
            ]);
        } else {
            setNotifications(prevState => [
                ...prevState,
                ...messages
            ]);
        }
    };

    const addSingleNotification = (message: NotificationType) => {
        if(!notifications.some(el => el.id === message.uid)) {
            setNotifications(prevState => [
                message,
                ...prevState
            ]);
    
            if(!message.read) {
                setUnreadNotification(prevstate => (prevstate + 1));
            }
        }
    }

    const removeReadNotification = (uid: string) => {
        notifications.forEach(notification => {
            if(notification.uid === uid && !notification.read) {
                notification.read = true;
                setUnreadNotification(prevstate => (prevstate - 1));
                postReadMessage(uid)
                    .catch((err: any) => {
                        console.error(err);
                    });
            }
        });
    }

    const clearNotifications = () => {
        setNotifications([]);
    }

    const updateNotificationsCount = () => {
        getUnreadMessageCount()
            .then((res: any) => {
                if (res.data.data) {
                    setUnreadNotification(res.data.data);
                }
            }).catch((err: any) => {
                console.log(err);
            });
    }

    useEffect(() => {
        if (loggedIn !== null && loggedIn !== false && userInfo !== null && userInfo.email_verified_at !== null) {
            updateNotificationsCount();
        }
    }, [loggedIn, userInfo]);

    if (loggedIn !== null && loggedIn === false) {
        return <Redirect to="/login" />;
    }

    if (userInfo && userInfo.email_verified_at === null) {
        return <Redirect to="/verify" />;
    }

    if(userInfo && userInfo.email_verified_at !== null && assignsLoaded && assigns.length === 0) {
        return <Redirect to="/assign" />;
    }

    const customPageTransition = (baseEl: any, opts?: any) => {
        var animStart = createAnimation()
            .addElement(opts.leavingEl)
            .duration(250)
            .iterations(1)
            .easing('ease-out')
            .fromTo('opacity', '1', '0.0');

        var animEnd = createAnimation()
            .addElement(opts.enteringEl)
            .duration(250)
            .iterations(1)
            .easing('ease-out')
            .fromTo('opacity', '0.0', '1');

        var animAll = createAnimation()
            .duration(250)
            .iterations(1)
            .addAnimation([animStart, animEnd]);

        return animAll;
    };

    return (
        <>
            <NotificationsContext.Provider value={{ notifications, addNotifications, clearNotifications, unreadNotifictaions, removeReadNotification, addSingleNotification, updateNotificationsCount }}>
                {Boolean(userInfo !== null && assignsLoaded && assigns.length !== 0) &&
                    <>
                        <PushNotificationHelper />
                        <IonSplitPane id="mainSplitPane" contentId="main" when="lg">
                            <Menu />
                            <IonRouterOutlet id="main" animated={true} animation={customPageTransition}>
                                <Route exact path="/my/dashboard">
                                    <DashboardPage />
                                </Route>
                                <Route exact path="/my/notifications">
                                    <NotificationsPage />
                                </Route>
                                <Route exact path="/my/documents">
                                    <DocumentsPage />
                                </Route>
                                <Route exact path="/my/invoices">
                                    <InvoicesPage />
                                </Route>
                                <Route exact path="/my/documents/view/:id">
                                    <DocumentsViewPage />
                                </Route>
                                <Route exact path="/my/invoices/payment/:id">
                                    <InvoicesPaymentPage />
                                </Route>
                                <Route exact path="/my/invoices/payment-response">
                                    <PaymentResponsePage />
                                </Route>
                                <Route exact path="/my/profile">
                                    <DatamodNewPage />
                                </Route>
                                <Route exact path="/my/profile-complete">
                                    <DatamodCompletePage />
                                </Route>
                                <Route exact path="/my/services">
                                    <ServicesNewPage />
                                </Route>
                                <Route exact path="/my/services-complete">
                                    <ServicesCompletePage />
                                </Route>
                                <Route exact path="/my/subsystems">
                                    <SubsystemsPage />
                                </Route>
                                <Route exact path="/my/user">
                                    <UserPage />
                                </Route>
                                <Route exact path="/my/relations">
                                    <RelationsPage />
                                </Route>
                                <Route exact path="/my/cancel">
                                    <CancelPage />
                                </Route>
                                <Redirect exact path="/" to="/my/dashboard" />
                                <Route>
                                    <Redirect to="/my/dashboard" />
                                </Route>
                            </IonRouterOutlet>
                        </IonSplitPane>
                    </>
                }

                <IonLoading
                    isOpen={Boolean(userInfo === null || !assignsLoaded)}
                    message={'Felhasználó adatainak betöltése...'}
                />
            </NotificationsContext.Provider>
        </>
    );
}

export default AppTabs;
