export const sendBarionPixel = (type, data) => {
    console.log("Sending '" + type + "' to barion with:", data);
    try {
        // eslint-disable-next-line no-undef
        bp("track", type, data);
    } catch (err) {
        console.error(err);
    }
};

export const PixelConsent = (consent) => {
    // eslint-disable-next-line no-undef
    bp("consent", consent ? "grantConsent" : "rejectConsent");
};
