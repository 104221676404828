import React, { useState, useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { PushNotifications, PushNotificationSchema, Token, ActionPerformed } from "@capacitor/push-notifications";
import { AppLauncher } from "@capacitor/app-launcher";
import { firebase } from "@firebase/app";
import { IonAlert, IonToast } from "@ionic/react";
import "@firebase/messaging";

import { useNotification } from "../context/Notifications";
import { useAuth } from "../context/Auth";
import LocalStorage from "./LocalStorage";
import { postPushToken } from "../services/GlobalServices";

interface NotificationTypeLocal {
  uid: string;
  title: string;
  message: string;
}

const PushNotificationHelper: React.FC = () => {
  const [showPwaNotiAlert, setShowPwaNotiAlert] = useState(false);
  const { addSingleNotification } = useNotification();
  const { authToken, checkAxiosError } = useAuth();
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      initPushApp();
    } else {
      if ("Notification" in window) {
        if (Notification.permission !== "granted") {
          if (localStorage.getItem("disabledDesktopNotifications") !== "true") {
            setShowPwaNotiAlert(true);
          }
        } else {
          initPushWeb();
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initPushWeb = () => {
    if (Notification.permission === "granted") {
      initMessaging();

      if (localStorage.getItem("disabledDesktopNotifications") !== "false") {
        localStorage.setItem("disabledDesktopNotifications", "false");
      }
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          initMessaging();

          if (localStorage.getItem("disabledDesktopNotifications") !== "false") {
            localStorage.setItem("disabledDesktopNotifications", "false");
          }
        } else {
          if (localStorage.getItem("disabledDesktopNotifications") !== "true") {
            localStorage.setItem("disabledDesktopNotifications", "true");
          }
        }
      });
    }
  };

  const initMessaging = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("./firebase-messaging-sw.js", { scope: "/my/" })
        .then((registration) => {
          if (!firebase.apps.length) {
            firebase.initializeApp({
              apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
              authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
              databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
              projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
              storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
              messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
              appId: process.env.REACT_APP_FIREBASE_APPID,
            });
          }
          const messaging = firebase.messaging();

          if (!firebase.messaging.isSupported()) {
            return;
          }

          messaging.onTokenRefresh(() => {
            messaging
              .getToken({
                vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
                serviceWorkerRegistration: registration,
              })
              .then((refreshedToken: string) => {
                if (localStorage.getItem("pushToken") !== refreshedToken) {
                  sendPushTokenToServer(refreshedToken);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          });

          messaging
            .getToken({
              vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
              serviceWorkerRegistration: registration,
            })
            .then((token) => {
              if (localStorage.getItem("pushToken") !== token) {
                sendPushTokenToServer(token);
              }
            });

          navigator.serviceWorker.addEventListener("message", (message) => {
            addSingleNotification({
              uid: Math.random().toString(36).substring(7),
              title: message.data.notification.title,
              message: message.data.notification.body,
              read: false,
              time: new Date(),
              document: null,
            });
          });
        })
        .catch((err) => {
          console.error("Service worker registration failed, error:", err);
        });
    }
  };

  const initPushApp = async () => {
    PushNotifications.addListener('registration', (token: Token) => {
      console.log('myPatentPushNotifications,registration', token.value);
      if (localStorage.getItem("pushToken") !== token.value) {
        sendPushTokenToServer(token.value);
      }
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.error('myPatentPushNotifications,registrationError', error);
    });

    // Mikor az app meg van nyitva.
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.log('myPatentPushNotifications,pushNotificationReceived', notification);
      if (!authToken) {
        return;
      }

      addSingleNotification({
        uid: notification.id,
        title: notification.title,
        message: notification.body,
        read: false,
        time: new Date(),
        document: null,
      });
    });

    // Mikor rákattint a felhasználó a notira ha az app a háttérben van.
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      console.log('myPatentPushNotifications,pushNotificationActionPerformed', notification);
      const data = notification.notification.data;
      if (data.document_id) {
        AppLauncher.openUrl({
          url: `/my/documents/view/${data.document_id}`,
        });
      } else {
        AppLauncher.openUrl({
          url: "/my/notifications",
        });
      }
    });

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      console.error('myPatentPushNotifications,User denied permissions!');
      return;
    }

    await PushNotifications.register();
  };

  const sendPushTokenToServer = (pushToken: string) => {
    postPushToken(pushToken)
      .then(() => {
        LocalStorage.set("pushToken", pushToken);
      })
      .catch((err: any) => {
        checkAxiosError(err);
      });
  };

  return (
    <>
      <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message="Hiba történt az értesítések feliratkozásakor." duration={200} />

      {showPwaNotiAlert && (
        <IonAlert
          isOpen={showPwaNotiAlert}
          onDidDismiss={() => setShowPwaNotiAlert(false)}
          header="Értesítések"
          message="Szeretne feliratkozni a myPatent értesítéseire?"
          buttons={[
            {
              text: "Nem",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                if (localStorage.getItem("disabledDesktopNotifications") !== "true") {
                  localStorage.setItem("disabledDesktopNotifications", "true");
                }
                setShowPwaNotiAlert(false);
              },
            },
            {
              text: "Igen",
              handler: () => {
                setShowPwaNotiAlert(false);
                initPushWeb();
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default PushNotificationHelper;
