import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { cardOutline, checkmarkCircleOutline, closeCircleOutline, documentTextOutline } from "ionicons/icons";
import { IonAlert, IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRow, IonText, useIonViewWillEnter } from "@ionic/react";
import dateFormat from "dateformat";
import NumberFormat from "react-number-format";

import { useAuth } from "../../context/Auth";
import { getFileUrl } from "../../helpers/DevHelper";
import { getInvoiceByHash, postOtpsp } from "../../services/InvoiceServices";
import { getInvoicePrice } from "../../helpers/GlobalHelpers";
import { sendBarionPixel } from "../../helpers/barion/PixelHelper";

import barionLogo from "../../assets/images/barion-card-strip-intl.svg";
import logo from "../../assets/images/logo.svg";

import "./PublicInvoicesPaymentPage.scss";

interface Documents {
  id: number;
  type: string;
  createdAt: string;
  related: any;
  transactions?: any;
  fileId?: string | null;
  invoice_data?: any;
  invoice_items_data?: any;
  barion_transactions?: any;
}

const PublicInvoicesPaymentPage: React.FC = () => {
  const { checkAxiosError } = useAuth();
  const { id }: { id: string } = useParams();

  const [invoice, setInvoice] = useState<Documents>(null);
  const [simpleChecked, setSimpleChecked] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<string | null>(null);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const getStatus = () => {
    if (invoice.related.U_CPH_PaymentCreated !== undefined && invoice.related.U_CPH_PaymentCreated !== null && invoice.related.U_CPH_PaymentCreated === "Y") {
      return "paid";
    }

    if (invoice.barion_transactions !== undefined && invoice.barion_transactions.length > 0) {
      const succeeded = invoice.barion_transactions.filter((t: any) => t.status === "Succeeded").length > 0;
      if (succeeded) {
        return "paid";
      }
    }

    if (invoice.transactions !== undefined && invoice.transactions.length > 0 && invoice.transactions[0] !== undefined && invoice.transactions[0].status === "FINISHED") {
      return "paid";
    } else {
      var d1 = new Date();
      var d2 = new Date(invoice.related.DocDueDate);
      if (d1 > d2) {
        return "late";
      } else {
        return "waiting";
      }
    }
  };

  const getStatusText = () => {
    switch (getStatus()) {
      case "waiting":
        return "Fizetésre vár";
      case "paid":
        return "Fizetve";
      case "late":
        return "Késés";
    }
  };

  const getPaymentLink = (docId: number) => {
    setLoading(true);
    postOtpsp(docId, window.location.origin, true)
      .then((res: any) => {
        setLoading(false);

        sendBarionPixel("initiatePurchase", {
          contents: [
            {
              contentType: "Product",
              currency: "HUF",
              id: id.toString(),
              name: invoice.related.invoice_number,
              quantity: 1,
              totalItemPrice: getInvoicePrice(invoice.related),
              unit: "pcs.",
              unitPrice: getInvoicePrice(invoice.related),
            },
          ],
          currency: "HUF",
          revenue: getInvoicePrice(invoice.related),
          step: "1",
        });

        var data = res.data;
        if (data.payment_link !== undefined && data.payment_link !== "") {
          window.open(data.payment_link, "_self");
        } else if (data.response !== undefined && data.response.paymentUrl !== "") {
          window.open(data.response.paymentUrl, "_self");
        } else {
          if (data.message !== undefined) {
            setShowAlert(data.message);
          } else {
            setShowAlert("Hiba történt a befizetés közben.");
          }
        }
      })
      .catch((err: any) => {
        setLoading(false);

        if (err.response.data.messages !== undefined) {
          if (err.response.data.codes !== undefined) {
            setShowAlert(err.response.data.messages[0] + "<br/><br/>Hibakód: " + err.response.data.codes[0]);
          } else {
            setShowAlert(err.response.data.messages[0]);
          }
        } else {
          setShowAlert("Hiba történt a befizetés közben.");
        }

        checkAxiosError(err);
      });
  };

  useIonViewWillEnter(() => {
    setSimpleChecked(false);
  });

  useEffect(() => {
    setInvoice(null);
    setLoading(true);
    getInvoiceByHash(id)
      .then((res) => {
        if (res.data.data) {
          setInvoice({
            id: res.data.data.id,
            type: res.data.data.related_type,
            createdAt: res.data.data.created_at,
            related: res.data.data.related,
            transactions: res.data.data.transactions,
            fileId: res.data.data.file?.access_hash,
            invoice_data: res.data.data.invoice_data,
            invoice_items_data: res.data.data.invoice_items_data,
            barion_transactions: res.data.data.barion_transactions,
          });
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <>
      <IonPage>
        <IonContent>
          <IonGrid className="ion-padding" id="ionGridInner" fixed>
            <IonRow>
              <IonCol size="12">
                <img src={logo} alt="myPatent Logo" className="desktopLogo" />
                <h1 className="pageTitle">Számla befizetése</h1>
                <a rel="noopener noreferrer" href="https://www.barion.com/hu/" target="_blank" id="barionLogo" style={{ margin: "0 0 15px" }}>
                  <img height="30" src={barionLogo} title="Barion" alt="Barion" />
                </a>
              </IonCol>
            </IonRow>
            {invoice !== null ? (
              <>
                <IonRow>
                  {invoice.related.GroupNum !== "2" && (
                    <>
                      {getStatus() !== "paid" ? (
                        <>
                          <IonCol size="12">
                            <IonList>
                              <IonItem lines="none" className={"checkbox-input backgroundTransparent"}>
                                <IonCheckbox checked={simpleChecked} onIonChange={(e) => setSimpleChecked(e.detail.checked)} />
                                <IonLabel class="ion-text-wrap">
                                  Tudomásul veszem, hogy a Patent Távfelügyelet Kft. (9024, Győr, Mécs László u. 7) adatkezelő által a mypatent.hu felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek a Barion Payment
                                  Zrt. , mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: Számlaszám, név, e-mail, cím, számlázási cím, szállítási cím. Az adatfeldolgozó által végzett adatfeldolgozási
                                  tevékenység jellege és célja a Barion Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:{" "}
                                  <a target="_blank" rel="noopener noreferrer" href="https://www.barion.com/hu/adatvedelmi-tajekoztato/">
                                    https://www.barion.com/hu/adatvedelmi-tajekoztato/
                                  </a>
                                </IonLabel>
                              </IonItem>
                            </IonList>
                          </IonCol>
                          <IonCol size="12" sizeMd="6">
                            <IonButton
                              expand="block"
                              color="warning"
                              onClick={() => {
                                if (!simpleChecked) {
                                  setShowAlert("Kérjük fogadja el az általános felhasználási feltételeket a fizetés gomb felett!");
                                } else {
                                  getPaymentLink(invoice.id);
                                }
                              }}
                            >
                              <IonIcon icon={cardOutline} slot="start" />
                              Fizetés
                            </IonButton>
                          </IonCol>
                        </>
                      ) : (
                        <IonCol size="12">
                          <IonText color="success">
                            <h1 className="ion-text-center alertIcon">
                              <IonIcon icon={checkmarkCircleOutline} />
                            </h1>
                            <h2 className="ion-text-center alertText">A számla befizetve.</h2>
                          </IonText>
                        </IonCol>
                      )}
                    </>
                  )}
                  {invoice.fileId ? (
                    <IonCol size="12" sizeMd={getStatus() !== "paid" ? "6" : "12"}>
                      <IonButton href={getFileUrl() + "/" + invoice.fileId} target="_blank" color="success" expand="block">
                        <IonIcon icon={documentTextOutline} slot="start" />
                        Megnyitása
                      </IonButton>
                    </IonCol>
                  ) : (
                    <IonCol size="12" sizeMd={getStatus() !== "paid" ? "6" : "12"}>
                      <IonButton href={getFileUrl() + "/" + invoice.fileId} disabled color="medium" expand="block">
                        A Számlakép maximum 1 héten belül várható...
                      </IonButton>
                    </IonCol>
                  )}
                </IonRow>
                <br />
                <div className="list-between">
                  <span>Számlaszám</span>
                  <span>{invoice.related.invoice_number}</span>
                </div>
                <div className="list-between">
                  <span>Fizetési határidő</span>
                  <span>{invoice.related.DocDueDate ? dateFormat(invoice.related.DocDueDate, "yyyy.mm.dd.") : " - "}</span>
                </div>
                <div className="list-between">
                  <span>Bizonylat dátum</span>
                  <span>{invoice.related.TaxDate ? dateFormat(invoice.related.TaxDate, "yyyy.mm.dd.") : " - "}</span>
                </div>
                <div className="list-between">
                  <span>Partner azonosító</span>
                  <span>{invoice.related.CardCode ?? " - "}</span>
                </div>
                <div className="list-between">
                  <span>Megjegyzés</span>
                  <span>{invoice.related.Comments ?? " - "}</span>
                </div>
                <div className="list-between">
                  <span>Státusz</span>
                  <span>{getStatusText()}</span>
                </div>
                <div className="list-between">
                  <span>Bankszámlaszám, ahová a befizetést küldeni kell</span>
                  <span>{invoice.related.U_HousBnkAct ?? " - "}</span>
                </div>

                <div className="list-between price">
                  <span>Fizetendő</span>
                  <span>
                    <NumberFormat value={invoice.related.calculated_total} displayType={"text"} thousandSeparator={" "} suffix={" Ft"} decimalScale={0} />
                  </span>
                </div>
                <br />
                {invoice.invoice_data !== undefined && (
                  <>
                    {invoice.invoice_data !== null && invoice.invoice_data.length > 0 && (
                      <>
                        <h3 className="smallTitle">Részletek</h3>
                        {invoice.invoice_data.map((item: any, key: any) => (
                          <div className="list-between" data-content={JSON.stringify(item)} key={key}>
                            <span>{item.label ?? ""}</span>
                            <span>{item.value ?? ""}</span>
                          </div>
                        ))}
                        <br />
                      </>
                    )}
                  </>
                )}
              </>
            ) : null}
            {error ? (
              <IonCol size="12">
                <IonText color="danger">
                  <h1 className="ion-text-center alertIcon">
                    <IonIcon icon={closeCircleOutline} />
                  </h1>
                  <h2 className="ion-text-center alertText">{error}</h2>
                </IonText>
              </IonCol>
            ) : null}
          </IonGrid>
        </IonContent>
      </IonPage>
      {loading ? <IonLoading isOpen={loading} message={"Kérem várjon..."} /> : null}
      <IonAlert
        isOpen={Boolean(showAlert !== null)}
        onDidDismiss={() => setShowAlert(null)}
        header={"Információ"}
        message={showAlert}
        buttons={[
          {
            text: "Rendben",
            role: "cancel",
          },
        ]}
      />
    </>
  );
};

export default PublicInvoicesPaymentPage;
