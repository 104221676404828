export default function ValidateLogin(values: any) {
    let errors:any = {};
    
    if (!values.email) {
        errors.email = 'Az e-mail cím mező kötelező!';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Hibás e-mail cím!';
    }

    if(!values.password) {
        errors.password = 'A jelszó mező kötelező!';
    } else if(values.password.length < 6) {
        errors.password = 'A jelszó legalább 6 karakter!';
    }

    return errors;
}