import React from "react";

import {
    IonItem,
} from "@ionic/react";
import NumberFormat from "react-number-format";

import { FieldModel } from "../../../models/GlobalModels";

interface Props {
    field: FieldModel;
    onChange: (field: FieldModel, value: string) => void;
    isLoading: boolean;
    valueOverwrite?: string;
}

const PriceControl: React.FC<Props> = ({ field, onChange, isLoading, valueOverwrite }) => {
    return (
        <>
            <IonItem lines="none">
                {field.value !== ".000000" ?
                    <NumberFormat value={parseInt(field.value)??0} displayType={'text'} thousandSeparator={" "} suffix={' Ft'} decimalScale={0} />
                :
                    <NumberFormat value={0} displayType={'text'} thousandSeparator={" "} suffix={' Ft'} decimalScale={0} />
                }
            </IonItem>
        </>
    );
};
export default PriceControl;
