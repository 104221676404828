import React, { useState, useEffect } from 'react';

import {
    IonButton,
    IonButtons,
    IonCard,
    IonCheckbox,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonLoading,
    useIonViewDidEnter,
    useIonViewWillEnter,
} from "@ionic/react";

import { useAuth } from '../../context/Auth';
import PageWrapper from '../layouts/PageWrapper';
import { DatamodStatusModel, ServiceProps } from '../../models/GlobalModels';
import { fetchServiceFilters, fetchServices, postServicesPreview, postServicesStore } from '../../services/ServiceServices';

import './ServicesPage.scss';
import ServiceAccordionItem from '../../components/ServiceAccordionItem';
import NumberFormat from 'react-number-format';
import { checkmark, trash } from 'ionicons/icons';
import { getDatamodStatus, postDatamodAllDelete, postDatamodReady } from '../../services/DatamodNewServices';
import LocalStorage from '../../helpers/LocalStorage';

interface FilterProps {
    type: string;
    base_data_id: number;
    document_id: number|null;
    label: string;
}

const ServicesNewPage: React.FC = () => {
    const { checkAxiosError } = useAuth();
    const [ presentLoading, dismissLoading ] = useIonLoading();
    const [ presentAlert ] = useIonAlert();

    const [ filters, setFilters ] = useState<FilterProps[]>([]);
    const [ selectedFilterIndex, setSelectedFilterIndex ] = useState<number|null>(null);
    const [ services, setServices ] = useState<ServiceProps[]>([]);
    const [ datamodStatus, setDatamodStatus ] = useState<DatamodStatusModel|null>(null);
    const [ document, setDocument ] = useState<any>(null);
    const [ customerContractsCount, setCustomerContractsCount ] = useState<number>(0);
    const [ total, setTotal ] = useState(0);
    const [ oldTotal, setOldTotal ] = useState(0);
    const [ saveAlert, setSaveAlert ] = useState<boolean>(false);
    const [ saveAlertHtml, setSaveAlertHtml ] = useState<string>("");
    const [ aszfOneChecked, setAszfOneChecked ] = useState<boolean>(false);
    const [ aszfTwoChecked, setAszfTwoChecked ] = useState<boolean>(false);
    const [ aszfThreeChecked, setAszfThreeChecked ] = useState<boolean>(false);

    /*const [isFirstRelation, setIsFirstRelation] = useState<boolean>(false);*/

    /*const getFilters = () => {
        fetchServiceFilters()
            .then((res: any) => {
                if (res.data.data && res.data.data.length > 0) {
                    res.data.data.forEach((_fil: any) => {
                        setFilters(prevState => [
                            ...prevState,
                            {
                                type: _fil.type,
                                base_data_id: _fil.base_data_id,
                                document_id: _fil.document_id,
                                label: _fil.label,
                            }
                        ]);
                    });
                    setDisableFilter(false);
                } else {
                    setNoData(true);
                }
            })
            .catch((err: any) => {
                checkAxiosError(err);
            });
    }

    const getServices = () => {
        setServices({
            loaded: false,
            services: [],
        });
        setCheckedServices([]);
        getIsFirstRelation();

        fetchServices(
            filters[selectedFilter].base_data_id,
            filters[selectedFilter].document_id
        )
        .then((res: any) => {
            if (res.data.data.services && res.data.data.services.length > 0) {
                let tempServices: ServiceProps[] = [];
                res.data.data.services.forEach((service: any) => {
                    tempServices.push({
                        ...service,
                        checked: Boolean(service.value === "Y"),
                    });
                });

                setDocument(res.data.data.document);
                setServices({
                    loaded: true,
                    services: tempServices,
                });
            }
        })
        .catch((err: any) => {
            checkAxiosError(err);
        });
    }

    const getIsFirstRelation = () => {
        setIsFirstRelation(false);
        fetchIsFirstRelation(filters[selectedFilter].base_data_id)
            .then(res => {
                if (res.data.data === true) {
                    setIsFirstRelation(true);
                }
            }).catch(err => {
                checkAxiosError(err);
            });
    }
    

    const handleSave = () => {
        setResetLoading(true);
        
        var formData = new FormData();
        formData.append('base_data_id', filters[selectedFilter].base_data_id.toString());
        formData.append('related_id', document.related_id.toString());

        checkedServices.forEach((index: number, i: number) => {
            formData.append('items[' + i + '][key]', services.services[index].base_data_field);
            formData.append('items[' + i + '][value]', "Y");
        });

        postServicesStore(formData)
            .then((res: any) => {
                dismissLoading();
                setUpdateCompleted(true);
            }).catch((err: any) => {
                dismissLoading();
                checkAxiosError(err);
            });
    }

    const resetDatamods = () => {
        setResetLoading(true);

        postResetServices()
            .then((res: any) => {
                setResetLoading(false);
                pullToRefresh(null);
            })
            .catch((err: any) => {
                setResetLoading(false);
                checkAxiosError(err);
                pullToRefresh(null);
            });
    }*/

    const getFilters = () => {
        presentLoading("Kérem várjon...");
        fetchServiceFilters()
            .then((res: any) => {
                dismissLoading();
                const data = res.data.data;
                if(data && data.length > 0) {
                    let _tempFilters: FilterProps[] = [];
                    data.forEach((_fil: any) => {
                        _tempFilters.push({
                            type: _fil.type,
                            base_data_id: _fil.base_data_id,
                            document_id: _fil.document_id,
                            label: _fil.label,
                        });
                    });
                    setFilters(_tempFilters);

                    let _temp_selectedFilterIndex: any = LocalStorage.get("selectedServiceFilterIndex");
                    _temp_selectedFilterIndex = parseInt(_temp_selectedFilterIndex);
                    if (_temp_selectedFilterIndex && _tempFilters[_temp_selectedFilterIndex] !== undefined) {
                        setSelectedFilterIndex(_temp_selectedFilterIndex);
                    } else {
                        setSelectedFilterIndex(0);
                    }
                }
            })
            .catch((err: any) => {
                dismissLoading();
                checkAxiosError(err);
            });
    }

    const getServices = () => {
        if (selectedFilterIndex === null) {
            return;
        }

        fetchServices(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id
        )
        .then((res: any) => {
            if (res.data.data.document) {
                setDocument(res.data.data.document);
            }
            if (res.data.data.services) {
                setServices(res.data.data.services);
            }
            if (res.data.data.customer_contracts_count) {
                setCustomerContractsCount(res.data.data.customer_contracts_count);
            }
            handleUpdateDatamodStatus();
            dismissLoading();
        })
        .catch((err: any) => {
            dismissLoading();
            checkAxiosError(err);
        });
    }

    const handleChange = (checked: boolean, service: ServiceProps) => {
        // presentLoading("Kérem várjon...");

        let _services = [ ...services ];
        _services.map((s: ServiceProps, i: number) => {
            if (s.id === service.id) {
                s.value = checked ? "Y" : "N";
                s.is_editable = false;
            }
        });
        setServices(_services);

        postServicesStore(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            document?.related?.id,
            service.base_data_field,
            checked ? "Y" : "N"
        )
        .then((res: any) => {
            getServices();
        })
        .catch((err: any) => {
            dismissLoading();
            checkAxiosError(err);
        });
    }

    const handleUpdateDatamodStatus = () => {
        getDatamodStatus(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            1
        )
        .then((res: any) => {
            setDatamodStatus(res.data.data);
        }).catch((err) => {
            console.error(err);
            checkAxiosError(err);
        });
    }

    const getTotal = (old: boolean) => {
        let _def = 0;
        
        if (document?.related?.U_ElofizetesiDij) {
            _def += parseInt(document.related.U_ElofizetesiDij) * 1.27; 
        }
        
        _def += services
            .filter((service: ServiceProps) => service.is_monthly_fee === "1")
            .reduce((sum: number, service: ServiceProps) => {
                const price_field = document?.related[service.base_data_price_field];
                if (old) {
                    if (document.related[service.base_data_field] === "Y") {
                        if (price_field !== undefined) {
                            if (price_field === ".000000") {
                                return sum;
                            } else {
                                return sum + parseInt(price_field) * 1.27;
                            }
                        } else {
                            return sum + parseInt(service.price) * 1.27;
                        }
                    }

                    return sum;
                } else {
                    if (service.value === "N") {
                        return sum;
                    }

                    const has_price = document?.related[service.base_data_field] === "Y";
                    if (price_field !== undefined && has_price) {
                        if (price_field === ".000000") {
                            return sum;
                        } else {
                            return sum + parseInt(price_field) * 1.27;
                        }
                    }
    
                    return sum + parseInt(service.price) * 1.27;
                }
            }, 0);

        return _def;
    }

    const handleSaveButton = () => {
        presentLoading("Kérem várjon...");
        postServicesPreview(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
        )
        .then((res: any) => {
            dismissLoading();
            setSaveAlertHtml(res?.data?.data??"");
            setSaveAlert(true);
        })
        .catch((err: any) => {
            dismissLoading();
            checkAxiosError(err);
        });
    }

    const handleClickReady = () => {
        presentLoading("Kérem várjon...");
        postDatamodReady(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            1
        )
        .then((res: any) => {
            dismissLoading();
            getServices();
            handleUpdateDatamodStatus();
            setSaveAlertHtml("");
            setSaveAlert(false);
            presentAlert({
                header: "Siker",
                message: "A szolgáltatás rendelési kérelem beküldésre került, hamarosan kollégánk foglalkozni fog kérésével. A folyamat kezdetéig a megrendelés még módosítható.",
                buttons: [
                    "Rendben",
                ],
            });
        })
        .catch((err: any) => {
            dismissLoading();
            checkAxiosError(err);
        });
    }

    const handleDeleteAll = () => {
        presentLoading("Kérem várjon...");
        postDatamodAllDelete(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            1
        )
        .then((res: any) => {
            dismissLoading();
            getServices();
            handleUpdateDatamodStatus();
            presentAlert({
                header: "Siker",
                message: "A szolgáltatás megrendelési kérelem visszavonva!",
                buttons: [
                    "Rendben",
                ],
            });
        })
        .catch((err: any) => {
            dismissLoading();
            getServices();
            handleUpdateDatamodStatus();
            checkAxiosError(err);
        });
    }

    useIonViewWillEnter(() => {
        setFilters([]);
        setServices([]);
        setSelectedFilterIndex(null);
        setDatamodStatus(null);
        setDocument(null);
        setTotal(0);
        setOldTotal(0);
    });

    useIonViewDidEnter(() => {
        getFilters();
    });

    useEffect(() => {
        if (selectedFilterIndex !== null) {
            setDatamodStatus(null);
            setServices([]);
            presentLoading("Kérem várjon...");
            getServices();

            LocalStorage.set("selectedServiceFilterIndex", selectedFilterIndex.toString());
        }
    }, [selectedFilterIndex]);

    useEffect(() => {
        if(services && services.length > 0) {
            setTotal(getTotal(false));
            if (total === 0) {
                setOldTotal(getTotal(true));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services]);

    return (
        <PageWrapper>
            <div className="hasButton">
                <h1 className="pageTitle">Szolgáltatások</h1>
            </div>
            
            <div className="inputWithModalWrapper">
                <IonItem className="inputWithModal highlightedSelect" lines="none">
                    <IonSelect 
                        cancelText="Mégse"
                        interface="action-sheet"
                        className="fullLength"
                        value={selectedFilterIndex}
                        placeholder="Kérem válasszon"
                        disabled={filters.length === 0}
                        onIonChange={(e) => setSelectedFilterIndex(e.detail.value)}
                    >
                        {filters.map((filter, index) => 
                            <IonSelectOption key={index} value={index}>{filter.label}</IonSelectOption>
                        )}
                    </IonSelect>
                </IonItem>
            </div>

            <IonCard className="cardButton alert" button={false}>
                <div id="serviceAlert" className="success">
                    <h2>
                        Jelenlegi havidíj:
                    </h2>
                    <h2>
                        <NumberFormat
                            prefix={"Bruttó "}
                            value={oldTotal ? Math.floor(oldTotal) : 0}
                            displayType={'text'}
                            thousandSeparator={" "}
                            suffix={' Ft/hó'}
                            decimalScale={0}
                        />
                    </h2>
                </div>
            </IonCard>
            <br/>
            <IonCard className="cardButton alert warning" button={false}>
                <div id="serviceAlert" className="success">
                    <h2>
                        Várható havidíj:
                    </h2>
                    <h2>
                        <NumberFormat
                            prefix={"Bruttó "}
                            value={total ? Math.floor(total) : 0}
                            displayType={'text'}
                            thousandSeparator={" "}
                            suffix={' Ft/hó'}
                            decimalScale={0}
                        />
                    </h2>
                </div>
            </IonCard>
            <br/>

            {services.length > 0 &&
                <>
                    {services.map((service: ServiceProps, index: number) => 
                        <ServiceAccordionItem 
                            key={service.id} 
                            service={service} 
                            document={document}
                            datamodStatus={datamodStatus}
                            onChange={(checked: boolean) => {
                                handleChange(checked, service);
                            }}
                        />
                    )}
                </>
            }
            
            <br/>
            <br/>
            <br/>
            {(datamodStatus !== null && datamodStatus?.status == 0 && datamodStatus?.is_ready == 0) &&
                <IonFab vertical="bottom" horizontal="start" slot="fixed" className="fixedFab">
                    <IonFabButton
                        color="danger"
                        onClick={() => {
                            presentAlert({
                                header: "Figyelem",
                                message: "Biztosan visszavonja a szolgáltatás megrendelést?",
                                buttons: [
                                    "Mégse",
                                    {
                                        text: "Igen",
                                        role: "destructive",
                                        handler: () => {
                                            handleDeleteAll();
                                        }
                                    },
                                ],
                            });
                        }}
                    >
                        <IonIcon icon={trash} />
                    </IonFabButton>
                </IonFab>
            }
            
            {datamodStatus?.can_be_saved &&
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="fixedFab">
                    <IonFabButton 
                        color="success"
                        onClick={() => {
                            if (customerContractsCount > 1) {
                                presentAlert({
                                    header: "Figyelem",
                                    message: "A megrendeléstől számítva annak jóváhagyásáig a szolgáltatások további megrendelése a partnerhez tartozó valamennyi szerződés esetében nem lehetséges! Amennyiben egyéb szerződéséhez is szeretne szolgáltatást rendelni, akkor ezt még a beküldés előtt kell a listában kiválsztani,. és a megrendelést együtt leadni.",
                                    buttons: [
                                        {
                                            text: "Megrendelem",
                                            handler: () => {
                                                handleSaveButton();
                                            }
                                        },
                                        {
                                            text: "Vissza a szerződéseimhez",
                                            role: "destructive",
                                        }
                                    ],
                                });
                            } else {
                                handleSaveButton();
                            }
                        }}
                    >
                        <IonIcon icon={checkmark} />
                    </IonFabButton>
                </IonFab>
            }
            
            <IonModal
                isOpen={saveAlert}
                keyboardClose={false}
                swipeToClose={false}
                backdropDismiss={false}
                onDidDismiss={() => {
                    setSaveAlert(false);
                }}
            >
                <IonHeader translucent>
                    <IonToolbar>
                        <IonTitle>Megállapodás</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => {
                                setSaveAlert(false);
                                setAszfOneChecked(false);
                                setAszfTwoChecked(false);
                                setAszfThreeChecked(false);
                            }}>
                                Mégse
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen className="ion-padding">
                    <div dangerouslySetInnerHTML={{ __html: saveAlertHtml }} />
                    <IonItem lines="none">
                        <IonCheckbox checked={aszfOneChecked} onIonChange={e => setAszfOneChecked(e.detail.checked)} />
                        <IonLabel class="ion-text-wrap">
                            A Szolgáltatóval már megkötött Távfelügyeleti Szolgáltatási Szerződés rendelkezéseit ismeri.
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="none">
                        <IonCheckbox checked={aszfTwoChecked} onIonChange={e => setAszfTwoChecked(e.detail.checked)} />
                        <IonLabel class="ion-text-wrap">
                            A Megrendelő a Mypatent Felhasználási és általános szerződési feltételei-t és az Adatvédelmi nyilatkozat-ot az elnevezésre kattintásával megnyitotta, elolvasta és magára nézve kötelezően elfogadja.
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="none">
                        <IonCheckbox checked={aszfThreeChecked} onIonChange={e => setAszfThreeChecked(e.detail.checked)} />
                        <IonLabel class="ion-text-wrap">
                            Tisztában van azzal, hogy a „Kérelem beküldése” gombra, majd ezt követően a „Fizetési kötelezettséggel járó megrendelés” gombra kattintással a Kiegészítő Szolgáltatást megrendeli. A megrendelés Szolgáltató által történő elfogadásával, visszaigazolásával a megállapodás a fenti feltételek szerint létrejön.
                        </IonLabel>
                    </IonItem>
                    <IonButton
                        expand="block"
                        color="success"
                        onClick={handleClickReady}
                        disabled={!aszfOneChecked || !aszfTwoChecked || !aszfThreeChecked}
                    >
                        Fizetési kötelezettséggel járó megrendelés
                    </IonButton>
                    <IonButton expand="block" color="medium" onClick={() => {
                        setSaveAlert(false);
                    }}>
                        Mégse
                    </IonButton>
                </IonContent>
            </IonModal>

            {/*<IonAlert
                isOpen={saveAlert}
                onDidDismiss={() => setSaveAlert(false)}
                header="Figyelem"
                message={saveAlertHtml}
                buttons={[
                    {
                        text: "Mégse",
                        role: "cancel",
                        handler: () => {
                            setSaveAlert(false);
                        },
                    },
                    {
                        text: "Igen",
                        handler: () => {
                            handleSave();
                        },
                    }
                ]}
            />*/}

            {/*<IonAlert
                isOpen={showResetDatamodsAlert}
                onDidDismiss={() => {
                    setShowResetDatamodsAlert(false);
                }}
                header="Figyelem"
                message="Csak a feldolgozásra váró szolgáltatási kérelmek kerülnek visszavonásra."
                buttons={[
                    {
                        text: 'Igen',
                        handler: () => {
                            resetDatamods();
                            setShowResetDatamodsAlert(false);
                        }
                    },
                    {
                        text: 'Nem',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowResetDatamodsAlert(false);
                        }
                    }
                ]}
            />*/}
            <br/>
            <br/>
            <br/>
            {/*(datamodStatus !== null && datamodStatus?.status == 0) &&
                <IonFab vertical="bottom" horizontal="start" slot="fixed" className="fixedFab">
                    <IonFabButton
                        color="danger"
                        onClick={() => {
                            presentAlert({
                                header: "Figyelem",
                                message: "Biztosan visszavonja a módosított adatokat? Csak a feldolgozásra váró adatmódosítások kerülnek visszavonásra.",
                                buttons: [
                                    "Mégse",
                                    {
                                        text: "Igen",
                                        handler: () => {
                                            handleDeleteAll();
                                        }
                                    },
                                ],
                            });
                        }}
                    >
                        <IonIcon icon={trash} />
                    </IonFabButton>
                </IonFab>
            */}
        </PageWrapper>
    );
};

export default ServicesNewPage;
