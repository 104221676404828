export default function ValidateRegistration(values: any) {
    const errors:any = {};
    
    if (!values.lastname) {
        errors.lastname = 'A Vezetéknév mező kötelező!';
    }

    if (!values.firstname) {
        errors.firstname = 'A Keresztnév mező kötelező!';
    }

    if (!values.email) {
        errors.email = 'Az e-mail cím mező kötelező!';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Hibás e-mail cím!';
    }

    if(!values.password) {
        errors.password = 'A jelszó mező kötelező!';
    } else if(values.password.length < 6) {
        errors.password = 'A jelszó legalább 6 karakter!';
    }

    if(!values.passwordVerify) {
        errors.passwordVerify = 'A "Jelszó újra" mező kötelező!';
    } else if(values.passwordVerify.length < 6) {
        errors.passwordVerify = 'A "Jelszó újra" legalább 6 karakter!';
    }

    if(values.password !== values.passwordVerify) {
        errors.passwordVerify = 'A két jelszó nem egyezik meg!';
    }

    if(!values.privacy) {
        errors.privacy = 'Az Adatvédelmi nyilatkozatot elfogadása kötelező!';
    }

    return errors;
}