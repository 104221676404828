export default function ValidateNewPassword(values: any) {
    let errors:any = {};

    if(!values.password) {
        errors.password = 'A "Régi jelszó" mező kötelező!';
    } else if(values.password.length < 6) {
        errors.password = 'A "Régi jelszó" mező legalább 6 karakter!';
    }

    if(!values.password_new) {
        errors.password_new = 'Az "Új jelszó" mező kötelező!';
    } else if(values.password_new.length < 6) {
        errors.password_new = 'Az "Új jelszó" mező legalább 6 karakter!';
    }

    if(!values.password_new_re) {
        errors.password_new_re = 'Az "Új jelszó mégegyszer" mező kötelező!';
    } else if(values.password_new_re.length < 6) {
        errors.password_new_re = 'Az "Új jelszó mégegyszer" mező legalább 6 karakter!';
    }

    if(values.password_new !== values.password_new_re) {
        errors.password_new_re = 'A két új jelszó nem megegyező!';
    }

    return errors;
}
