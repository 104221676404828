import React from 'react';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import PageWrapper from '../layouts/PageWrapper';

const DatamodCompletePage: React.FC = () => {


    return (
        <PageWrapper>
            <IonText color="success">
                <h1 className="ion-text-center alertIcon">
                    <IonIcon icon={checkmarkCircleOutline} />
                </h1>
                <h2 className="ion-text-center alertText">
                    Adatmódosítási kérelem beküldve
                </h2>
            </IonText>

            <p className="ion-text-center">Hamarosan kollégánk foglalkozni fog az Ön adatmódosítási kérelmével. A folyamat kezdetéig még nyugodtan módosíthatja adatait, viszont utána már csak a módosítási folyamat után lesz rá lehetősége.</p>

            <IonButton color="success" expand="block" routerDirection="back" routerLink="/my/profile">Vissza az Adataim oldalra</IonButton>
            <IonButton color="medium" expand="block" routerDirection="back" routerLink="/my/dashboard">Főoldal</IonButton>
        </PageWrapper>
    );
};

export default DatamodCompletePage;
