import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App } from '@capacitor/app';

const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    useEffect(() => {
        App.addListener("appUrlOpen", (data: any) => {
            console.log(data.url.toString());
            const passwordRecoveryIndex = data.url.toString().indexOf("/password-recovery/");
            if (passwordRecoveryIndex > -1) {
                history.push(data.url.substring(passwordRecoveryIndex));
            } else {
                const slug = data.url.replace(
                    "mypatent://payment",
                    "/my/invoices/payment-response"
                );
                if (slug) {
                    history.push(slug);
                    return;
                }
            }
        });
    }, []);
    return <></>;
};

export default AppUrlListener;
