import React, { useState } from 'react';

import {
    IonContent,
    IonGrid,
    IonButton,
    IonLoading,
    IonInput,
    IonItem,
    IonPage,
    IonRow,
    IonCol,
    IonRouterLink,
    IonLabel,
    IonCheckbox,
} from "@ionic/react";
import { Redirect } from 'react-router-dom';
import { useAuth } from '../context/Auth';
import Axios from 'axios';

import useFormValidation from '../helpers/FormValidationHelper';
import ValidateRegistration from '../helpers/validators/ValidateRegistration';
import MyPatentHeader from '../components/MyPatentHeader';
import { setLoading, setInitError, hasError, addApiErrors, ErrorsList } from '../helpers/GlobalHelpers';

import logo from '../assets/images/logo.svg';

import './LoginPage.scss';
import { postRegister } from '../services/GlobalServices';
import { sendBarionPixel } from '../helpers/barion/PixelHelper';

interface Props {
    onRegistration: (login: boolean, token: string) => void;
}

const INIT_STATE = {
    lastname: '',
    firstname: '',
    email: '',
    password: '',
    passwordVerify: '',
    privacy: false,
};

const RegistrationPage: React.FC<Props> = ({ onRegistration }) => {
    const { handleChange, values } = useFormValidation(INIT_STATE);
    const { loggedIn } = useAuth();
    const [status, setStatus] = useState({
        loading: false,
        error_list: INIT_STATE,
    });
    const [ isLogoHidden, setIsLogoHidden ] = useState(false);

    const getScrollTop = (e: CustomEvent) => {
        if(e.detail.scrollTop >= 60) {
            setIsLogoHidden(true);
        } else {
            setIsLogoHidden(false);
        }
    }
    
    const handleRegister = async () => {
        setLoading(true, setStatus);
        setInitError(INIT_STATE, setStatus);

        const errors = ValidateRegistration(values);
        if (hasError(errors)) {
            setLoading(false, setStatus);
            addApiErrors(errors, setStatus);
        } else {
            postRegister(
                values.firstname,
                values.lastname,
                values.email,
                values.password,
                values.passwordVerify
            )
            .then((res: any) => {
                sendBarionPixel("signUp", {
                    contentType: "Page",
                    id: "loginPage",
                    name: "Register",
                });
                setLoading(false, setStatus);
                onRegistration(true, res.data.access_token);
            }).catch((err: any) => {
                setLoading(false, setStatus);
                if(!err.response) {
                    addApiErrors('Hálózati hiba.', setStatus);
                } else {
                    addApiErrors(err.response.data.message, setStatus);
                }
            });
        }
    }

    if (loggedIn !== null && loggedIn === true) {
        return <Redirect to="/my/dashboard" />;
    }
    
    return (
        <IonPage>
            <IonContent id="loginPage">
                <IonContent className="backgroundTransparent" scrollEvents={true} onIonScroll={(e) => getScrollTop(e)}>
                    <MyPatentHeader isLogoHidden={isLogoHidden} />

                    <IonGrid className="ion-padding" id="ionGridInner" fixed>
                        <div className="page-content">
                            <img src={logo} alt="myPatent Logo" className="desktopLogo" />

                            <h1 className="pageTitle">Regisztráció</h1>

                            <div className="form">
                                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.lastname ? 'error' : '')}>
                                    <IonLabel position="floating">Vezetéknév</IonLabel>
                                    <IonInput type="text" autocapitalize="on" name="lastname" autocomplete="family-name" onIonInput={handleChange} value={values.lastname} required></IonInput>
                                </IonItem>
                                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.firstname ? 'error' : '')}>
                                    <IonLabel position="floating">Keresztnév</IonLabel>
                                    <IonInput type="text" autocapitalize="on" name="firstname" autocomplete="given-name"  onIonInput={handleChange} value={values.firstname} required></IonInput>
                                </IonItem>
                                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.email ? 'error' : '')}>
                                    <IonLabel position="floating">E-mail cím</IonLabel>
                                    <IonInput type="email" name="email" autocomplete="email" onIonInput={handleChange} value={values.email} required></IonInput>
                                </IonItem>
                                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.password ? 'error' : '')}>
                                    <IonLabel position="floating">Jelszó</IonLabel>
                                    <IonInput clearOnEdit={false} type="password" name="password" onIonInput={handleChange} value={values.password} required></IonInput>
                                </IonItem>
                                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.passwordVerify ? 'error' : '')}>
                                    <IonLabel position="floating">Jelszó újra</IonLabel>
                                    <IonInput clearOnEdit={false} type="password" name="passwordVerify" onIonInput={handleChange} value={values.passwordVerify} required></IonInput>
                                </IonItem>
                                <IonItem lines="none" className={"checkbox-input backgroundTransparent " + (Boolean(status.error_list) && status.error_list.privacy ? 'error' : '')}>
                                    <IonCheckbox color="secondary" name="privacy" onIonChange={handleChange} checked={values.privacy} slot="start"></IonCheckbox>
                                    <IonLabel color="secondary">Az <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_MYPATENT_PRIVACY_URL??'#'}>Adatvédelmi nyilatkozatot</a> elfogadom</IonLabel>
                                </IonItem>

                                <ErrorsList errors={status.error_list} />

                                <div className="ion-text-center">
                                    <IonButton size="large" expand="block" onClick={handleRegister}>Regisztrálok</IonButton>
                                </div>
                                <br />
                                <IonRow>
                                    <IonCol size="6">
                                        <IonRouterLink routerDirection="back" routerLink="/login">
                                            Már van fiókom
                                        </IonRouterLink>
                                    </IonCol>
                                    <IonCol size="6">
                                    </IonCol>
                                </IonRow>
                                <br />
                            </div>

                            <IonLoading
                                isOpen={status.loading}
                                message={'Kérem várjon...'}
                            />
                        </div>
                    </IonGrid>
                </IonContent>
            </IonContent>
        </IonPage>
    );
}

export default RegistrationPage;
