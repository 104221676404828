import React, { useState } from 'react';
import {
    IonItem,
    IonLabel,
    IonText,
    IonToggle,
    IonBadge,
} from "@ionic/react";

import moment from "moment";
import NumberFormat from 'react-number-format';
import { DatamodStatusModel, ServiceProps } from '../models/GlobalModels';

import './AccordionItem.scss';

interface Props {
    service: ServiceProps;
    onChange: (checked: boolean) => void;
    document: any;
    datamodStatus: DatamodStatusModel|null;
}

const ServiceAccordionItem: React.FC<Props> = ({
    service,
    onChange,
    document,
    datamodStatus
}) => {
    const [ isOpen, setIsOpen ] = useState(false);

    const handleOpen = () => { 
        setIsOpen(!isOpen);
    }

    const getPrice = () => {
        let price: number = service?.price ? parseInt(service?.price) : 0;
        const price_field = document?.related[service.base_data_price_field];
        const has_personal_price = document?.related[service.base_data_field] === "Y";
        if (price_field !== undefined && has_personal_price) {
            if (price_field === ".000000") {
                price = 0;
            } else {
                price = parseInt(price_field);
            }
        }
        return <NumberFormat 
            value={Math.floor(price * 1.27)} 
            displayType={'text'} 
            thousandSeparator={" "}
            prefix={"Bruttó "}
            suffix={service.is_monthly_fee === "1" ? ' Ft/hó' : ' Ft'}
            decimalScale={0}
        />
    }

    return (
        <div className="accordionItemWrapper">
            <IonItem className={"accordionItem " + (isOpen ? "isOpen" : "")} lines="none" button={true} detail={false} onClick={() => handleOpen()}>
                <IonLabel>
                    <h2 className="title">
                        <IonText color={service.current_value_in_db === "Y" ? "success" : ""}>
                            {service.name??''}
                        </IonText>
                    </h2>
                    <IonText>
                        <small>
                            {getPrice()}
                        </small>
                    </IonText>
                    <br/>
                    {service.has_datamod_in_progress && 
                        <>
                            {datamodStatus?.is_ready == 1 ?
                                <IonBadge slot="end" color="primary" className="service-badge">
                                    Feldolgozásra vár
                                </IonBadge>
                            :
                                <IonBadge slot="end" color="warning" className="service-badge">
                                    Beküldésre vár
                                </IonBadge>
                            }
                        </>
                    }
                    {service.current_value_in_db === "Y" &&
                        <>
                            <IonBadge color="success" className="service-badge">
                                Előfizetve
                            </IonBadge>
                        </>
                    }
                    {(service.valid_at_field && document?.related[service.valid_at_field]) &&
                        <>
                            <IonBadge color="medium" className="service-badge">
                             Kezdete: {moment(document?.related[service.valid_at_field]).format("YYYY.MM.DD.")}
                            </IonBadge>
                        </>
                    }
                </IonLabel>
                <IonToggle 
                    color="success" 
                    slot="end" 
                    className={(!service.is_editable || datamodStatus?.is_ready == 1) ? "disabled" : ""} 
                    checked={service.value === "Y"}
                    onClick={(e: any) => {
                        onChange(service.value === "Y" ? false : true);
                    }}
                />
            </IonItem>
            <div className={"subItem " + (isOpen ? "isOpen" : "")}>
                <div className="subItem-inner">
                    <div dangerouslySetInnerHTML={{ __html: service.description??"" }} />
                    <br/>
                    <p>
                        A szolgáltatás díja: {getPrice()}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ServiceAccordionItem;
