import { HttpClient } from "../helpers/HttpClient";

export const logout = async () => {
    return await HttpClient({
        url: `/v1/logout`,
        method: "get",
    });
};

export const deletePushToken = async (token: string) => {
    return await HttpClient({
        url: `/v1/push-token-delete`,
        method: "post",
        data: {
            token,
        }
    });
};

export const postPushToken = async (token: string) => {
    return await HttpClient({
        url: `/v1/push-token`,
        method: "post",
        data: {
            token,
        }
    });
};

export const postNewPassword = async (
    password: string,
    password_new: string,
    password_new_re: string
) => {
    return await HttpClient({
        url: `/v1/new-password`,
        method: "post",
        data: {
            password,
            password_new,
            password_new_re,
        }
    });
};

export const postCancelUser = async (
    password: string
) => {
    return await HttpClient({
        url: `/v2/cancellation-request`,
        method: "post",
        data: {
            password,
        }
    });
};

export const postReadMessage = async (id: string) => {
    return await HttpClient({
        url: `/v1/messages/read`,
        method: "post",
        data: {
            id
        }
    });
};

export const getUnreadMessageCount = async () => {
    return await HttpClient({
        url: `/v1/messages/unread-count`,
        method: "get",
    });
};

export const postLogin = async (email: string, password: string) => {
    return await HttpClient({
        url: `/v1/login`,
        method: "post",
        data: {
            email,
            password
        }
    });
};

export const postLostPassword = async (email: string) => {
    return await HttpClient({
        url: `/v1/lost-password`,
        method: "post",
        data: {
            email
        }
    });
};

export const postPasswordRecovery = async (
    password: string,
    password_confirmation: string,
    hash: string,
    email: string,
) => {
    return await HttpClient({
        url: `/v1/password-recovery`,
        method: "post",
        data: {
            password,
            password_confirmation,
            hash,
            email
        }
    });
};

export const postRegister = async (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    password_confirmation: string,
) => {
    return await HttpClient({
        url: `/v1/register`,
        method: "post",
        data: {
            first_name,
            last_name,
            email,
            password,
            password_confirmation,
        }
    });
};

export const postRegisterConfirm = async (pin: string) => {
    return await HttpClient({
        url: `/v1/register-confirm`,
        method: "post",
        data: {
            pin
        }
    });
};

export const getNewPin = async () => {
    return await HttpClient({
        url: `/v1/new-pin`,
        method: "get",
    });
};

export const postAssignSmsVerification = async (
    relation_id: string,
    sms_code: string
) => {
    return await HttpClient({
        url: `/v1/assign/sms-verification`,
        method: "post",
        data: {
            relation_id,
            sms_code,
        }
    });
};

export const postAssignSmsSendCode = async (
    relation_id: string
) => {
    return await HttpClient({
        url: `/v1/assign/sms-send-code`,
        method: "post",
        data: {
            relation_id,
        }
    });
};

export const postAssignNewPhone = async (
    relation_id: string,
    phone: string
) => {
    return await HttpClient({
        url: `/v1/assign/sms-send-code`,
        method: "post",
        data: {
            relation_id,
            phone
        }
    });
};

export const postAssign = async (
    customer_code: string
) => {
    return await HttpClient({
        url: `/v1/assign`,
        method: "post",
        data: {
            customer_code
        }
    });
};

export const getUser = async () => {
    return await HttpClient({
        url: `/v1/user`,
        method: "get",
    });
};

export const getRelation = async () => {
    return await HttpClient({
        url: `/v1/get-relations`,
        method: "get",
    });
};

export const getVersion = async () => {
    return await fetch('https://mypatent.hu/version.json', { cache: "no-cache" })
        .then(r => r.json())
        .then(json => {
            return json?.version??null;
        });
};
