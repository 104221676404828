import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { AlertButton, IonApp, IonLoading, IonToast, useIonAlert, useIonToast } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Capacitor } from "@capacitor/core";
import { App as AppCapacitor } from "@capacitor/app";
import { PushNotifications } from "@capacitor/push-notifications";
import { AppLauncher } from "@capacitor/app-launcher";
import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";

import { AuthContext } from "./context/Auth";
import AppTabs from "./AppTabs";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import NotFoundPage from "./pages/NotFoundPage";
import AssignPage from "./pages/AssignPage";
import LostPasswordPage from "./pages/LostPasswordPage";
import PasswordRecoveryPage from "./pages/PasswordRecoveryPage";
import RegistrationVerifyPage from "./pages/RegistrationVerifyPage";
import { deletePushToken, getRelation, getUser, getVersion, logout } from "./services/GlobalServices";
import LocalStorage from "./helpers/LocalStorage";
import { GetBasicPlatforms, hasNewVersion } from "./helpers/GlobalHelpers";
import AppUrlListener from "./components/AppUrlListener";
import BarionPixelConsent from "./components/barion/BarionPixelConsent";
import PublicInvoicesPaymentPage from "./pages/public/PublicInvoicesPaymentPage";

import "./App.scss";
import PublicPaymentResponsePage from "./pages/public/PublicPaymentResponsePage";

const App: React.FC = () => {
  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();

  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [authToken, setAuthToken] = useState("");
  const [assigns, setAssign] = useState([]);
  const [assignsLoaded, setAssignLoaded] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [logoutLoader, setLogoutLoader] = useState<boolean>(false);
  const [isOffline, setIsOffline] = useState<boolean>(false);
  const [lastBackButtonTime, _setLastBackButtonTime] = useState<number>(new Date().getTime());
  const [isDev, setIsDev] = useState<boolean>(LocalStorage.get("isDev") === "true");
  const [showPixelConsent, setShowPixelConsent] = useState<boolean>(false);
  const pixelConsent = LocalStorage.get("barion_pixel_consent");

  const lastBackButtonTimeRef = useRef(lastBackButtonTime);

  const setAuthStatus = (login: boolean, token: string) => {
    if (login) {
      setUserInfo(null);
      setAssignLoaded(false);
      setAssigns("empty");

      setAuthToken(token);

      if (LocalStorage.get("authToken") !== token) {
        LocalStorage.set("authToken", token);
      }

      getUser()
        .then((res: any) => {
          setUserInfo(res.data.data);
          getRelation()
            .then((res: any) => {
              if (Object.keys(res.data.data).length > 0) {
                setAssigns(res.data.data);
              }

              setAssignLoaded(true);
            })
            .catch((err: any) => {
              checkAxiosError(err);
            });
        })
        .catch((err: any) => {
          checkAxiosError(err);
        });
    }

    setLoggedIn(login);
  };

  const checkAxiosError = (error: any) => {
    if (!error.response) {
      presentToast("Hiba történt!", 4000);
    }

    if (error.response !== undefined && error.response.status === 401) {
      setLogout();
    }
  };

  const setLogout = () => {
    setLogoutLoader(true);
    logout()
      .then((res) => {
        emptyLocalItems();
        setLogoutLoader(false);
      })
      .catch((err) => {
        emptyLocalItems();
        setLogoutLoader(false);
      });
  };

  const emptyLocalItems = () => {
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      deletePushToken(LocalStorage.get("pushToken")).catch((err) => {
        console.error(err);
      });
      PushNotifications.removeAllListeners();
    } else {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }

    LocalStorage.set("pushToken", null);
    LocalStorage.set("authToken", null);
    setAuthStatus(false, "");
    setAssigns("empty");
    setUserInfo(null);
    setAuthToken("");
  };

  const setAssigns = (assign: any) => {
    if (assign === "empty") {
      setAssign([]);
    } else {
      setAssign((prevState) => [...prevState, assign]);
    }
  };

  const setLastBackButtonTime = (data: any) => {
    lastBackButtonTimeRef.current = data;
    _setLastBackButtonTime(data);
  };

  const getNewVersionAlertButtons = (): AlertButton[] => {
    let buttons: AlertButton[] = [
      {
        text: "Mégse",
        role: "cancel",
      },
      {
        text: "Frissítés" + (Capacitor.isNativePlatform() && GetBasicPlatforms() === "android" ? " - Android" : ""),
        handler: () => {
          if (Capacitor.isNativePlatform()) {
            var ret = AppLauncher.canOpenUrl({
              url: process.env.REACT_APP_MYPATENT_ANDROID_DOWNLOAD_URL,
            });
            if (GetBasicPlatforms() === "android") {
              if (ret) {
                AppLauncher.openUrl({
                  url: process.env.REACT_APP_MYPATENT_ANDROID_DOWNLOAD_URL,
                });
              }
            } else {
              ret = AppLauncher.canOpenUrl({
                url: process.env.REACT_APP_MYPATENT_IOS_DOWNLOAD_URL,
              });
              if (ret) {
                AppLauncher.openUrl({
                  url: process.env.REACT_APP_MYPATENT_IOS_DOWNLOAD_URL,
                });
              }
            }
          } else {
            window.location.reload();
          }
        },
      },
    ];
    if (Capacitor.isNativePlatform() && GetBasicPlatforms() === "android") {
      buttons.push({
        text: "Frissítés - Huawei",
        handler: () => {
          var ret = AppLauncher.canOpenUrl({
            url: process.env.REACT_APP_MYPATENT_HUAWEI_DOWNLOAD_URL,
          });
          if (ret) {
            AppLauncher.openUrl({
              url: process.env.REACT_APP_MYPATENT_HUAWEI_DOWNLOAD_URL,
            });
          }
        },
      });
    }
    return buttons;
  };

  const checkUpdate = async () => {
    let ver: string = null;
    ver = await getVersion()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });

    if (ver !== null) {
      if (hasNewVersion(ver)) {
        presentAlert({
          header: "Figyelem",
          message: "Új verzió érhető el az alkalmazásból, kérem frissítse azt, mert az elavult verzió problémákat okozhat!",
          buttons: getNewVersionAlertButtons(),
        });
      }
    }
  };

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      if (Capacitor.isPluginAvailable("PushNotifications")) {
        PushNotifications.removeAllDeliveredNotifications();
      }
      AppCapacitor.addListener("backButton", (e: any) => {
        if (window.location.pathname === "/my/dashboard" || window.location.pathname === "/login") {
          const now = new Date().getTime();
          if (now - lastBackButtonTimeRef.current < 3000) {
            AppCapacitor.exitApp();
          } else {
            presentToast("Nyomja meg mégegyszer a kilépéshez", 3000);
          }

          setLastBackButtonTime(new Date().getTime());
        } else {
          const backdrops = document.getElementsByTagName("ion-backdrop");
          if (backdrops && backdrops.length) {
            backdrops[0].click();
          } else {
            window.history.back();
          }
        }
      });
      checkUpdate();
    }
  }, []);

  useEffect(() => {
    setAuthStatus(Boolean(LocalStorage.get("authToken")), LocalStorage.get("authToken"));

    window.addEventListener("offline", () => {
      setIsOffline(true);
    });

    window.addEventListener("online", () => {
      setIsOffline(false);
    });
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pixelConsent === null || pixelConsent === "") {
        setShowPixelConsent(true);
    }
  }, [pixelConsent]);

  return (
    <IonApp id="main">
      <ClearBrowserCacheBoundary auto={true} duration={60000}>
        <AuthContext.Provider
          value={{
            loggedIn,
            authToken,
            checkAxiosError,
            setLogout,
            assigns,
            setAssigns,
            assignsLoaded,
            userInfo,
            setUserInfo,
          }}
        >
          <IonReactRouter>
            <AppUrlListener />
            <Switch>
              <Route exact path="/login">
                <LoginPage
                  onLogin={(login, token) => setAuthStatus(login, token)}
                  updateDev={() => {
                    setIsDev(LocalStorage.get("isDev") === "true");
                  }}
                />
              </Route>
              <Route exact path="/registration">
                <RegistrationPage onRegistration={(login, token) => setAuthStatus(login, token)} />
              </Route>
              <Route exact path="/lost-password">
                <LostPasswordPage />
              </Route>
              <Route exact path="/password-recovery/:hash/:email">
                <PasswordRecoveryPage onLogin={(login, token) => setAuthStatus(login, token)} />
              </Route>

              {/* Felhasználó nélküli fizetés - Start */}
              <Route exact path="/szamla-fizetes/:id">
                <PublicInvoicesPaymentPage />
              </Route>
              <Route exact path="/szamla-fizetes-valasz">
                <PublicPaymentResponsePage />
              </Route>
              {/* Felhasználó nélküli fizetés - End */}

              <Route path="/my">
                <AppTabs onLogout={() => setAuthStatus(false, "")} />
              </Route>
              <Route exact path="/verify">
                <RegistrationVerifyPage />
              </Route>
              <Route exact path="/assign">
                <AssignPage />
              </Route>
              <Redirect exact path="/" to="/my/dashboard" />
              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
          </IonReactRouter>
        </AuthContext.Provider>

        <IonToast isOpen={isOffline} position="bottom" message="Nincs hálózati hozzáférés." color="danger" />

        {logoutLoader && <IonLoading isOpen={logoutLoader} message={"Kijelentkezés..."} />}

        {/*<BarionPixelConsent show={showPixelConsent} onChange={() => setShowPixelConsent(false)} />*/}

        {/*isDev &&
                    <div id="isDev">Fejlesztői környezet</div>
                */}
      </ClearBrowserCacheBoundary>
    </IonApp>
  );
};

export default App;
