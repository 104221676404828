import { FilterQueryObject } from "../helpers/FilterHelper";
import { HttpClient } from "../helpers/HttpClient";

export const fetchDocumentFilters = async () => {
  return await HttpClient({
    url: `/v1/documents/filters`,
    method: "get",
  });
};

export const fetchDocuments = async (queryObject: FilterQueryObject) => {
  return await HttpClient({
    url: `/v1/documents/view`,
    method: "post",
    data: queryObject,
  });
};

export const fetchDocumentPreview = async (id: string) => {
  return await HttpClient({
    url: `/v1/documents/show`,
    method: "post",
    data: {
      id,
    },
  });
};

export const getActivatedDocuments = async () => {
  return await HttpClient({
    url: `/v1/documents/activated`,
    method: "get",
  });
};

export const postActivateDocument = async (id: string) => {
  return await HttpClient({
    url: `/v1/documents/activate`,
    method: "post",
    data: {
      id,
    },
  });
};
