import { useState } from "react";

function useFormValidation(initialState: any) {
    const [values, setValues] = useState(initialState);

    function handleChange(event: any) {
        if (event) {
            if (
                event.detail &&
                event.detail.checked !== undefined &&
                event.detail.checked !== null
            ) {
                setValues((prevState: any) => ({
                    ...prevState,
                    [event.target.name]: event.detail.checked,
                }));
            } else if (event.target.value !== undefined && event.target.value) {
                setValues((prevState: any) => ({
                    ...prevState,
                    [event.target.name]: event.target.value,
                }));
            }
        }
    }

    function handleDirectChange(name: string, value: string) {
        setValues({
            ...values,
            [name]: value,
        });
    }

    return { handleChange, values, handleDirectChange };
}

export default useFormValidation;
