import React, { useState } from "react";
import { IonContent, IonGrid, IonButton, IonLoading, IonInput, IonItem, IonIcon, IonPage, IonRow, IonCol, IonRouterLink, IonLabel } from "@ionic/react";
import { Redirect } from "react-router-dom";

import { useAuth } from "../context/Auth";
import { setDevOrProd } from "../helpers/DevHelper";
import { postLogin } from "../services/GlobalServices";
import useFormValidation from "../helpers/FormValidationHelper";
import ValidateLogin from "../helpers/validators/ValidateLogin";
import MyPatentHeader from "../components/MyPatentHeader";
import { addApiErrors, setLoading, hasError, setInitError, ErrorsList } from "../helpers/GlobalHelpers";

import { eyeOutline } from "ionicons/icons";

import logo from "../assets/images/logo.svg";
import iosDownload from "../assets/images/ios-download.png";
import androidDownload from "../assets/images/android-download.png";
import huaweiDownload from "../assets/images/huawei-download.png";
import mobileMockup from "../assets/images/mockup.png";
import qr from "../assets/images/qr.png";
import barionLogo from "../assets/images/barion-card-strip-intl.svg";

import "./LoginPage.scss";

interface Props {
  onLogin: (login: boolean, token: string) => void;
  updateDev: () => void;
}

const INIT_STATE = {
  email: "",
  password: "",
};

const LoginPage: React.FC<Props> = ({ onLogin, updateDev }) => {
  const { handleChange, values } = useFormValidation(INIT_STATE);
  const { loggedIn } = useAuth();
  const [status, setStatus] = useState({
    loading: false,
    error_list: INIT_STATE,
  });
  const [isText, setIsText] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    setLoading(true, setStatus);
    setInitError(INIT_STATE, setStatus);

    const errors = ValidateLogin(values);
    if (hasError(errors)) {
      setLoading(false, setStatus);
      addApiErrors(errors, setStatus);
    } else {
      postLogin(values.email, values.password)
        .then((res: any) => {
          onLogin(true, res.data.access_token);
        })
        .catch((err: any) => {
          setLoading(false, setStatus);
          if (!err.response) {
            addApiErrors("Hálózati hiba.", setStatus);
          } else {
            addApiErrors(err.response.data.message, setStatus);
          }
        });
    }
  };

  const [devClicks, setDevClicks] = useState<number>(0);
  const [lastDevClickTime, _setLastDevClickTime] = useState<number>(new Date().getTime());
  const lastDevClickTimeRef = React.useRef(lastDevClickTime);
  const setLastDevClickTime = (data: any) => {
    lastDevClickTimeRef.current = data;
    _setLastDevClickTime(data);
  };

  const devClickHandler = () => {
    const now = new Date().getTime();
    if (now - lastDevClickTimeRef.current < 1000) {
      if (devClicks >= 7) {
        setDevOrProd();
        updateDev();
        setDevClicks(0);
      } else {
        setDevClicks((prevState) => prevState + 1);
      }
    } else {
      if (devClicks === 0) {
        setDevClicks((prevState) => prevState + 1);
      } else {
        setDevClicks(1);
      }
    }

    setLastDevClickTime(new Date().getTime());
  };

  if (loggedIn !== null && loggedIn === true) {
    return <Redirect to="/my/dashboard" />;
  }

  return (
    <IonPage>
      <IonContent id="loginPage">
        <MyPatentHeader isLogoHidden={false} hasMenu={false} />

        <IonContent className="backgroundTransparent">
          <IonGrid className="ion-padding" id="ionGridInner" fixed>
            <div className="page-content">
              <img src={logo} alt="myPatent Logo" className="desktopLogo" onClick={() => devClickHandler()} />

              <h1 className="pageTitle" onClick={() => devClickHandler()}>
                Belépés
              </h1>

              <form className="form">
                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.email ? "error" : "")}>
                  <IonLabel position="floating">E-mail cím</IonLabel>
                  <IonInput
                    title="E-mail cím"
                    onIonInput={handleChange}
                    onKeyDown={handleKeyDown}
                    clearOnEdit={false}
                    autocomplete="email"
                    type="email"
                    inputmode="email"
                    pattern="email"
                    name="email"
                    value={values.email}
                    required
                    autofocus={true}
                    data-testid="input-login-email"
                  ></IonInput>
                </IonItem>

                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.password ? "error" : "")}>
                  <IonLabel position="floating">Jelszó</IonLabel>
                  <IonInput
                    title="Jelszó"
                    onIonInput={handleChange}
                    onKeyDown={handleKeyDown}
                    clearOnEdit={false}
                    autocomplete="current-password"
                    type={isText ? "text" : "password"}
                    pattern="password"
                    name="password"
                    value={values.password}
                    required
                    data-testid="input-login-password"
                  ></IonInput>
                  <IonButton slot="end" color="secondary" fill={isText ? "outline" : "solid"} onClick={() => setIsText(!isText)}>
                    <IonIcon slot="icon-only" icon={eyeOutline} />
                  </IonButton>
                </IonItem>

                <ErrorsList errors={status.error_list} />

                <div className="ion-text-center">
                  <IonButton data-testid="loginButton" id="loginButton" size="large" expand="block" onClick={handleLogin}>
                    Bejelentkezés
                  </IonButton>
                </div>
                <br />
                <IonRow>
                  <IonCol size="6">
                    <IonRouterLink routerDirection="forward" routerLink="/lost-password">
                      Elfelejtett jelszó
                    </IonRouterLink>
                  </IonCol>
                  <IonCol size="6" className="ion-text-right">
                    <IonRouterLink routerDirection="forward" routerLink="/registration">
                      Új vagyok, regisztrálok
                    </IonRouterLink>
                  </IonCol>
                </IonRow>
                <br />
              </form>

              {/*<IonCard className="adsCard">
                                <img src={ads} />
                            </IonCard>*/}

              <IonLoading isOpen={status.loading} message={"Kérem várjon..."} data-testid="loading_alert" />
              <div className="ion-text-center">
                <a rel="noopener noreferrer" href="https://www.barion.com/hu/" target="_blank" id="barionLogo">
                    <img height="30" src={barionLogo} title="Barion" alt="Barion" />
                </a>
              </div>
            </div>
          </IonGrid>

          <div id="appDownload">
            <img src={mobileMockup} alt="Alkalmazás" />
            <h4>Szerezze be alkalmazásunkat az alábbi linkeken telefonjára</h4>
            <div className="downloadButtons">
              <a href={process.env.REACT_APP_MYPATENT_IOS_DOWNLOAD_URL} id="iosDownload">
                <img src={iosDownload} alt="Alkalmazás letöltése az App Store-ból" />
              </a>
              <a href={process.env.REACT_APP_MYPATENT_ANDROID_DOWNLOAD_URL} id="androidDownload">
                <img src={androidDownload} alt="Alkalmazás letöltése az Google Play Áruházból" />
              </a>
              <a href={process.env.REACT_APP_MYPATENT_HUAWEI_DOWNLOAD_URL} id="huaweiDownload">
                <img src={huaweiDownload} alt="Alkalmazás letöltése a Huawei AppGallery alkalmazásból" />
              </a>
            </div>
            <img src={qr} alt="" className="qr" />
          </div>

          <span id="copyright">© {new Date().getFullYear()} Patent Security • Minden jog fenntartva!</span>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
