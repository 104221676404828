import { HttpClient } from "../helpers/HttpClient";

export const fetchServiceFilters = async () => {
    return await HttpClient({
        url: `/v2/services/selectors`,
        method: "get",
    });
};

export const fetchServices = async (
    base_data_id: number,
    document_id: number
) => {
    return await HttpClient({
        url: `/v2/services`,
        method: "get",
        params: {
            base_data_id,
            document_id,
        },
    });
};

export const postServicesStore = async (
    base_data_id: number,
    document_id: number | "",
    model_id: number|null,
    key: string,
    value: string
) => {
    return await HttpClient({
        url: `/v2/datamod`,
        method: "post",
        data: {
            base_data_id,
            document_id,
            model_id,
            model_type: "App\\Models\\DocumentCustomerContract",
            key,
            value,
            is_service: 1,
        },
    });
};

export const postServicesPreview = async (
    base_data_id: number,
    document_id: number | "",
) => {
    return await HttpClient({
        url: `/v2/services/preview`,
        method: "get",
        params: {
            base_data_id,
            document_id,
        },
    });
};
