import React from "react";

import {
    IonBadge,
    IonButton,
    IonItemDivider,
    IonLabel,
    IonSpinner,
    IonText
} from "@ionic/react";

import TextControl from "./TextControl";
import SelectControl from "./SelectControl";
import DateControl from "./DateControl";
import PriceControl from "./PriceControl";
import { DatamodStatusModel, FieldModel } from "../../../models/GlobalModels";

interface Props {
    field: FieldModel;
    onChange: (field: FieldModel, value: string) => void;
    datamodStatus: DatamodStatusModel;
    disabled?: boolean;
    valueOverwrite?: string;
}

const DatamodInput: React.FC<Props> = ({ field, onChange, disabled, valueOverwrite, datamodStatus }) => {
    let itemElement = null;
    if (typeof field.type === 'object' && field.type !== null) {
        itemElement = (
            <SelectControl
                field={field}
                onChange={onChange}
                isLoading={field.isLoading || disabled}
                valueOverwrite={valueOverwrite}
            />
        );
    } else {
        switch (field.type) {
            case "date":
            case "datetime":
                itemElement = (
                    <DateControl
                        field={field}
                        onChange={onChange}
                        isLoading={field.isLoading || disabled}
                        valueOverwrite={valueOverwrite}
                    />
                );
                break;
            case "price":
                itemElement = (
                    <PriceControl
                        field={field}
                        onChange={onChange}
                        isLoading={field.isLoading || disabled}
                        valueOverwrite={valueOverwrite}
                    />
                );
                break;
            case "number":
            case "email":
            case "tel":
            case "text":
                itemElement = (
                    <TextControl
                        field={field}
                        onChange={onChange}
                        isLoading={field.isLoading || disabled}
                        valueOverwrite={valueOverwrite}
                    />
                );
                break;
        }
    }

    return (
        <div className={"datamod-input" + (field.is_editable ? " editable" : "") + (field.type === "hidden" ? " hidden" : "")}>
            {field.is_editable &&
                <i className="las la-pencil-alt editable-icon"/>
            }
            <IonItemDivider>
                <IonLabel slot="start">
                    {field.label??""}
                    {field.required &&
                        <IonText color="warning">
                            <small>
                                {" "}
                                Kötelező mező
                            </small>
                        </IonText>
                    }
                </IonLabel>
                {field.isLoading ?
                    <IonLabel slot="end">
                        <IonSpinner name="crescent" />
                    </IonLabel>
                :
                    <>
                        {field.has_datamod_in_progress && 
                            <>
                                {datamodStatus?.is_ready == 1 ?
                                    <IonBadge slot="end" color="success">
                                        Feldolgozásra vár
                                    </IonBadge>
                                :
                                    <IonBadge slot="end" color="warning">
                                        Beküldésre vár
                                    </IonBadge>
                                }
                            </>
                        }
                    </>
                }
            </IonItemDivider>
            {itemElement??""}
            {field.has_datamod_in_progress &&
                <>
                    {field.model_id ?
                        <div className="original-value">
                            <IonText color="warning">
                                <p>
                                    Eredeti érték: {field.current_value_in_db ?? "Üres érték"}
                                </p> 
                            </IonText>
                            {field.is_editable &&
                                <IonButton
                                    color="warning"
                                    size="small"
                                    onClick={() => {
                                        onChange(field, field.current_value_in_db);
                                    }}
                                    disabled={field.isLoading || disabled}
                                >
                                    Visszaállítás
                                </IonButton>
                            }
                        </div>
                    :
                        <IonText color="warning">
                            <p style={{ padding: "5px 20px 6px", fontSize: 13 }}>
                                Új elem, nincs eredeti érték
                            </p>
                        </IonText>
                    }
                </> 
            }
        </div>
    );
};

export default DatamodInput;
