import { HttpClient } from "../helpers/HttpClient";
import { FilterQueryObject } from "../helpers/FilterHelper";

export const postMessageArchiveSingle = async (id: string|number) => {
    return await HttpClient({
        url: `/v1/messages/archive-single`,
        method: "post",
        data: {
            id,
        }
    });
};

export const postMessagesArchive = async () => {
    return await HttpClient({
        url: `/v1/messages/archive`,
        method: "get",
    });
};

export const getMessageFilters = async () => {
    return await HttpClient({
        url: `/v1/messages/filters`,
        method: "get",
    });
};

export const fetchMessages = async (queryObject: FilterQueryObject) => {
    return await HttpClient({
        url: `/v1/messages/view`,
        method: "post",
        data: queryObject
    });
};
