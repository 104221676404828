import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { cardOutline, checkmarkCircleOutline, copyOutline, documentTextOutline } from "ionicons/icons";
import { IonAlert, IonButton, IonCheckbox, IonCol, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonRow, IonText, useIonToast, useIonViewDidEnter, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { Clipboard } from "@capacitor/clipboard";

import PageWrapper from "../layouts/PageWrapper";
import { useAuth } from "../../context/Auth";
import { getFileUrl } from "../../helpers/DevHelper";
import { fetchInvoicePreview, postOtpsp } from "../../services/InvoiceServices";
import { getInvoicePrice } from "../../helpers/GlobalHelpers";
import { sendBarionPixel } from "../../helpers/barion/PixelHelper";

import barionLogo from "../../assets/images/barion-card-strip-intl.svg";
import NumberFormat from "react-number-format";

interface Documents {
  id: number;
  type: string;
  createdAt: string;
  related: any;
  transactions?: any;
  fileId?: string | null;
  invoice_data?: any;
  invoice_items_data?: any;
  barion_transactions?: any;
}

const dateFormat = require("dateformat");

const InvoicesPaymentPage: React.FC = () => {
  const [present] = useIonToast();
  const { checkAxiosError } = useAuth();
  const { id }: { id: string } = useParams();

  const [invoice, setInvoice] = useState<Documents>(null);
  const [simpleChecked, setSimpleChecked] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<string | null>(null);
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

  const getStatus = () => {
    if (invoice.related.U_CPH_PaymentCreated !== undefined && invoice.related.U_CPH_PaymentCreated !== null && invoice.related.U_CPH_PaymentCreated === "Y") {
      return "paid";
    }

    if (invoice.barion_transactions !== undefined && invoice.barion_transactions.length > 0) {
      const succeeded = invoice.barion_transactions.filter((t: any) => t.status === "Succeeded").length > 0;
      if (succeeded) {
        return "paid";
      }
    }

    if (invoice.transactions !== undefined && invoice.transactions.length > 0 && invoice.transactions[0] !== undefined && invoice.transactions[0].status === "FINISHED") {
      return "paid";
    } else {
      var d1 = new Date();
      var d2 = new Date(invoice.related.DocDueDate);
      if (d1 > d2) {
        return "late";
      } else {
        return "waiting";
      }
    }
  };

  const getStatusText = () => {
    switch (getStatus()) {
      case "waiting":
        return "Fizetésre vár";
      case "paid":
        return "Fizetve";
      case "late":
        return "Késés";
    }
  };

  const getPaymentLink = (docId: number) => {
    setPaymentLoading(true);
    postOtpsp(docId, window.location.origin)
      .then((res: any) => {
        setPaymentLoading(false);

        sendBarionPixel("initiatePurchase", {
          contents: [
            {
              contentType: "Product",
              currency: "HUF",
              id: id.toString(),
              name: invoice.related.invoice_number,
              quantity: 1,
              totalItemPrice: getInvoicePrice(invoice.related),
              unit: "pcs.",
              unitPrice: getInvoicePrice(invoice.related),
            },
          ],
          currency: "HUF",
          revenue: getInvoicePrice(invoice.related),
          step: "1",
        });

        var data = res.data;
        if (data.payment_link !== undefined && data.payment_link !== "") {
          if (Capacitor.isNativePlatform()) {
            openInAppBrowser(data.payment_link);
          } else {
            window.open(data.payment_link, "_self");
          }
        } else if (data.response !== undefined && data.response.paymentUrl !== "") {
          if (Capacitor.isNativePlatform()) {
            openInAppBrowser(data.response.paymentUrl);
          } else {
            window.open(data.response.paymentUrl, "_self");
          }
        } else {
          if (data.message !== undefined) {
            setShowAlert(data.message);
          } else {
            setShowAlert("Hiba történt a befizetés közben.");
          }
        }
      })
      .catch((err: any) => {
        setPaymentLoading(false);

        if (err.response.data.messages !== undefined) {
          if (err.response.data.codes !== undefined) {
            setShowAlert(err.response.data.messages[0] + "<br/><br/>Hibakód: " + err.response.data.codes[0]);
          } else {
            setShowAlert(err.response.data.messages[0]);
          }
        } else {
          setShowAlert("Hiba történt a befizetés közben.");
        }

        checkAxiosError(err);
      });
  };

  const openInAppBrowser = async (link: string) => {
    Browser.removeAllListeners();
    await Browser.open({
      url: link,
      toolbarColor: "#00adee",
    });
  };

  useIonViewWillEnter(() => {
    setSimpleChecked(false);
  });

  useIonViewWillLeave(() => {
    Browser.close();
  });

  useEffect(() => {
    setInvoice(null);
    fetchInvoicePreview(id)
      .then((res) => {
        if (res.data.data) {
          setInvoice({
            id: res.data.data.id,
            type: res.data.data.related_type,
            createdAt: res.data.data.created_at,
            related: res.data.data.related,
            transactions: res.data.data.transactions,
            fileId: res.data.data.file?.access_hash,
            invoice_data: res.data.data.invoice_data,
            invoice_items_data: res.data.data.invoice_items_data,
            barion_transactions: res.data.data.barion_transactions,
          });
        }
      })
      .catch((err) => {
        checkAxiosError(err);
      });
  }, [id]);

  useEffect(() => {
    if (invoice) {
      sendBarionPixel("clickProductDetail", {
        contentType: "Product",
        currency: "HUF",
        id: id.toString(),
        name: invoice.related.invoice_number,
        unit: "pcs.",
        unitPrice: getInvoicePrice(invoice.related),
        category: "",
      });
      sendBarionPixel("clickProduct", {
        contentType: "Product",
        currency: "HUF",
        id: id.toString(),
        name: invoice.related.invoice_number,
        quantity: 1,
        unit: "pcs.",
        unitPrice: getInvoicePrice(invoice.related),
        category: "",
      });
      sendBarionPixel("contentView", {
        contentType: "Product",
        currency: "HUF",
        id: id.toString(),
        name: invoice.related.invoice_number,
        quantity: 1,
        unit: "pcs.",
        unitPrice: getInvoicePrice(invoice.related),
        category: "",
        imageUrl: "",
      });
    }
  }, [invoice]);

  const writeToClipboard = async (s: string) => {
    await Clipboard.write({
      string: s,
    });
  };

  return (
    <PageWrapper>
      <div className="hasButton">
        <h1 className="pageTitle">Számla</h1>
        <a rel="noopener noreferrer" href="https://www.barion.com/hu/" target="_blank" id="barionLogo">
          <img height="30" src={barionLogo} title="Barion" alt="Barion" />
        </a>
      </div>

      {invoice !== null ? (
        <>
          <IonRow>
            {invoice.related.GroupNum !== "2" && (
              <>
                {getStatus() !== "paid" ? (
                  <>
                    <IonCol size="12">
                      <IonList>
                        <IonItem lines="none" className={"checkbox-input backgroundTransparent"}>
                          <IonCheckbox checked={simpleChecked} onIonChange={(e) => setSimpleChecked(e.detail.checked)} />
                          <IonLabel class="ion-text-wrap">
                            Tudomásul veszem, hogy a Patent Távfelügyelet Kft. (9024, Győr, Mécs László u. 7) adatkezelő által a mypatent.hu felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek a Barion Payment Zrt.
                            , mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: Számlaszám, név, e-mail, cím, számlázási cím, szállítási cím. Az adatfeldolgozó által végzett adatfeldolgozási tevékenység
                            jellege és célja a Barion Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:{" "}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.barion.com/hu/adatvedelmi-tajekoztato/">
                              https://www.barion.com/hu/adatvedelmi-tajekoztato/
                            </a>
                          </IonLabel>
                        </IonItem>
                      </IonList>
                    </IonCol>
                    <IonCol size="12" sizeMd="6">
                      <IonButton
                        expand="block"
                        color="warning"
                        onClick={() => {
                          if (!simpleChecked) {
                            setShowAlert("Kérjük fogadja el az általános felhasználási feltételeket a fizetés gomb felett!");
                          } else {
                            getPaymentLink(invoice.id);
                          }
                        }}
                      >
                        <IonIcon icon={cardOutline} slot="start" />
                        Fizetés
                      </IonButton>
                    </IonCol>
                  </>
                ) : (
                  <IonCol size="12">
                    <IonText color="success">
                      <h1 className="ion-text-center alertIcon">
                        <IonIcon icon={checkmarkCircleOutline} />
                      </h1>
                      <h2 className="ion-text-center alertText">A számla befizetve.</h2>
                    </IonText>
                  </IonCol>
                )}
              </>
            )}
            {invoice.fileId ? (
              <IonCol size="12" sizeMd={getStatus() !== "paid" ? "6" : "12"}>
                <IonButton href={getFileUrl() + "/" + invoice.fileId} target="_blank" color="success" expand="block">
                  <IonIcon icon={documentTextOutline} slot="start" />
                  Megnyitása
                </IonButton>
              </IonCol>
            ) : (
              <IonCol size="12" sizeMd={getStatus() !== "paid" ? "6" : "12"}>
                <IonButton href={getFileUrl() + "/" + invoice.fileId} disabled color="medium" expand="block">
                  A Számlakép maximum 1 héten belül várható...
                </IonButton>
              </IonCol>
            )}
          </IonRow>
          <br />

          <div className="list-between">
            <span>Számlaszám</span>
            <span>{invoice.related.invoice_number}</span>
          </div>
          <IonButton
            size="small"
            color="medium"
            onClick={() => {
              if (Capacitor.isNativePlatform()) {
                writeToClipboard(invoice.related.invoice_number);
                present({
                  duration: 2000,
                  color: "success",
                  message: "Számlaszám kimásolva",
                });
              } else {
                navigator.clipboard.writeText(invoice.related.invoice_number).then(function () {
                  present({
                    duration: 2000,
                    color: "success",
                    message: "Számlaszám kimásolva",
                  });
                });
              }
            }}
            expand="block"
          >
            <IonIcon icon={copyOutline} slot="start" />
            Számlaszám másolása
          </IonButton>
          <div className="list-between">
            <span>Fizetési határidő</span>
            <span>{invoice.related.DocDueDate ? dateFormat(invoice.related.DocDueDate, "yyyy.mm.dd.") : " - "}</span>
          </div>
          <div className="list-between">
            <span>Bizonylat dátum</span>
            <span>{invoice.related.TaxDate ? dateFormat(invoice.related.TaxDate, "yyyy.mm.dd.") : " - "}</span>
          </div>
          <div className="list-between">
            <span>Partner azonosító</span>
            <span>{invoice.related.CardCode ?? " - "}</span>
          </div>
          {invoice.related.CardCode && (
            <IonButton
              size="small"
              color="medium"
              onClick={() => {
                if (Capacitor.isNativePlatform()) {
                  writeToClipboard(invoice.related.CardCoder);
                  present({
                    duration: 2000,
                    color: "success",
                    message: "Partner azonosító kimásolva",
                  });
                } else {
                  navigator.clipboard.writeText(invoice.related.CardCode).then(function () {
                    present({
                      duration: 2000,
                      color: "success",
                      message: "Partner azonosító kimásolva",
                    });
                  });
                }
              }}
              expand="block"
            >
              <IonIcon icon={copyOutline} slot="start" />
              Partner azonosító másolása
            </IonButton>
          )}
          <div className="list-between">
            <span>Megjegyzés</span>
            <span>{invoice.related.Comments ?? " - "}</span>
          </div>
          <div className="list-between">
            <span>Státusz</span>
            <span>{getStatusText()}</span>
          </div>
          <div className="list-between">
            <span>Bankszámlaszám, ahová a befizetést küldeni kell</span>
            <span>{invoice.related.U_HousBnkAct ?? " - "}</span>
          </div>

          <div className="list-between price">
            <span>Fizetendő</span>
            <span>
              <NumberFormat value={invoice.related.calculated_total} displayType={"text"} thousandSeparator={" "} suffix={" Ft"} decimalScale={0} />
            </span>
          </div>
          <br />

          {/*<div className="list-between">
                        <span>Partner azonosító</span>
                        <span>{invoice.related.CardCode??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Számlázási cím</span>
                        <span>{invoice.related.Address??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Bizonylat dátum</span>
                        <span>{invoice.related.TaxDate ? dateFormat(invoice.related.TaxDate, 'yyyy.mm.dd HH:MM') : ' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Fizetési feltétel</span>
                        <span>{invoice.related.GroupNum??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Kirendeltség azonosító</span>
                        <span>{invoice.related.BPLId??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Számla típusa</span>
                        <span>{invoice.related.U_Tipus??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Fizetve</span>
                        <span>{invoice.related.U_CPH_PaymentCreated??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Postázási cím</span>
                        <span>{invoice.related.U_PostazasiCim??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Csekkazonosító</span>
                        <span>{invoice.related.U_CheckID??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Sztornózott</span>
                        <span>{invoice.related.CANCELED??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>ÁFA végösszeg</span>
                        <span>{invoice.related.VatSum??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Bankszámlaszám</span>
                        <span>{invoice.related.U_HousBnkAct??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Számla bizonylat típusa</span>
                        <span>{invoice.related.BizonylatTipus??' - '}</span>
                    </div>*/}

          {invoice.invoice_data !== undefined && (
            <>
              {invoice.invoice_data !== null && invoice.invoice_data.length > 0 && (
                <>
                  <h3 className="smallTitle">Részletek</h3>
                  {invoice.invoice_data.map((item: any, key: any) => (
                    <div className="list-between" data-content={JSON.stringify(item)} key={key}>
                      <span>{item.label ?? ""}</span>
                      <span>{item.value ?? ""}</span>
                    </div>
                  ))}
                  <br />
                </>
              )}
            </>
          )}

          {/*invoice.invoice_items_data !== undefined &&
                        <>
                            {(invoice.invoice_items_data !== null && invoice.invoice_items_data.length > 0) &&
                                <>
                                    <h3 className="smallTitle">Számla elemei</h3>
                                    {invoice.invoice_items_data.map((items: any, key: any) => (
                                        <div key={key}>
                                            {items.map((item: any, _key: any) => (
                                                <div className="list-between" key={_key}>
                                                    <span>{item.label??''}</span>
                                                    <span>{item.value??''}</span>
                                                </div>
                                            ))}
                                            <br/>
                                        </div>
                                    ))}
                                    <br/>
                                </>
                            }
                        </>
                    */}
        </>
      ) : (
        <IonLoading isOpen={Boolean(invoice === null)} message={"Számla betöltése..."} />
      )}

      {paymentLoading && <IonLoading isOpen={paymentLoading} message={"Kérem várjon..."} />}

      <IonAlert
        isOpen={Boolean(showAlert !== null)}
        onDidDismiss={() => setShowAlert(null)}
        header={"Információ"}
        message={showAlert}
        buttons={[
          {
            text: "Rendben",
            role: "cancel",
          },
        ]}
      />
    </PageWrapper>
  );
};

export default InvoicesPaymentPage;
