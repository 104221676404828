import React from 'react'
import { IonPage, IonContent, IonGrid, IonButton } from '@ionic/react';
import MyPatentHeader from '../components/MyPatentHeader';

import logo from '../assets/images/logo.svg';
import './LoginPage.scss';

const NotFoundPage: React.FC = () => {
    return (
        <IonPage>
            <IonContent id="loginPage">
                <MyPatentHeader isLogoHidden={false} />

                <IonContent className="backgroundTransparent">
                    <IonGrid className="ion-padding" id="ionGridInner" fixed>
                        <div className="page-content">
                            <img src={logo} alt="myPatent Logo" className="desktopLogo" />

                            <h1 className="ion-text-center">Az oldal nem található.</h1>
                            <br/>
                            <IonButton routerLink="/" routerDirection="root">Vissza a főoldalra</IonButton>
                        </div>
                    </IonGrid>
                </IonContent>
            </IonContent>
        </IonPage>
    );
};

export default NotFoundPage;
