import React, { useState, useEffect } from "react";

import {
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonReorder,
    IonReorderGroup,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonToast,
} from "@ionic/react";
import { addCircle, filterCircle } from "ionicons/icons";
import { ItemReorderEventDetail } from "@ionic/core";

import {
    BoxModel,
    DatamodStatusModel,
    FieldModel,
    NewValue,
    SubBoxModel,
} from "../../models/GlobalModels";
import DatamodInput from "./controls/DatamodInput";

interface Props {
    box: BoxModel;
    onChange: (field: FieldModel, value: string) => void;
    handleDelete: (
        model_type: string,
        model_id: number,
        is_delete: boolean
    ) => void;
    handleNew: (model_type: string, fields: NewValue[]) => void;
    datamodStatus: DatamodStatusModel;
    handleNewDelete: (datamod_new_item_id: number) => void;
    onChangeNew: (field: FieldModel, value: string, item: SubBoxModel) => void;
}

const DatamodSubbox: React.FC<Props> = ({
    box,
    onChange,
    handleDelete,
    handleNew,
    datamodStatus,
    handleNewDelete,
    onChangeNew,
}) => {
    const [presentAlert] = useIonAlert();
    const [presentToast] = useIonToast();

    const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);
    const [openModalNew, setOpenModalNew] = useState<boolean>(false);
    const [newValues, setNewValues] = useState<NewValue[]>([]);
    const [openModalSort, setOpenModalSort] = useState<boolean>(false);
    const [subBoxSort, setSubBoxSort] = useState<SubBoxModel[]>([]);
    const [tempSubBoxSort, setTempSubBoxSort] = useState<SubBoxModel[]>([]);

    const onNewChange = (field: FieldModel, value: string) => {
        if (openModalNew) {
            const filteredIndex = newValues.findIndex(
                (e) =>
                    e.field.model_type === field.model_type &&
                    e.field.model_id === field.model_id &&
                    e.field.key === field.key
            );
            if (filteredIndex === -1) {
                setNewValues((prevState: NewValue[]) => [
                    ...prevState,
                    {
                        field,
                        value,
                    },
                ]);
            } else {
                if (value !== "") {
                    const newState = [...newValues];
                    newState[filteredIndex].value = value;
                    setNewValues(newState);
                } else {
                    setNewValues(
                        newValues.filter(
                            (_: any, i: number) => i !== filteredIndex
                        )
                    );
                }
            }
        }
    };

    const handleClose = () => {
        setOpenModalIndex(null);
    };

    const getValue = (fields: FieldModel[], key: string) => {
        const _temp_field = fields.find(
            (field: FieldModel) => field.key === key
        );
        if (_temp_field) {
            return _temp_field.value;
        } else {
            return "";
        }
    };

    const getAddressType = (fields: FieldModel[]) => {
        const U_Tipus = getValue(fields, "U_Tipus");
        const AdresType = getValue(fields, "AdresType");

        let _temp_type = "";
        if (U_Tipus && AdresType) {
            if (AdresType == "B") {
                _temp_type = "SZAM";
            } else {
                _temp_type = U_Tipus;
            }
        }

        switch (_temp_type) {
            case "LEV":
                return "Levelezési";
            case "SZAM":
                return "Számlázási";
            case "BIZ":
            case "EGY":
            case "TAV":
            default:
                return "Objektum";
        }
    };

    const getName = (subbox: SubBoxModel, index: number) => {
        if (subbox.model_id === undefined) {
            return (
                <>
                    {index + 1}.
                    {" "}
                    {subbox.name}
                </>
            );
        }

        switch (subbox.model_type) {
            case "App\\Models\\BaseDataCustomerAddress":
                return (
                    <>
                        {index + 1}.
                        {" "}
                        {getAddressType(subbox.fields)}
                        {" - "}
                        {getValue(subbox.fields, "ZipCode")}
                        {", "}
                        {getValue(subbox.fields, "City")}
                        {", "}
                        {getValue(subbox.fields, "Street")}{" "}
                        {getValue(subbox.fields, "StreetNo")}
                    </>
                );
            case "App\\Models\\DocumentCustomerContractNotifiable":
                return (
                    <>
                        {index + 1}.
                        {" "}
                        {[
                            getValue(subbox.fields, "U_Name"),
                            getValue(subbox.fields, "U_E_mail"),
                            getValue(subbox.fields, "U_Telfon"),
                        ]
                            .filter(Boolean)
                            .join(", ")}
                    </>
                );
            default:
                return (
                    <>
                        {index + 1}.
                        {" "}
                        {subbox.name}
                    </>
                );
        }
    };

    useEffect(() => {
        if (openModalSort && box && box.subboxes.length > 0) {
            // setSubBoxSort(box.subboxes.filter((subBox: SubBoxModel) => subBox.type !== "datamod_new" && subBox.is_marked_for_delete === false));
            setSubBoxSort(box.subboxes.filter((subBox: SubBoxModel) => subBox.is_marked_for_delete === false));
        }
    }, [openModalSort]);

    useEffect(() => {
        setTempSubBoxSort(subBoxSort);
    }, [subBoxSort]);

    useEffect(() => {
        if (openModalNew && box && box.subboxes.length > 0) {
            let temp_new_values: NewValue[] = [];
            box.new_fields.forEach((field: FieldModel) => {
                if (field.model_type === "App\\Models\\DocumentCustomerContractNotifiable" && field.key === "U_Prioritas") {
                    const prio =
                        90 -
                        box.subboxes.filter(
                            (subBox: SubBoxModel) =>
                                subBox.is_marked_for_delete === false
                        ).length *
                            10;
                    temp_new_values.push({
                        field: field,
                        value: prio.toString(),
                    });
                } else {
                    temp_new_values.push({
                        field: field,
                        value: "",
                    });
                }
            });
            setNewValues(temp_new_values);
        }
    }, [openModalNew]);

    return (
        <>
            {box.subboxes.map((subbox: SubBoxModel, index: number) => (
                <IonItem
                    lines="none"
                    onClick={() => setOpenModalIndex(index)}
                    detail={true}
                    key={index}
                    button
                    disabled={subbox.fields.filter((field: FieldModel) => field.isLoading).length > 0}
                >
                    {subbox.fields.filter((field: FieldModel) => field.isLoading).length > 0 &&
                        <IonSpinner name="crescent" slot="start" />
                    }
                    <IonLabel>
                        {getName(subbox, index)}
                    </IonLabel>
                    {subbox?.is_marked_for_delete === true && (
                        <>
                            {datamodStatus?.is_ready == 1 ? (
                                <IonBadge slot="end" color="success">
                                    Feldolgozásra vár
                                </IonBadge>
                            ) : (
                                <IonBadge slot="end" color="danger">
                                    Törölve, beküldésre vár
                                </IonBadge>
                            )}
                        </>
                    )}
                    {subbox.type === "datamod_new" ? (
                        <>
                            {datamodStatus?.is_ready == 1 ? (
                                <IonBadge slot="end" color="success">
                                    Feldolgozásra vár
                                </IonBadge>
                            ) : (
                                <IonBadge slot="end" color="success">
                                    Új, beküldésre vár
                                </IonBadge>
                            )}
                        </>
                    ) : (
                        <>
                            {subbox?.fields.filter((e: FieldModel) => e.has_datamod_in_progress === true).length > 0 && (
                                <>
                                    {datamodStatus?.is_ready == 1 ? (
                                        <IonBadge slot="end" color="success">
                                            Feldolgozásra vár
                                        </IonBadge>
                                    ) : (
                                        <IonBadge slot="end" color="warning">
                                            Beküldésre vár
                                        </IonBadge>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </IonItem>
            ))}

            {box.name === "Értesítendők" && box.subboxes.length > 0 && (
                <IonItem
                    lines="none"
                    onClick={() => setOpenModalSort(true)}
                    disabled={datamodStatus != null && datamodStatus?.status != 0}
                    detail={false}
                    button
                >
                    <IonIcon slot="start" icon={filterCircle} color="primary" />
                    <IonLabel>
                        <IonText color="primary">Sorrend beállítása</IonText>
                    </IonLabel>
                </IonItem>
            )}

            {box.can_add_new && (
                <IonItem
                    lines="none"
                    onClick={() => setOpenModalNew(true)}
                    detail={false}
                    disabled={
                        datamodStatus != null && datamodStatus?.status != 0
                    }
                    button
                >
                    <IonIcon slot="start" icon={addCircle} color="primary" />
                    <IonLabel>
                        <IonText color="primary">Új hozzáadása</IonText>
                    </IonLabel>
                </IonItem>
            )}

            <IonModal
                backdropDismiss={false}
                keyboardClose={false}
                swipeToClose={false}
                isOpen={openModalIndex !== null}
            >
                {openModalIndex !== null && (
                    <>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>{box.name ?? ""}</IonTitle>
                            </IonToolbar>
                            {box.subboxes[openModalIndex]
                                .is_marked_for_delete && (
                                <IonToolbar
                                    color="danger"
                                    className="delete-toolbar"
                                >
                                    <IonTitle>Törölve, beküldésre vár</IonTitle>
                                </IonToolbar>
                            )}
                            {box.subboxes[openModalIndex].type === "datamod_new" && (
                                <IonToolbar
                                    color="success"
                                    className="delete-toolbar"
                                >
                                    <IonTitle>Új, beküldésre vár</IonTitle>
                                </IonToolbar>
                            )}
                        </IonHeader>
                        <IonContent>
                            {box.subboxes[openModalIndex].fields.map(
                                (field: FieldModel, index: number) => (
                                    <DatamodInput
                                        field={field}
                                        key={index}
                                        onChange={(
                                            field: FieldModel,
                                            value: string
                                        ) => {
                                            if (
                                                box.subboxes[openModalIndex]
                                                    .datamod_new_item_id
                                            ) {
                                                onChangeNew(
                                                    field,
                                                    value,
                                                    box.subboxes[openModalIndex]
                                                );
                                            } else {
                                                onChange(field, value);
                                            }
                                        }}
                                        disabled={
                                            box.subboxes[openModalIndex].is_marked_for_delete ||
                                            box.subboxes[openModalIndex].type === "datamod_new"
                                        }
                                        datamodStatus={datamodStatus}
                                    />
                                )
                            )}
                            <div className="button-grid">
                                {box.can_delete ? (
                                    <>
                                        {box.subboxes[openModalIndex]
                                            .is_marked_for_delete === false ||
                                        box.subboxes[openModalIndex].type ===
                                            "datamod_new" ? (
                                            <>
                                                {box.subboxes[openModalIndex]
                                                    .type === "datamod_new" ? (
                                                    <IonButton
                                                        onClick={() => {
                                                            presentAlert({
                                                                header: "Figyelem",
                                                                message:
                                                                    "Biztosan törölni szeretné?",
                                                                buttons: [
                                                                    "Mégse",
                                                                    {
                                                                        text: "Igen",
                                                                        handler:
                                                                            (
                                                                                d
                                                                            ) => {
                                                                                if (
                                                                                    box
                                                                                        .subboxes[
                                                                                        openModalIndex
                                                                                    ]
                                                                                        .datamod_new_item_id
                                                                                ) {
                                                                                    handleNewDelete(
                                                                                        box
                                                                                            .subboxes[
                                                                                            openModalIndex
                                                                                        ]
                                                                                            .datamod_new_item_id
                                                                                    );
                                                                                }
                                                                                setOpenModalIndex(
                                                                                    null
                                                                                );
                                                                            },
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                        color="danger"
                                                        fill="outline"
                                                        expand="block"
                                                        disabled={
                                                            datamodStatus !=
                                                                null &&
                                                            datamodStatus?.status !=
                                                                0
                                                        }
                                                    >
                                                        Törölni szeretném
                                                    </IonButton>
                                                ) : (
                                                    <IonButton
                                                        onClick={() => {
                                                            presentAlert({
                                                                header: "Figyelem",
                                                                message:
                                                                    "Biztosan törölni szeretné?",
                                                                buttons: [
                                                                    "Mégse",
                                                                    {
                                                                        text: "Igen",
                                                                        role: "destructive",
                                                                        handler:
                                                                            (
                                                                                d
                                                                            ) => {
                                                                                handleDelete(
                                                                                    box
                                                                                        .subboxes[
                                                                                        openModalIndex
                                                                                    ]
                                                                                        .fields[0]
                                                                                        .model_type,
                                                                                    box
                                                                                        .subboxes[
                                                                                        openModalIndex
                                                                                    ]
                                                                                        .fields[0]
                                                                                        .model_id,
                                                                                    true
                                                                                );
                                                                                setOpenModalIndex(
                                                                                    null
                                                                                );
                                                                            },
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                        color="danger"
                                                        fill="outline"
                                                        expand="block"
                                                        disabled={
                                                            datamodStatus !=
                                                                null &&
                                                            datamodStatus?.status !=
                                                                0
                                                        }
                                                    >
                                                        Törölni szeretném
                                                    </IonButton>
                                                )}
                                            </>
                                        ) : (
                                            <IonButton
                                                onClick={() => {
                                                    presentAlert({
                                                        header: "Figyelem",
                                                        message:
                                                            "Biztosan vissza szeretné vonni a törlést?",
                                                        buttons: [
                                                            "Mégse",
                                                            {
                                                                text: "Igen",
                                                                handler: (
                                                                    d
                                                                ) => {
                                                                    handleDelete(
                                                                        box
                                                                            .subboxes[
                                                                            openModalIndex
                                                                        ]
                                                                            .fields[0]
                                                                            .model_type,
                                                                        box
                                                                            .subboxes[
                                                                            openModalIndex
                                                                        ]
                                                                            .fields[0]
                                                                            .model_id,
                                                                        false
                                                                    );
                                                                    setOpenModalIndex(
                                                                        null
                                                                    );
                                                                },
                                                            },
                                                        ],
                                                    });
                                                }}
                                                color="success"
                                                fill="outline"
                                                expand="block"
                                                disabled={
                                                    datamodStatus != null &&
                                                    datamodStatus?.status != 0
                                                }
                                            >
                                                Törlési kérelem visszavonása
                                            </IonButton>
                                        )}
                                    </>
                                ) : (
                                    <div></div>
                                )}
                                <IonButton
                                    onClick={handleClose}
                                    color="primary"
                                    expand="block"
                                >
                                    Rendben
                                </IonButton>
                            </div>
                        </IonContent>
                    </>
                )}
            </IonModal>
            <IonModal
                backdropDismiss={false}
                keyboardClose={false}
                swipeToClose={false}
                isOpen={openModalNew}
            >
                {openModalNew && (
                    <>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>{box.name ?? ""}</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            {box.new_fields.map(
                                (field: FieldModel, index: number) => (
                                    <DatamodInput
                                        field={field}
                                        key={index}
                                        onChange={onNewChange}
                                        valueOverwrite={
                                            newValues.find(
                                                (e) =>
                                                    e.field.model_type ===
                                                        field.model_type &&
                                                    e.field.model_id ===
                                                        field.model_id &&
                                                    e.field.key === field.key
                                            )?.value ?? ""
                                        }
                                        datamodStatus={datamodStatus}
                                    />
                                )
                            )}
                            <div className="button-grid">
                                <IonButton
                                    onClick={() => {
                                        setOpenModalNew(false);
                                    }}
                                    color="primary"
                                    fill="outline"
                                    expand="block"
                                >
                                    Mégse
                                </IonButton>
                                <IonButton
                                    onClick={() => {
                                        let error: boolean = false;
                                        newValues.forEach(
                                            (newValue: NewValue, _: number) => {
                                                if (
                                                    newValue.field.required &&
                                                    newValue.value === ""
                                                ) {
                                                    error = true;
                                                }
                                            }
                                        );

                                        if (error) {
                                            presentToast({
                                                message:
                                                    "Kötelező mező hiányzik!",
                                                duration: 3000,
                                                color: "danger",
                                            });
                                        } else {
                                            handleNew(
                                                box.new_fields[0].model_type,
                                                newValues
                                            );
                                            setNewValues([]);
                                            setOpenModalNew(false);
                                        }
                                    }}
                                    color="primary"
                                    expand="block"
                                >
                                    Rendben
                                </IonButton>
                            </div>
                        </IonContent>
                    </>
                )}
            </IonModal>
            <IonModal
                backdropDismiss={false}
                keyboardClose={false}
                swipeToClose={false}
                isOpen={openModalSort}
            >
                {openModalSort && (
                    <>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>Sorrend beállítása</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <IonReorderGroup
                                disabled={false}
                                onIonItemReorder={(
                                    event: CustomEvent<ItemReorderEventDetail>
                                ) => {
                                    setTempSubBoxSort(
                                        event.detail.complete(subBoxSort)
                                    );
                                }}
                            >
                                {tempSubBoxSort.map(
                                    (subbox: SubBoxModel, index: number) => (
                                        <IonReorder key={index}>
                                            <IonItem>
                                                <IonLabel>
                                                    {getName(subbox, index)}
                                                </IonLabel>
                                            </IonItem>
                                        </IonReorder>
                                    )
                                )}
                            </IonReorderGroup>
                            <div className="button-grid">
                                <IonButton
                                    onClick={() => {
                                        subBoxSort.forEach((subBox) =>
                                            subBox.fields.filter((field) => field.has_datamod_in_progress && field.key === "U_Prioritas")
                                                .forEach((field) => onChange(field, field.current_value_in_db))
                                        );
                                        setOpenModalSort(false);
                                    }}
                                    color="danger"
                                    expand="block"
                                >
                                    Sorrendezés visszavonása
                                </IonButton>
                                <IonButton
                                    onClick={() => {
                                        let i = 90;
                                        tempSubBoxSort.forEach((item: SubBoxModel) => {
                                            const field = item.fields.find((field: FieldModel) => field.key === "U_Prioritas");
                                            if (field) {
                                                if (item.type === "db") {
                                                    onChange(
                                                        field,
                                                        i.toString()
                                                    );
                                                    i -= 10;
                                                } else {
                                                    onChangeNew(
                                                        field,
                                                        i.toString(),
                                                        item
                                                    );
                                                    i -= 10;
                                                }
                                            }
                                        });
                                        setOpenModalSort(false);
                                    }}
                                    color="primary"
                                    expand="block"
                                >
                                    Rendben
                                </IonButton>
                            </div>
                        </IonContent>
                    </>
                )}
            </IonModal>
        </>
    );
};
export default DatamodSubbox;
