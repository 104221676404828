import React, { useState } from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { closeOutline, filterOutline, trash } from "ionicons/icons";

interface Props {
    number: number;
    setFilterDefault: () => void;
    showDeleteFiltersButton?: boolean;
}

const FilterModal: React.FC<Props> = (props) => {
    const { children, number } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className="filterModalToolbar">
                <span>
                    Találat: <b>{number} db</b>
                </span>
                <div>
                    {props.showDeleteFiltersButton === true &&
                        props.setFilterDefault && (
                            <IonButton
                                onClick={props.setFilterDefault}
                                color="danger"
                                size="small"
                            >
                                <IonIcon slot="start" icon={trash} />
                                Keresés törlése
                            </IonButton>
                        )}
                    <IonButton
                        onClick={() => setIsOpen(true)}
                        color="primary"
                        size="small"
                    >
                        <IonIcon slot="start" icon={filterOutline} />
                        Szűrés
                    </IonButton>
                </div>
            </div>

            <IonModal backdropDismiss={false} isOpen={isOpen}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Szűrés</IonTitle>
                        {/*<IonButtons slot="end">
                            <IonButton onClick={setFilterDefault}>
                                Alaphelyzet
                            </IonButton>
                        </IonButtons>*/}
                        <IonButtons slot="end">
                            <IonButton onClick={() => setIsOpen(false)}>
                                Bezár
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    {children}

                    <br />
                    <div className="paddingForButton">
                        <IonButton
                            color="success"
                            expand="block"
                            onClick={() => setIsOpen(false)}
                        >
                            Szűrés
                        </IonButton>
                    </div>
                </IonContent>
            </IonModal>
        </>
    );
};

export default FilterModal;
