import React from "react";
import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonBadge,
    IonSkeletonText,
    IonRouterLink,
} from "@ionic/react";
import { useLocation } from "react-router-dom";
import {
    notificationsOutline,
    documentOutline,
    optionsOutline,
    laptopOutline,
    fileTrayFullOutline,
    homeOutline,
    exitOutline,
    callOutline,
    mailOutline,
    addOutline,
    keyOutline,
    peopleOutline,
    cartOutline,
    documentLockOutline,
    documentTextOutline,
    personRemoveOutline,
} from "ionicons/icons";
import { useAuth } from "../context/Auth";
import { useNotification } from "../context/Notifications";
import { getCurrentVersion } from "../helpers/GlobalHelpers";

import logo from "../assets/images/logo.svg";

import "./Menu.scss";

interface AppPage {
    url: string;
    icon: string;
    title: string;
    direction: "none" | "forward" | "back" | "root";
}

const appPages: AppPage[] = [
    {
        title: "Főoldal",
        url: "/my/dashboard",
        icon: homeOutline,
        direction: "root",
    },
    {
        title: "Értesítések",
        url: "/my/notifications",
        icon: notificationsOutline,
        direction: "forward",
    },
    {
        title: "Dokumentumtár",
        url: "/my/documents",
        icon: documentOutline,
        direction: "forward",
    },
    {
        title: "Adataim",
        url: "/my/profile",
        icon: optionsOutline,
        direction: "forward",
    },
    {
        title: "Szolgáltatások",
        url: "/my/services",
        icon: cartOutline,
        direction: "forward",
    },
    {
        title: "Számláim",
        url: "/my/invoices",
        icon: fileTrayFullOutline,
        direction: "forward",
    },
    {
        title: "Alrendszerek",
        url: "/my/subsystems",
        icon: laptopOutline,
        direction: "forward",
    },
    {
        title: "Jelszóváltoztatás",
        url: "/my/user",
        icon: keyOutline,
        direction: "forward",
    },
    {
        title: "Új hozzárendelés",
        url: "/assign",
        icon: addOutline,
        direction: "root",
    },
];

const Menu: React.FC = () => {
    const { setLogout, userInfo, assigns } = useAuth();
    const location = useLocation();
    const { unreadNotifictaions } = useNotification();
    /*
    const [showVersionAlert, setShowVersionAlert] = useState<boolean>(false);
    const [forceUpdate, setForceUpdate] = useState<boolean>(false);
    */


    return (
        <>
            <IonMenu contentId="main" type="overlay" maxEdgeStart={150}>
                <IonContent>
                    <IonList id="inbox-list">
                        <IonRouterLink routerLink="/my/dashboard" routerDirection="root">
                            <div id="menuLogoHolder">
                                <img src={logo} alt="Logo" />
                            </div>
                        </IonRouterLink>
                        <IonItem lines="none" id="menuProfileHolder">
                            <IonLabel>
                                <h2>
                                    {userInfo ? (
                                        userInfo.name
                                    ) : (
                                        <IonSkeletonText animated />
                                    )}
                                </h2>
                                <p>
                                    {userInfo ? (
                                        userInfo.email
                                    ) : (
                                        <IonSkeletonText
                                            animated
                                            style={{ width: "60%" }}
                                        />
                                    )}
                                </p>
                            </IonLabel>
                        </IonItem>

                        {/*<IonItem lines="none" className="backgroundTransparent" id="menuSelectHolder">
                        <IonLabel>Szerződés</IonLabel>
                        <IonSelect value="PATENT-115585" className="invoiceSelect" okText="Kiválasztás" cancelText="Mégse">
                            <IonSelectOption value="PATENT-115585">PATENT-115585</IonSelectOption>
                        </IonSelect>
                    </IonItem>*/}

                        {appPages.map((appPage, index) => {
                            return (
                                <IonMenuToggle key={index} autoHide={false}>
                                    <IonItem
                                        button={true}
                                        className={
                                            "menu-route " +
                                            (location.pathname === appPage.url
                                                ? "selected"
                                                : "")
                                        }
                                        routerLink={appPage.url}
                                        routerDirection={appPage.direction}
                                        lines="none"
                                        detail={false}
                                    >
                                        <IonIcon
                                            slot="start"
                                            icon={appPage.icon}
                                        />
                                        <IonLabel>{appPage.title}</IonLabel>
                                        {appPage.url === "/my/notifications" &&
                                            unreadNotifictaions > 0 && (
                                                <IonBadge
                                                    color="danger"
                                                    slot="end"
                                                >
                                                    {unreadNotifictaions}
                                                </IonBadge>
                                            )}
                                    </IonItem>
                                </IonMenuToggle>
                            );
                        })}

                        {(assigns !== null && assigns.length > 0 && Array.isArray(assigns[0])) &&
                            <>
                                {(assigns[0].filter((item: any, i: number) => {
                                    return item.isFirst === true;
                                }).length > 0) &&
                                    <>
                                        <IonMenuToggle autoHide={false}>
                                            <IonItem
                                                button={true}
                                                className={
                                                    "menu-route " +
                                                    (location.pathname === "/my/relations"
                                                        ? "selected"
                                                        : "")
                                                }
                                                routerLink="/my/relations"
                                                routerDirection="forward"
                                                lines="none"
                                                detail={false}
                                            >
                                                <IonIcon
                                                    slot="start"
                                                    icon={peopleOutline}
                                                />
                                                <IonLabel>Hozzárendelések</IonLabel>
                                            </IonItem>
                                        </IonMenuToggle>
                                    </>
                                }
                            </>
                        }

                        {/* DEBUG */}
                        {/*<IonMenuToggle autoHide={false}>
                            <IonItem button={true} className="menu-route" onClick={() => addSingleNotification({ uid: Math.random().toString(36).substring(7), title: 'Teszt notification', message: 'Ez egy teszt üzenet amit az app generál magának.', time: new Date(), read: false })} routerDirection="none" lines="none" detail={false}>
                                <IonIcon slot="start" icon={notificationsOutline} />
                                <IonLabel>Új notifikáció</IonLabel>
                                <IonBadge color="success" slot="end">DEV</IonBadge>
                            </IonItem>
                        </IonMenuToggle>*/}
                        <hr className="menuHr" />
                        
                        <IonMenuToggle autoHide={false}>
                            <IonItem
                                button={true}
                                className="menu-route"
                                href="https://securitypatent.hu/mypatent-altalanos-szerzodesi-feltetelek/"
                                target="_blank"
                                lines="none"
                                detail={false}
                            >
                                <IonIcon slot="start" icon={documentTextOutline} />
                                <IonLabel>ÁSZF</IonLabel>
                            </IonItem>
                        </IonMenuToggle>

                        <IonMenuToggle autoHide={false}>
                            <IonItem
                                button={true}
                                className="menu-route"
                                href="https://securitypatent.hu/mypatent-adatvedelmi-tajekoztato/"
                                target="_blank"
                                lines="none"
                                detail={false}
                            >
                                <IonIcon slot="start" icon={documentLockOutline} />
                                <IonLabel>Adatvédelmi tájékoztató</IonLabel>
                            </IonItem>
                        </IonMenuToggle>


                        <hr className="menuHr" />

                        <IonMenuToggle autoHide={false}>
                            <IonItem
                                button={true}
                                className="menu-route"
                                href="tel:+36305060111"
                                target="_blank"
                                lines="none"
                                detail={false}
                            >
                                <IonIcon slot="start" icon={callOutline} />
                                <IonLabel>+36 30 50 60 111</IonLabel>
                            </IonItem>
                        </IonMenuToggle>

                        <IonMenuToggle autoHide={false}>
                            <IonItem
                                button={true}
                                className="menu-route"
                                href="mailto:hello@mypatent.hu"
                                target="_blank"
                                lines="none"
                                detail={false}
                            >
                                <IonIcon slot="start" icon={mailOutline} />
                                <IonLabel>hello@mypatent.hu</IonLabel>
                            </IonItem>
                        </IonMenuToggle>
                        
                        <IonMenuToggle autoHide={false}>
                            <IonItem
                                button={true}
                                className={
                                    "menu-route " +
                                    (location.pathname === "/my/cancel"
                                        ? "selected"
                                        : "")
                                }
                                routerLink="/my/cancel"
                                routerDirection="forward"
                                lines="none"
                                detail={false}
                            >
                                <IonIcon
                                    slot="start"
                                    icon={personRemoveOutline}
                                />
                                <IonLabel>Regisztráció visszavonása</IonLabel>
                            </IonItem>
                        </IonMenuToggle>

                        <IonMenuToggle autoHide={false}>
                            <IonItem
                                button={true}
                                className="menu-route"
                                onClick={() => setLogout()}
                                routerDirection="none"
                                lines="none"
                                detail={false}
                            >
                                <IonIcon slot="start" icon={exitOutline} />
                                <IonLabel>Kijelentkezés</IonLabel>
                            </IonItem>
                        </IonMenuToggle>

                        <IonItem
                            button={false}
                            className="version-info"
                            lines="none"
                            detail={false}
                        >
                            <p>Minden jog fenntartva!</p>
                            <p>{getCurrentVersion()}</p>
                        </IonItem>
                    </IonList>
                </IonContent>
            </IonMenu>

            {/*forceUpdate
            ?
                <IonAlert
                    isOpen={showVersionAlert}
                    backdropDismiss={false}
                    keyboardClose={false}
                    header={"Figyelmeztetés"}
                    message={"Az Ön verziója elavult, kérem frissítse az alkalmazást!"}
                    buttons={[
                        {
                            text: 'Frissítés',
                            handler: () => {
                                handleUpdateButton();
                            }
                        }
                    ]}
                />
            :
                <IonAlert
                    isOpen={showVersionAlert}
                    header={"Figyelmeztetés"}
                    message={"Az Ön verziója elavult, kérem frissítse az alkalmazást!"}
                    buttons={["Rendben"]}
                />
            */}
        </>
    );
};

export default Menu;
