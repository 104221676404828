import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

import {
    useIonLoading,
    useIonViewWillEnter,
    useIonViewDidEnter,
    IonItem,
    IonSelect,
    IonSelectOption,
    useIonAlert,
    useIonToast,
    IonButton,
} from "@ionic/react";

import PageWrapper from "../layouts/PageWrapper";
import { useAuth } from "../../context/Auth";
import {
    fetchDatamod,
    fetchDatamodFilters,
    getDatamodStatus,
    postDatamodAllDelete,
    postDatamodDelete,
    postDatamodNew,
    postDatamodNewChange,
    postDatamodNewDelete,
    postDatamodReady,
    postDatamodStore,
} from "../../services/DatamodNewServices";
import {
    BoxModel,
    DatamodStatusModel,
    FieldModel,
    NewValue,
    SubBoxModel,
} from "../../models/GlobalModels";
import DatamodBox from "../../components/datamod/DatamodBox";

import "./DatamodPage.scss";
import LocalStorage from "../../helpers/LocalStorage";

interface FilterProps {
    type: string;
    base_data_id: number;
    document_id: number | null;
    label: string;
}

const DatamodNewPage: React.FC = () => {
    const { checkAxiosError } = useAuth();
    const [presentLoading, dismissLoading] = useIonLoading();
    const [presentAlert] = useIonAlert();
    const [presentToast] = useIonToast();

    const [filters, setFilters] = useState<FilterProps[]>([]);
    const [selectedFilterIndex, setSelectedFilterIndex] = useState<number | null>(null);
    const [boxes, setBoxes] = useState<BoxModel[]>([]);
    const [openBoxIndex, setOpenBoxIndex] = useState<number | null>(null);
    const [datamodStatus, setDatamodStatus] = useState<DatamodStatusModel | null>(null);

    const [queue, setQueue] = useState<Array<{field: FieldModel, value: string, uuid: string}>>([]);
    const [queueRunning, setQueueRunning] = useState<string | null>(null);

    const getFilters = () => {
        presentLoading("Kérem várjon...");
        fetchDatamodFilters()
            .then((res: any) => {
                dismissLoading();
                const data = res.data.data;
                if (data && data.length > 0) {
                    let _tempFilters: FilterProps[] = [];
                    data.forEach((_fil: any) => {
                        _tempFilters.push({
                            type: _fil.type,
                            base_data_id: _fil.base_data_id,
                            document_id: _fil.document_id,
                            label: _fil.label,
                        });
                    });
                    setFilters(_tempFilters);

                    let _temp_selectedFilterIndex: any = LocalStorage.get(
                        "selectedFilterIndex"
                    );
                    _temp_selectedFilterIndex = parseInt(
                        _temp_selectedFilterIndex
                    );
                    if (
                        _temp_selectedFilterIndex &&
                        _tempFilters[_temp_selectedFilterIndex] !== undefined
                    ) {
                        setSelectedFilterIndex(_temp_selectedFilterIndex);
                    } else {
                        setSelectedFilterIndex(0);
                    }
                }
            })
            .catch((err: any) => {
                dismissLoading();
                checkAxiosError(err);
            });
    };

    const getBoxes = (withLoader: boolean = true) => {
        if (selectedFilterIndex === null) {
            return;
        }

        if (withLoader) {
            presentLoading("Kérem várjon...");
        }
        fetchDatamod(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id
        )
            .then((res: any) => {
                if (res.data.data) {
                    setBoxes(res.data.data);
                }
                setDatamodStatus(res.data.datamod ?? null);
                if (withLoader) {
                    dismissLoading();
                }
            })
            .catch((err: any) => {
                if (withLoader) {
                    dismissLoading();
                }
                if (err.message === "REQ_CANCEL") {
                    return;
                }
                checkAxiosError(err);
            });
    };

    const handleChange = (field: FieldModel, value: string) => {
        if (field.required && value === "") {
            presentToast({
                message: "Kötelező mező: " + field.label,
                duration: 3000,
                color: "danger",
            });
            return;
        }

        let _boxes = [...Object.values(boxes)];
        _boxes.map((box: BoxModel, boxIndex: number) => {
            if (box.type === "simple") {
                box.fields.map((_field: FieldModel, fieldIndex: number) => {
                    if (
                        _field.model_id === field.model_id &&
                        _field.model_type === field.model_type &&
                        _field.key === field.key
                    ) {
                        _boxes[boxIndex].fields[fieldIndex].value = value;
                        _boxes[boxIndex].fields[fieldIndex].isLoading = true;
                    }
                });
            } else if (box.type === "subboxes") {
                box.subboxes.map((_subbox: SubBoxModel, subboxIndex: number) => {
                    _subbox.fields.map((_field: FieldModel, fieldIndex: number) => {
                        if (
                            _field.model_id === field.model_id &&
                            _field.model_type === field.model_type &&
                            _field.key === field.key
                        ) {
                            _boxes[boxIndex].subboxes[subboxIndex].fields[fieldIndex].value = value;
                            _boxes[boxIndex].subboxes[subboxIndex].fields[fieldIndex].isLoading = true;
                        }
                    });
                });
            }
        });
        setBoxes(_boxes);

        queueChange(field, value);
    };

    const queueChange = (field: FieldModel, value: string) => {
        setQueue((prevState: any) => [
            ...prevState,
            {
                field: field,
                value: value,
                uuid: uuidv4(),
            },
        ]);
    }

    const runQueue = (field: FieldModel, value: string, uuid: string) => {
        console.log("Running queue", { field, value, uuid });
        setQueueRunning(uuid);
        postDatamodStore(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            field.model_type,
            field.model_id,
            field.key,
            value
        )
            .then((res: any) => {
                if (field.model_type === "App\\Models\\DocumentCustomerContractNotifiable" && field.key === "U_Prioritas") {
                    getBoxes(false);
                } else {
                    if (res.data.data) {
                        let _boxes = [...Object.values(boxes)];
                        _boxes.map((box: BoxModel, boxIndex: number) => {
                            if (box.type === "simple") {
                                box.fields.map((_field: FieldModel, fieldIndex: number) => {
                                    if (_field.model_id === field.model_id && _field.model_type === field.model_type && _field.key === field.key) {
                                        _boxes[boxIndex].fields[fieldIndex] = res.data.data;
                                    }
                                });
                            } else if (box.type === "subboxes") {
                                box.subboxes.map((_subbox: SubBoxModel, subboxIndex: number) => {
                                    _subbox.fields.map((_field: FieldModel, fieldIndex: number) => {
                                        if (_field.model_id === field.model_id && _field.model_type === field.model_type && _field.key === field.key) {
                                            _boxes[boxIndex].subboxes[subboxIndex].fields[fieldIndex] = res.data.data;
                                        }
                                    });
                                });
                            }
                        });
                        setBoxes(_boxes);
                        handleUpdateDatamodStatus();
                    }
                }
            })
            .catch((err: any) => {
                console.error(err);
                checkAxiosError(err);
            })
            .finally(() => {
                setQueue((prevState: any) =>
                    prevState.filter((item: any) => {
                        return item.uuid !== uuid;
                    }),
                );
                setQueueRunning(null);
            });
    }

    const handleChangeNew = (
        field: FieldModel,
        value: string,
        item: SubBoxModel
    ) => {
        if (field.required && value === "") {
            presentToast({
                message: "Kötelező mező: " + field.label,
                duration: 3000,
                color: "danger",
            });
            return;
        }

        let _boxes = [...Object.values(boxes)];
        _boxes.map((box: BoxModel, boxIndex: number) => {
            if (box.type === "subboxes") {
                box.subboxes.map(
                    (_subbox: SubBoxModel, subboxIndex: number) => {
                        _subbox.fields.map(
                            (_field: FieldModel, fieldIndex: number) => {
                                if (
                                    _field.model_type === field.model_type &&
                                    _field.key === field.key &&
                                    _subbox.datamod_new_item_id ==
                                        item.datamod_new_item_id
                                ) {
                                    _boxes[boxIndex].subboxes[
                                        subboxIndex
                                    ].fields[fieldIndex].value = value;
                                    _boxes[boxIndex].subboxes[
                                        subboxIndex
                                    ].fields[fieldIndex].isLoading = true;
                                }
                            }
                        );
                    }
                );
            }
        });
        setBoxes(_boxes);

        postDatamodNewChange(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            item.datamod_new_item_id,
            field.key,
            value
        )
            .then((res: any) => {
                if (res.data.data) {
                    let _boxes = [...Object.values(boxes)];
                    _boxes.map((box: BoxModel, boxIndex: number) => {
                        if (box.type === "subboxes") {
                            box.subboxes.map(
                                (_subbox: SubBoxModel, subboxIndex: number) => {
                                    _subbox.fields.map(
                                        (
                                            _field: FieldModel,
                                            fieldIndex: number
                                        ) => {
                                            if (
                                                _field.model_type ===
                                                    field.model_type &&
                                                _field.key === field.key &&
                                                _subbox.datamod_new_item_id ==
                                                    item.datamod_new_item_id
                                            ) {
                                                _boxes[boxIndex].subboxes[
                                                    subboxIndex
                                                ].fields[fieldIndex] =
                                                    res.data.data;
                                            }
                                        }
                                    );
                                }
                            );
                        }
                    });
                    setBoxes(_boxes);
                    handleUpdateDatamodStatus();
                }
            })
            .catch((err: any) => {
                console.error(err);
                checkAxiosError(err);
            });
    };

    const handleDelete = (
        model_type: string,
        model_id: number,
        is_delete: boolean
    ) => {
        presentLoading("Adatok szinkornizálása...");
        postDatamodDelete(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            model_type,
            model_id,
            is_delete ? 1 : 0
        )
            .then((res: any) => {
                if (res.data.data) {
                    let _boxes = [...Object.values(boxes)];
                    _boxes.map((box: BoxModel, boxIndex: number) => {
                        if (box.type === "subboxes") {
                            box.subboxes.map(
                                (_subbox: SubBoxModel, subboxIndex: number) => {
                                    if (
                                        _subbox.model_id === model_id &&
                                        _subbox.model_type === model_type
                                    ) {
                                        _boxes[boxIndex].subboxes[
                                            subboxIndex
                                        ].is_marked_for_delete =
                                            res.data.data == 1 ? true : false;
                                    }
                                }
                            );
                        }
                    });
                    setBoxes(_boxes);
                    // handleUpdateDatamodStatus();
                    getBoxes();
                }
                dismissLoading();
            })
            .catch((err: any) => {
                dismissLoading();
                checkAxiosError(err);
            });
    };

    const handleNew = (model_type: string, fields: NewValue[]) => {
        presentLoading("Adatok szinkornizálása...");

        let _fields: any = {};
        fields.forEach((newValue: NewValue, _: number) => {
            _fields[newValue.field.key] = newValue.value;
        });

        postDatamodNew(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            model_type,
            JSON.stringify(_fields)
        )
            .then((res: any) => {
                dismissLoading();
                getBoxes();
            })
            .catch((err: any) => {
                dismissLoading();
                checkAxiosError(err);
            });
    };

    const handleNewDelete = (datamod_new_item_id: number) => {
        presentLoading("Adatok szinkornizálása...");

        postDatamodNewDelete(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            datamod_new_item_id
        )
            .then((res: any) => {
                dismissLoading();
                getBoxes();
            })
            .catch((err: any) => {
                dismissLoading();
                checkAxiosError(err);
            });
    };

    const handleUpdateDatamodStatus = () => {
        getDatamodStatus(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            0
        )
            .then((res: any) => {
                setDatamodStatus(res.data.data);
            })
            .catch((err) => {
                console.error(err);
                checkAxiosError(err);
            });
    };

    const handleClickReady = () => {
        presentLoading("Kérem várjon...");
        postDatamodReady(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            0
        )
            .then((res: any) => {
                dismissLoading();
                getBoxes();
                presentAlert({
                    header: "Siker",
                    message:
                        "Az adamódosítási kérelem beküldésre került, hamarosan kollégánk foglalkozni fog vele. A folyamat kezdetéig az adatok még módosíthatók.",
                    buttons: ["Rendben"],
                });
            })
            .catch((err: any) => {
                dismissLoading();
                checkAxiosError(err);
            });
    };

    const handleDeleteAll = () => {
        presentLoading("Kérem várjon...");
        postDatamodAllDelete(
            filters[selectedFilterIndex].base_data_id,
            filters[selectedFilterIndex].document_id,
            0
        )
            .then((res: any) => {
                dismissLoading();
                getBoxes();
                presentAlert({
                    header: "Siker",
                    message: "Az adamódosítási kérelemek visszavonva!",
                    buttons: ["Rendben"],
                });
                handleUpdateDatamodStatus();
            })
            .catch((err: any) => {
                dismissLoading();
                getBoxes();
                checkAxiosError(err);
            });
    };

    useIonViewWillEnter(() => {
        setFilters([]);
        setBoxes([]);
        setSelectedFilterIndex(null);
        setOpenBoxIndex(null);
        setDatamodStatus(null);
    });

    useIonViewDidEnter(() => {
        getFilters();
    });

    useEffect(() => {
        if (selectedFilterIndex !== null) {
            setOpenBoxIndex(null);
            setDatamodStatus(null);
            setBoxes([]);
            getBoxes();

            LocalStorage.set(
                "selectedFilterIndex",
                selectedFilterIndex.toString()
            );
        }
    }, [selectedFilterIndex]);

    useEffect(() => {
        if (queueRunning !== null) {
            return;
        }
        
        if (queue.length > 0) {
            runQueue(queue[0].field, queue[0].value, queue[0].uuid);
        }
    }, [queue, queueRunning]);
    

    return (
        <PageWrapper>
            <div className="hasButton">
                <h1 className="pageTitle">Adataim</h1>
            </div>

            <div className="inputWithModalWrapper">
                <IonItem
                    className="inputWithModal highlightedSelect"
                    lines="none"
                >
                    <IonSelect
                        cancelText="Mégse"
                        interface="action-sheet"
                        className="fullLength"
                        value={selectedFilterIndex}
                        placeholder="Kérem válasszon"
                        disabled={filters.length === 0}
                        onIonChange={(e) =>
                            setSelectedFilterIndex(e.detail.value)
                        }
                    >
                        {filters.map((filter, index) => (
                            <IonSelectOption key={index} value={index}>
                                {filter.label}
                            </IonSelectOption>
                        ))}
                    </IonSelect>
                </IonItem>
            </div>

            {Object.values(boxes).map((box: BoxModel, index: number) => (
                <DatamodBox
                    box={box}
                    isOpen={index === openBoxIndex}
                    setOpen={() => {
                        if (index !== openBoxIndex) {
                            setOpenBoxIndex(index);
                        } else {
                            setOpenBoxIndex(null);
                        }
                    }}
                    onChange={handleChange}
                    handleDelete={handleDelete}
                    handleNew={handleNew}
                    datamodStatus={datamodStatus}
                    handleNewDelete={handleNewDelete}
                    onChangeNew={handleChangeNew}
                    key={index}
                />
            ))}

            {(datamodStatus?.status == 0 || datamodStatus?.can_be_saved) && (
                <div id="datamod_buttons">
                    {datamodStatus?.can_be_saved && (
                        <IonButton
                            color="success"
                            onClick={() => {
                                presentAlert({
                                    header: "Figyelem",
                                    message:
                                        "Biztosan beküldi a módosított adatokat? Az adatok feldolgozásáig még lesz rá lehetősége, hogy módosítsa az adatait.",
                                    buttons: [
                                        "Mégse",
                                        {
                                            text: "Igen",
                                            handler: () => {
                                                handleClickReady();
                                            },
                                        },
                                    ],
                                });
                            }}
                        >
                            Módosítási kérelem beküldése
                        </IonButton>
                    )}
                    {datamodStatus?.status == 0 && (
                        <IonButton
                            color="danger"
                            onClick={() => {
                                presentAlert({
                                    header: "Figyelem",
                                    message:
                                        "Biztosan visszavonja a módosított adatokat? Az ügyintézés alatt álló adatmódosítások nem kerülnek visszavonásra.",
                                    buttons: [
                                        "Mégse",
                                        {
                                            text: "Igen",
                                            role: "destructive",
                                            handler: () => {
                                                handleDeleteAll();
                                            },
                                        },
                                    ],
                                });
                            }}
                        >
                            Módosítások visszavonása
                        </IonButton>
                    )}
                </div>
            )}
        </PageWrapper>
    );
};

export default DatamodNewPage;
