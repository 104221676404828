import React, { useState, useEffect } from 'react';
import {
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButton,
    IonIcon,
    IonAlert,
    useIonViewDidEnter,
    useIonToast,
    useIonViewWillEnter,
} from "@ionic/react";
import { RefresherEventDetail } from '@ionic/core';
import { cardOutline, caretDown, copyOutline, documentTextOutline, list } from 'ionicons/icons';
import { Capacitor } from "@capacitor/core";
import { Clipboard } from '@capacitor/clipboard';
import PageWrapper from '../layouts/PageWrapper';
import SkeletonAccordionItem from '../../components/SkeletonAccordionItem';
import EmptyText from '../../components/EmptyText';
import FilterModal from '../../components/FilterModal';
import { useAuth } from '../../context/Auth';
import { Filter, FilterJsonObject, FilterObject, FilterQueryObject, getFiltersArray } from '../../helpers/FilterHelper';
import InvoiceAccordionItem from '../../components/InvoiceAccordionItem';
import { fetchInvoiceFilters, fetchInvoices } from '../../services/InvoiceServices';
import { getFileUrl } from '../../helpers/DevHelper';
import { getInvoicePrice } from '../../helpers/GlobalHelpers';

import barionLogo from '../../assets/images/barion-card-strip-intl.svg';
import NumberFormat from 'react-number-format';

interface Documents {
    id: number;
    type: string;
    createdAt: string;
    related: any;
    transactions: any;
    barion_transactions?: any;
    fileId?: string | null;
    title?: string;
    sub_title?: string;
}

const dateFormat = require('dateformat');

const InvoicesPage: React.FC = () => {
    const [ present ] = useIonToast();
    const { checkAxiosError } = useAuth();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ disablePullToRefresh, setDisablePullToRefresh ] = useState<boolean>(true);
    const [ disableInfiniteScroll, setDisableInfiniteScroll ] = useState<boolean>(true);
    const [ filters, setFilters ] = useState([]);
    const [ skip, setSkip ] = useState<number>(0);
    const [ documents, setDocuments ] = useState<Documents[]>([]);
    const [ showAlert, setShowAlert ] = useState<string | null>(null);

    const pullToRefresh = (event?: CustomEvent<RefresherEventDetail> | null) => {
        getData(false, true).then(() => {
            if (event !== null) {
                event.detail.complete();
            }
        });
    }

    const infiniteScrollNext = (event: CustomEvent<void>) => {
        getData(true).then(() => {
            (event.target as HTMLIonInfiniteScrollElement).complete();
        });
    }

    const getData = async (next: boolean = false, forceRefresh: boolean = false) => {
        let filterQuery: FilterJsonObject[] = [];
        if (filters.length > 0) {
            filters.forEach((_fil: any) => {
                filterQuery.push({
                    key: _fil.key,
                    value: _fil.value,
                } as FilterJsonObject);
            });
        }

        const queryObject: FilterQueryObject = {
            skip: (next ? skip : 0),
            filters: filterQuery,
        };

        setDisablePullToRefresh(true);

        if (forceRefresh) {
            setDocuments([]);
            setIsLoading(true);
            setSkip(0);
        }

        fetchInvoices(queryObject)
        .then(res => {
            const response: any = res.data.data;
            if (response && response.length > 0) {
                if (response.length < 15) {
                    // Ha kevesebb mint 15 talált van akkor nem kell az infinite scroll mert úgy sem fog többet találni.
                    setDisableInfiniteScroll(true);
                } else {
                    setDisableInfiniteScroll(false);
                    setSkip(prevState => prevState + 15);
                }

                response.forEach((document: any) => {
                    if (document.related !== null) {
                        var _document: Documents = {
                            id: document.id,
                            type: document.related_type,
                            createdAt: document.created_at,
                            fileId: document.file?.access_hash,
                            related: document.related,
                            transactions: document.transactions,
                            barion_transactions: document.barion_transactions,
                            title: document.mypatent_title,
                            sub_title: document.mypatent_sub_title??"",
                        };

                        setDocuments(prevState => (
                            [
                                ...prevState,
                                _document,
                            ]
                        ));
                    }
                });
            } else {
                setDisableInfiniteScroll(true);
            }

            setIsLoading(false);
            setDisablePullToRefresh(false);
        }).catch(err => {
            checkAxiosError(err);
        });
    }

    /* Filters */
    const setFilterValue = (name: string, value: string) => {
        setFilters(
            filters.map((item: FilterObject) => item.key === name ? { ...item, value: value } : item)
        );
    }

    const getFilters = () => {
        if (filters.length === 0) {
            fetchInvoiceFilters()
            .then(res => {
                if (res.data.data && res.data.data.length > 0) {
                    var _filters = getFiltersArray(res.data.data);
                    setFilters(_filters);
                }
            }).catch(err => {
                checkAxiosError(err);
            });
        }
    }

    const isPaid = (document: Documents) => {
        if(document.related.U_CPH_PaymentCreated !== undefined && 
            document.related.U_CPH_PaymentCreated !== null && 
            document.related.U_CPH_PaymentCreated === 'Y') {
            return true;
        }

        if (document.transactions !== undefined && 
            document.transactions.length > 0 && 
            document.transactions[0] !== undefined && 
            document.transactions[0].status === 'FINISHED') {
            return true;
        }

        return false;
    }

    const writeToClipboard = async (s: string) => {
        await Clipboard.write({
            string: s,
        });
    };

    useIonViewWillEnter(() => {
        setIsLoading(true);
        setDisablePullToRefresh(true);
        setDisableInfiniteScroll(true);
        setFilters([]);
        setDocuments([]);
        setShowAlert(null);
    });
    
    useIonViewDidEnter(() => {
        // Első betöltésnél kérje le a filtereket
        getFilters();
    });

    useEffect(() => {
        // Ha változtatnak a szűrésen akkor firssítsük le a listát teljesen
        if (filters.length > 0) {
            getData(false, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <PageWrapper>
            <IonRefresher disabled={disablePullToRefresh} slot="fixed" onIonRefresh={pullToRefresh}>
                <IonRefresherContent
                    pullingIcon={caretDown}
                    pullingText=""
                    refreshingSpinner="crescent"
                    refreshingText="">
                </IonRefresherContent>
            </IonRefresher>

            <div className="hasButton">
                <h1 className="pageTitle">Számláim</h1>
                <IonButton
                    size="small"
                    className="hideOnMobile"
                    color="success"
                    disabled={disablePullToRefresh}
                    onClick={() => pullToRefresh(null)}
                >
                    Frissítés
                </IonButton>
                <a rel="noopener noreferrer" href="https://www.barion.com/hu/" target="_blank" id="barionLogo">
                    <img height="30" src={barionLogo} title="Barion" alt="Barion" />
                </a>
            </div>

            {(filters.length > 0) &&
                <>
                    <FilterModal number={documents.length ?? 0} setFilterDefault={() => {}}>
                        {filters.map((filter, key) => (
                            <Filter key={key} filter={filter} setFilterValue={(name, value) => setFilterValue(name, value)} />
                        ))}
                    </FilterModal>
                    <br />
                </>
            }

            {(documents.length > 0) ?
                (
                    <>
                        {documents
                            .map(document => (
                                <InvoiceAccordionItem
                                    key={document.id}
                                    docDueDate={document.related.DocDueDate}
                                    type={document.type}
                                    createdAt={document.createdAt}
                                    related={document.related}
                                    transactions={document.transactions}
                                    barion_transactions={document.barion_transactions}
                                >
                                    <div>
                                        <p>{document.related.Comments ?? ''}</p>
                                    </div>
                                    <br />
                                    <div className="list-between">
                                        <span>Számlaszám</span>
                                        <span>
                                            {document.related.invoice_number}
                                        </span>
                                    </div>
                                    <IonButton
                                        size="small"
                                        color="warning"
                                        onClick={() => {
                                            if (Capacitor.isNativePlatform()) {
                                                writeToClipboard(document.related.invoice_number);
                                                present({
                                                    duration: 2000,
                                                    color: "success",
                                                    message: "Számlaszám kimásolva",
                                                });
                                            } else {
                                                navigator.clipboard.writeText(document.related.invoice_number).then(function() {
                                                    present({
                                                        duration: 2000,
                                                        color: "success",
                                                        message: "Számlaszám kimásolva",
                                                    });
                                                });
                                            }
                                        }}
                                        expand="block"
                                    >
                                        <IonIcon icon={copyOutline} slot="start" />
                                        Számlaszám másolása
                                    </IonButton>
                                    <div className="list-between">
                                        <span>Fizetési határidő</span>
                                        <span>
                                            {document.related.DocDueDate ? dateFormat(document.related.DocDueDate, 'yyyy.mm.dd') : ''}
                                        </span>
                                    </div>
                                    <div className="list-between price">
                                        <span>Fizetendő</span>
                                        <span>
                                            <NumberFormat value={document.related.calculated_total} displayType={"text"} thousandSeparator={" "} suffix={" Ft"} decimalScale={0} />
                                        </span>
                                    </div>
                                    <br />
                                    <div className="rightButton">
                                        {document.fileId &&
                                            <>
                                                <IonButton size="small" href={getFileUrl() + "/" + document.fileId} target="_blank" color="success">
                                                    <IonIcon icon={documentTextOutline} slot="start" />
                                                    Megnyitása
                                                </IonButton>
                                            </>
                                        }
                                        <IonButton size="small" routerLink={"/my/invoices/payment/" + document.id} routerDirection="forward" color="primary">
                                            <IonIcon icon={list} slot="start" />
                                            Részletek
                                        </IonButton>
                                        {document.related.GroupNum !== "2" &&
                                            <>
                                                {(isPaid(document) === false) &&
                                                    <>
                                                        <IonButton size="small" color="warning" routerLink={"/my/invoices/payment/" + document.id} routerDirection="forward">
                                                            <IonIcon icon={cardOutline} slot="start" />
                                                            Fizetés
                                                        </IonButton>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </InvoiceAccordionItem>

                            ))
                        }
                    </>
                )
                :
                (isLoading ?
                    <SkeletonAccordionItem count={8} />
                    :
                    <EmptyText text="Nincs számla" subtext="Az Ön számlái ezen a felületen fognak megjelenni." />
                )
            }

            {(!isLoading && disableInfiniteScroll && documents.length > 0) &&
                <EmptyText text="Nincs több találat..." subtext="" />
            }

            <IonAlert
                isOpen={Boolean(showAlert !== null)}
                onDidDismiss={() => setShowAlert(null)}
                header={'Információ'}
                message={showAlert}
                buttons={[
                    {
                        text: 'Rendben',
                        role: 'cancel',
                    }
                ]}
            />

            <IonInfiniteScroll threshold="100px"
                disabled={disableInfiniteScroll}
                onIonInfinite={(e: CustomEvent<void>) => infiniteScrollNext(e)}>
                <IonInfiniteScrollContent
                    loadingSpinner="crescent"
                    loadingText="További elemek betöltése">
                </IonInfiniteScrollContent>
            </IonInfiniteScroll>

        </PageWrapper>
    );
};

export default InvoicesPage;
