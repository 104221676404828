import React, { useEffect, useState } from 'react';

import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
} from "@ionic/react";
import { saveOutline } from 'ionicons/icons';

import PageWrapper from '../layouts/PageWrapper';
import { useAuth } from '../../context/Auth';
import useFormValidation from '../../helpers/FormValidationHelper';
import { addApiErrors, setLoading, hasError, setInitError, ErrorsList } from '../../helpers/GlobalHelpers';
import ValidateNewPassword from '../../helpers/validators/ValidateNewPassword';
import { postNewPassword } from '../../services/GlobalServices';

import './DatamodPage.scss';
import { sendBarionPixel } from '../../helpers/barion/PixelHelper';

const INIT_STATE = {
    email: '',
    password: '',
    password_new: '',
    password_new_re: '',
};

const UserPage: React.FC = () => {
    const { handleChange, values } = useFormValidation(INIT_STATE);
    const { checkAxiosError, userInfo } = useAuth();
    
    const [ status, setStatus ] = useState({
        loading: false,
        error_list: INIT_STATE,
    });
    const [passwordSaved, setPasswordSaved] = useState<boolean>(false);

    const savePassword = async () => {
        setLoading(true, setStatus);
        setInitError(INIT_STATE, setStatus);

        const errors = ValidateNewPassword(values);
        if (hasError(errors)) {
            setLoading(false, setStatus);
            addApiErrors(errors, setStatus);
        } else {
            postNewPassword(
                values.password,
                values.password_new,
                values.password_new_re
            ).then(res => {
                setLoading(false, setStatus);
                setPasswordSaved(true);
            }).catch(err => {
                setLoading(false, setStatus);
                addApiErrors(err.response.data.message, setStatus);
                checkAxiosError(err);
            });
        }
    };

    useEffect(() => {
        if (userInfo) {
            sendBarionPixel("setUserProperties", {
                userId: userInfo.id.toString(),
            });
        }
    }, [userInfo]);
    

    return (
        <PageWrapper>
            <div>
                <h1 className="pageTitle">Jelszóváltoztatás</h1>
            </div>

            <IonCard className="datamodList ion-margin-bottom">
                <IonCardContent className="isOpen">
                    <form className="form">
                        <div>
                            <IonItem>
                                <IonLabel position="floating">Régi jelszó</IonLabel>
                                <IonInput title="Régi jelszó" onIonInput={handleChange} clearOnEdit={false} type="password" pattern="password" name="password" value={values.password} required></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Új jelszó</IonLabel>
                                <IonInput title="Új jelszó" onIonInput={handleChange} clearOnEdit={false} type="password" pattern="password" name="password_new" value={values.password_new} required></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Új jelszó mégegyszer</IonLabel>
                                <IonInput title="Új jelszó mégegyszer" onIonInput={handleChange} clearOnEdit={false} type="password" pattern="password" name="password_new_re" value={values.password_new_re} required></IonInput>
                            </IonItem>

                            <br/>
                            <ErrorsList errors={status.error_list} />
                            
                            <div className="bottomPaddingButton" style={{ padding: "0 20px 20px" }}>
                                <IonButton color="success" expand="block" onClick={() => savePassword()}>
                                    Mentés
                                </IonButton>
                            </div>
                        </div>
                    </form>
                </IonCardContent>
            </IonCard>

            <IonLoading
                isOpen={status.loading}
                message={'Kérem várjon...'}
            />

            <IonAlert
                isOpen={passwordSaved}
                onDidDismiss={() => setPasswordSaved(false)}
                header={'Siker'}
                message={'A jelszó megváltoztott!'}
                buttons={[
                    {
                        text: 'Rendben',
                        role: 'cancel',
                    }
                ]}
                />   
        </PageWrapper>
    );
};

export default UserPage;
