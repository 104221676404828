import React, { useEffect, useState } from "react";
import Axios from "axios";
import NumberFormat from "react-number-format";
import { IonRow, IonCol, IonCard, IonIcon, IonBadge, IonButton, useIonViewDidEnter, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, useIonLoading } from "@ionic/react";
import { documentOutline, notificationsOutline, fileTrayFullOutline, laptopOutline, readerOutline, warning, checkmarkCircle, cartOutline, shieldCheckmark } from "ionicons/icons";

import { useNotification } from "../../context/Notifications";
import PageWrapper from "../layouts/PageWrapper";
import { useAuth } from "../../context/Auth";
import { fetchUnpaidInvoices } from "../../services/InvoiceServices";
import { getActivatedDocuments, postActivateDocument } from "../../services/DocumentServices";

import logo from "../../assets/images/logo.svg";

import "./DashboardPage.scss";

const DashboardPage: React.FC = () => {
  const { unreadNotifictaions } = useNotification();
  const [presentLoading, dismissLoading] = useIonLoading();
  const { authToken, checkAxiosError } = useAuth();

  const [unpaidInvoices, setUnpaidInvoices] = useState<any>(null);
  const [activatedDocuments, setActivatedDocuments] = useState<any>([]);
  const [selectedActivatedDocument, setSelectedActivatedDocument] = useState<any>(undefined);

  const getUnpaidInvoices = () => {
    fetchUnpaidInvoices()
      .then((res: any) => {
        if (res.data.error === false) {
          setUnpaidInvoices(res.data.data);
        }
      })
      .catch((err: any) => {
        checkAxiosError(err);
      });
  };

  const fetchActivatedDocuments = () => {
    getActivatedDocuments()
      .then((res: any) => {
        if (res.data.error === false) {
          setActivatedDocuments(res.data.data);
        }
      })
      .catch((err: any) => {
        checkAxiosError(err);
      });
  };

  const handleActivateDocument = (id: string) => {
    presentLoading("Kérem várjon...");
    postActivateDocument(id)
      .then((res: any) => {})
      .catch((err: any) => {
        checkAxiosError(err);
      })
      .finally(() => {
        setSelectedActivatedDocument(undefined);
        dismissLoading();
        fetchActivatedDocuments();
      });
  };

  useIonViewDidEnter(() => {
    getUnpaidInvoices();
    fetchActivatedDocuments();
    setSelectedActivatedDocument(undefined);
  });

  return (
    <>
      <PageWrapper>
        <div className="hasButton">
          <h1 className="pageTitle">Főoldal</h1>
          <IonButton size="small" color="success" routerLink="/assign" routerDirection="none">
            Új hozzárendelés
          </IonButton>
        </div>

        <div id="desktopWelcome">
          <h2>Üdv a myPatent alkalmazásban!</h2>
          <p>
            Bármilyen hibát talált kérem jelezze a <a href="mailto:hello@mypatent.hu">hello@mypatent.hu</a> címen! Köszönjük!
          </p>
          {/*<div className="inlineButtons">
                    <IonButton color="light" size="small" routerLink="/assign" routerDirection="forward">
                        Új hozzárendelés
                    </IonButton>
                    <IonButton color="light" size="small" routerLink="/my/documents" routerDirection="forward">
                        Dokumentumaim
                    </IonButton>
                    <IonButton color="light" size="small" routerLink="/my/invoices" routerDirection="forward">
                        Számláim
                    </IonButton>
                    <IonButton color="light" size="small" routerLink="/my/profile" routerDirection="forward">
                        Adataim
                    </IonButton>
                </div>*/}
        </div>

        {unpaidInvoices !== null ? (
          <IonRow>
            <IonCol size="12">
              <IonCard button={true} className="cardButton alert" routerLink="/my/invoices" routerDirection="forward">
                {unpaidInvoices > 0 ? (
                  <div className="invoiceAlert">
                    <IonIcon icon={warning} />
                    <h2>
                      Fizetetlen számlák:{" "}
                      <strong>
                        <NumberFormat value={unpaidInvoices} displayType={"text"} thousandSeparator={" "} suffix={" Ft"} decimalScale={0} />
                      </strong>
                    </h2>
                  </div>
                ) : (
                  <div className="invoiceAlert success">
                    <IonIcon icon={checkmarkCircle} />
                    <h2>Nincs fizetetlen számla!</h2>
                  </div>
                )}
              </IonCard>
            </IonCol>
          </IonRow>
        ) : null}

        {activatedDocuments.length > 0 ? (
          <>
            {activatedDocuments.map((a: any) => (
              <IonRow key={a.id}>
                <IonCol size="12">
                  <IonCard button={true} className="cardButton alert" onClick={() => setSelectedActivatedDocument(a)}>
                    <div className="invoiceAlert full">
                      <IonIcon icon={shieldCheckmark} />
                      <h2>Szerződés aktiválása: {a.related.callID} - {a.related.U_myPatentObjektumCim ?? "-"}</h2>
                    </div>
                  </IonCard>
                </IonCol>
              </IonRow>
            ))}
          </>
        ) : null}

        <IonRow>
          <IonCol size="6" sizeMd="3" sizeLg="4">
            <IonCard button={true} className="cardButton" routerLink="/my/notifications" routerDirection="forward">
              {unreadNotifictaions > 0 && <IonBadge color="danger">{unreadNotifictaions}</IonBadge>}
              <IonIcon icon={notificationsOutline} />
              <h2>Értesítések</h2>
            </IonCard>
          </IonCol>
          <IonCol size="6" sizeMd="3" sizeLg="4">
            <IonCard button={true} className="cardButton" routerLink="/my/documents" routerDirection="forward">
              <IonIcon icon={documentOutline} />
              <h2>Dokumentumtár</h2>
            </IonCard>
          </IonCol>
          <IonCol size="6" sizeMd="3" sizeLg="4">
            <IonCard button={true} className="cardButton" routerLink="/my/profile" routerDirection="forward">
              <IonIcon icon={readerOutline} />
              <h2>Adataim</h2>
            </IonCard>
          </IonCol>
          <IonCol size="6" sizeMd="3" sizeLg="4">
            <IonCard button={true} className="cardButton" routerLink="/my/invoices" routerDirection="forward">
              <IonIcon icon={fileTrayFullOutline} />
              <h2>Számláim</h2>
            </IonCard>
          </IonCol>
          <IonCol size="6" sizeMd="3" sizeLg="4">
            <IonCard button={true} className="cardButton" routerLink="/my/subsystems" routerDirection="forward">
              <IonIcon icon={laptopOutline} />
              <h2>Alrendszerek</h2>
            </IonCard>
          </IonCol>
          <IonCol size="6" sizeMd="3" sizeLg="4">
            <IonCard button={true} className="cardButton" routerLink="/my/services" routerDirection="forward">
              <IonBadge color="primary">ÚJ</IonBadge>
              <IonIcon icon={cartOutline} />
              <h2>Szolgáltatások</h2>
            </IonCard>
          </IonCol>
        </IonRow>
      </PageWrapper>
      <IonModal backdropDismiss={false} isOpen={selectedActivatedDocument !== undefined}>
        {selectedActivatedDocument ? (
          <>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Szerződés aktiválása</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setSelectedActivatedDocument(undefined)}>Mégse</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="center-modal">
                <div>
                  <img src={logo} alt="myPatent Logo" className="desktopLogo" />
                  <div className="list-between">
                    <span>Partner neve</span>
                    <span>{selectedActivatedDocument.base_data.name ?? "-"}</span>
                  </div>
                  <div className="list-between">
                    <span>Objektum cím</span>
                    <span>{selectedActivatedDocument.related.U_myPatentObjektumCim ?? "-"}</span>
                  </div>
                  <div className="list-between">
                    <span>Sorszám</span>
                    <span>{selectedActivatedDocument.related.U_Sorszam ?? "-"}</span>
                  </div>
                </div>
                <div>
                  <IonButton color="success" expand="block" onClick={() => handleActivateDocument(selectedActivatedDocument.related.id)}>
                    Aktiválás
                  </IonButton>
                  <IonButton color="medium" expand="block" onClick={() => setSelectedActivatedDocument(undefined)}>
                    Mégse
                  </IonButton>
                </div>
              </div>
            </IonContent>
          </>
        ) : null}
      </IonModal>
    </>
  );
};

export default DashboardPage;
