import Axios from "axios";
import { getApiUrl, getAuthToken } from "./DevHelper";

const qs = require("qs");
const AUTH_HEADER = "Authorization";

export const HttpClient = ({
    method = "get",
    url = "",
    data = {},
    params = {},
    timeout = 15000,
    additionalHeaders = {},
    contentType = "application/json",
    cancelToken = undefined,
}: any) => {
    const authToken = getAuthToken();

    let headers = {
        "Content-Type": contentType,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Accept: "application/json, text/plain",
        ...additionalHeaders,
    };

    if (authToken && authToken !== "undefined") {
        headers[AUTH_HEADER] = `Bearer ${authToken}`;
    }

    /*
    if (contentType === "multipart/form-data") {
        let oldData = data;
        data = new FormData();
        for (var key in oldData) {
            data.append(key, oldData[key]);
        }
    } else if (contentType === "application/x-www-form-urlencoded") {
        data = qs.stringify(data);
    }
    */

    return Axios({
        url: `${getApiUrl()}${url}`,
        method,
        timeout,
        headers,
        data,
        params,
        cancelToken,
    });
};

export default HttpClient;
