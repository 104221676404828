import LocalStorage from "./LocalStorage";

export const isDevMode = () => {
    return LocalStorage.get("isDev") === "true";
}

export const getApiUrl = () => {
    return process.env.REACT_APP_MYPATENT_API_URL;
}

export const getFileUrl = () => {
    return process.env.REACT_APP_MYPATENT_FILE_URL;
}

export const getAuthToken = () => {
    return LocalStorage.get("authToken");
}

export const setDevOrProd = () => {
    if (isDevMode()) {
        LocalStorage.set("isDev", "false");
    } else {
        LocalStorage.set("isDev", "true");
    }
}
