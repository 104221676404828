

import React from "react";

import { BoxModel, DatamodStatusModel, FieldModel } from "../../models/GlobalModels";
import DatamodInput from "./controls/DatamodInput";

interface Props {
    box: BoxModel;
    onChange: (field: FieldModel, value: string) => void;
    datamodStatus: DatamodStatusModel;
}

const DatamodSimple: React.FC<Props> = ({ box, onChange, datamodStatus }) => {
    return (
        <>
            {box.fields.map((field: FieldModel, index: number) => (
                <DatamodInput
                    field={field}
                    key={index}
                    onChange={onChange}
                    datamodStatus={datamodStatus}
                />
            ))}
        </>
    );
};
export default DatamodSimple;
