import React, { useState, useEffect } from "react";
import {
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButton,
    useIonViewDidEnter,
    useIonViewWillEnter,
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import { caretDown } from "ionicons/icons";

import PageWrapper from "../layouts/PageWrapper";
import DocumentAccordionItem from "../../components/DocumentAccordionItem";
import SkeletonAccordionItem from "../../components/SkeletonAccordionItem";
import EmptyText from "../../components/EmptyText";
import FilterModal from "../../components/FilterModal";
import { useAuth } from "../../context/Auth";
import {
    Filter,
    FilterJsonObject,
    FilterObject,
    FilterQueryObject,
    getFiltersArray,
} from "../../helpers/FilterHelper";
import {
    fetchDocumentFilters,
    fetchDocuments,
} from "../../services/DocumentServices";

interface Documents {
    id: number;
    type: string;
    createdAt: string;
    fileId: string;
    related: any;
    title?: string;
    sub_title?: string;
}

const DocumentsPage: React.FC = () => {
    const { checkAxiosError } = useAuth();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [disablePullToRefresh, setDisablePullToRefresh] =
        useState<boolean>(true);
    const [disableInfiniteScroll, setDisableInfiniteScroll] =
        useState<boolean>(true);
    const [filters, setFilters] = useState([]);
    const [skip, setSkip] = useState<number>(0);
    const [documents, setDocuments] = useState<Documents[]>([]);

    const pullToRefresh = (
        event?: CustomEvent<RefresherEventDetail> | null
    ) => {
        getData(false, true).then(() => {
            if (event !== null) {
                event.detail.complete();
            }
        });
    };

    const infiniteScrollNext = (event: CustomEvent<void>) => {
        getData(true).then(() => {
            (event.target as HTMLIonInfiniteScrollElement).complete();
        });
    };

    const getData = async (
        next: boolean = false,
        forceRefresh: boolean = false
    ) => {
        let filterQuery: FilterJsonObject[] = [];
        if (filters.length > 0) {
            filters.forEach((_fil: any) => {
                filterQuery.push({
                    key: _fil.key,
                    value: _fil.value,
                } as FilterJsonObject);
            });
        }

        const queryObject: FilterQueryObject = {
            skip: next ? skip : 0,
            filters: filterQuery,
        };

        setDisablePullToRefresh(true);

        if (forceRefresh) {
            setDocuments([]);
            setIsLoading(true);
            setSkip(0);
        }

        fetchDocuments(queryObject)
            .then((res) => {
                var response = res.data.data;

                if (response && response.length > 0) {
                    if (response.length < 15) {
                        // Ha kevesebb mint 15 talált van akkor nem kell az infinite scroll mert úgy sem fog többet találni.
                        setDisableInfiniteScroll(true);
                    } else {
                        setDisableInfiniteScroll(false);
                        setSkip((prevState) => prevState + 15);
                    }

                    response.forEach((document: any) => {
                        if (document.related !== null) {
                            var _document: Documents = {
                                id: document.id,
                                type: document.related_type,
                                createdAt: document.created_at,
                                fileId: document.file?.access_hash,
                                related: document.related,
                                title: document.mypatent_title,
                                sub_title: document.mypatent_sub_title ?? "",
                            };

                            setDocuments((prevState) => [
                                ...prevState,
                                _document,
                            ]);
                        }
                    });
                } else {
                    setDisableInfiniteScroll(true);
                }

                setIsLoading(false);
                setDisablePullToRefresh(false);
            })
            .catch((err) => {
                checkAxiosError(err);
            });
    };

    /* Filters */
    const setFilterValue = (name: string, value: string) => {
        const nextState = filters.map((item: FilterObject) =>
            item.key === name ? { ...item, value: value } : item
        );
        setFilters(nextState);
    };

    const getFilters = () => {
        if (filters.length === 0) {
            fetchDocumentFilters()
                .then((res) => {
                    if (res.data.data && res.data.data.length > 0) {
                        var _filters = getFiltersArray(res.data.data);
                        setFilters(_filters);
                    }
                })
                .catch((err) => {
                    checkAxiosError(err);
                });
        }
    };

    useIonViewWillEnter(() => {
        setIsLoading(true);
        setDisablePullToRefresh(true);
        setDisableInfiniteScroll(true);
        setFilters([]);
        setDocuments([]);
    });

    useIonViewDidEnter(() => {
        // Első betöltésnél kérje le a filtereket
        getFilters();
    });

    useEffect(() => {
        // Ha változtatnak a szűrésen akkor firssítsük le a listát teljesen
        if (filters.length > 0) {
            getData(false, true);
        }
    }, [filters]);

    return (
        <PageWrapper>
            <IonRefresher
                disabled={disablePullToRefresh}
                slot="fixed"
                onIonRefresh={pullToRefresh}
            >
                <IonRefresherContent
                    pullingIcon={caretDown}
                    pullingText=""
                    refreshingSpinner="crescent"
                    refreshingText=""
                ></IonRefresherContent>
            </IonRefresher>

            <div className="hasButton">
                <h1 className="pageTitle">Dokumentumtár</h1>
                <IonButton
                    size="small"
                    className="hideOnMobile"
                    color="success"
                    disabled={disablePullToRefresh}
                    onClick={() => pullToRefresh(null)}
                >
                    Frissítés
                </IonButton>
            </div>

            {filters.length > 0 && (
                <>
                    <FilterModal
                        number={documents.length ?? 0}
                        setFilterDefault={() => {
                            let tempFiltersData = [...filters];
                            console.log("tempFiltersData", tempFiltersData);
                            tempFiltersData.forEach(
                                (tempFilterData) => (tempFilterData.value = "0")
                            );
                            setFilters(tempFiltersData);
                        }}
                        showDeleteFiltersButton={
                            filters.filter((filter) => filter.value !== "0")
                                .length > 0
                        }
                    >
                        {filters.map((filter, key) => (
                            <Filter
                                key={key}
                                filter={filter}
                                setFilterValue={(name, value) =>
                                    setFilterValue(name, value)
                                }
                            />
                        ))}
                    </FilterModal>
                    <br />
                </>
            )}

            {documents.length > 0 ? (
                <>
                    {documents.map((document) => (
                        <DocumentAccordionItem
                            key={document.id}
                            id={document.id}
                            type={document.type}
                            createdAt={document.createdAt}
                            fileId={document.fileId}
                            related={document.related}
                            title={document.title}
                            sub_title={document.sub_title}
                        />
                    ))}
                </>
            ) : isLoading ? (
                <SkeletonAccordionItem count={8} />
            ) : (
                <EmptyText
                    text="Nincs dokumentum"
                    subtext="Az Ön dokumentumai ezen a felületen fognak megjelenni később."
                />
            )}

            {!isLoading && disableInfiniteScroll && documents.length > 0 && (
                <EmptyText text="Nincs több találat..." subtext="" />
            )}

            <IonInfiniteScroll
                threshold="100px"
                disabled={disableInfiniteScroll}
                onIonInfinite={(e: CustomEvent<void>) => infiniteScrollNext(e)}
            >
                <IonInfiniteScrollContent
                    loadingSpinner="crescent"
                    loadingText="További elemek betöltése"
                ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
        </PageWrapper>
    );
};

export default DocumentsPage;
