import React from "react";

import {
    IonItem,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";

import { FieldModel } from "../../../models/GlobalModels";

interface Props {
    field: FieldModel;
    onChange: (field: FieldModel, value: string) => void;
    isLoading: boolean;
    valueOverwrite?: string;
}

const SelectControl: React.FC<Props> = ({ field, onChange, isLoading, valueOverwrite }) => {
    return (
        <>
            <IonItem lines="none">
                <IonSelect 
                    cancelText="Mégse" 
                    interface="action-sheet" 
                    value={valueOverwrite ? valueOverwrite.toString() : field.value ? field.value.toString() : ""} 
                    placeholder={field.label??""}
                    disabled={isLoading || !field.is_editable}
                    onIonChange={(e: any) => {
                        if (e.target.value !== field.value && !isLoading && field.is_editable) {
                            onChange(field, e.target.value);
                        }
                    }}
                    className="fullLength"
                >
                    {Object.keys(field.type).map((key: any, index: any) => 
                        <IonSelectOption key={index} value={key}>
                            {field.type[key]}
                        </IonSelectOption>
                    )}
                </IonSelect>
            </IonItem>
        </>
    );
};
export default SelectControl;
