import React from "react";

import {
    IonBadge,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonItemGroup,
} from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";

import {
    BoxModel,
    DatamodStatusModel,
    FieldModel,
    NewValue,
    SubBoxModel,
} from "../../models/GlobalModels";
import DatamodSimple from "./DatamodSimple";
import DatamodSubbox from "./DatamodSubbox";

interface Props {
    box: BoxModel;
    isOpen: boolean;
    setOpen: () => void;
    onChange: (field: FieldModel, value: string) => void;
    handleDelete: (
        model_type: string,
        model_id: number,
        is_delete: boolean
    ) => void;
    handleNew: (model_type: string, fields: NewValue[]) => void;
    datamodStatus: DatamodStatusModel;
    handleNewDelete: (datamod_new_item_id: number) => void;
    onChangeNew: (field: FieldModel, value: string, item: SubBoxModel) => void;
}

const DatamodBox: React.FC<Props> = ({
    box,
    isOpen,
    setOpen,
    onChange,
    handleDelete,
    handleNew,
    datamodStatus,
    handleNewDelete,
    onChangeNew,
}) => {
    const hasDatamod = () => {
        let count = 0;
        if (box.fields) {
            count = box.fields.filter(
                (field: FieldModel) => field.has_datamod_in_progress
            ).length;
        } else {
            count = box.subboxes.filter(
                (subbox: SubBoxModel) =>
                    subbox.fields.filter(
                        (field: FieldModel) => field.has_datamod_in_progress
                    ).length > 0
            ).length;
        }
        return count > 0;
    };

    return (
        <IonCard className="datamodList ion-margin-bottom">
            <IonItemGroup>
                <IonCardHeader onClick={setOpen}>
                    <IonCardTitle>
                        <div>
                            {hasDatamod() && (
                                <IonBadge
                                    color="warning"
                                    style={{ marginRight: 8 }}
                                >
                                    !
                                </IonBadge>
                            )}
                            {box.name ?? ""}
                        </div>

                        <div className={"chevron-icon " + (isOpen ? "isOpen" : "isClosed")}>
                            <div className="chevron-icon-inner"></div>
                        </div>
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className={isOpen ? "isOpen" : "isClosed"}>
                    {box.type === "simple" ? (
                        <DatamodSimple
                            box={box}
                            onChange={onChange}
                            datamodStatus={datamodStatus}
                        />
                    ) : (
                        <DatamodSubbox
                            box={box}
                            onChange={onChange}
                            handleDelete={handleDelete}
                            handleNew={handleNew}
                            datamodStatus={datamodStatus}
                            handleNewDelete={handleNewDelete}
                            onChangeNew={onChangeNew}
                        />
                    )}
                </IonCardContent>
            </IonItemGroup>
        </IonCard>
    );
};

export default DatamodBox;
