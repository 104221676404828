import React from "react";

import { IonText } from "@ionic/react";
import { isPlatform } from "@ionic/react";

import packageJson from "../../package.json";
import NumberFormat from "react-number-format";

const semver = require("semver");

export const isObject = (data: object) => {
    return !!data && data.constructor === Object;
};

export const forEachApiErrors = (err: any, setState: any) => {
    Object.keys(err).forEach((key) => {
        setState((prevState: any) => ({
            ...prevState,
            error_list: {
                ...prevState.error_list,
                [key]: err[key],
            },
        }));
    });
};

export const addApiErrors = (err: any, setState: any) => {
    if (isObject(err)) {
        forEachApiErrors(err, setState);
    } else {
        setState((prevState: any) => ({
            ...prevState,
            error_list: {
                ...prevState.error_list,
                error: err,
            },
        }));
    }
};

export const setLoading = (loading: boolean, setState: any) => {
    setState((prevState: any) => ({
        ...prevState,
        loading: loading,
    }));
};

export const setInitError = (init_state: any, setState: any) => {
    setState((prevState: any) => ({
        ...prevState,
        error_list: init_state,
    }));
};

export const hasError = (err: any) => {
    return Object.keys(err).length > 0;
};

export const ErrorsList: React.FC<{ errors: any }> = ({ errors }) => {
    return (
        <div className="errorsList">
            {Object.entries(errors).map(([key, value], index) => {
                if (value) {
                    return (
                        <p
                            className="ion-text-center"
                            data-testid="errorText"
                            key={index}
                        >
                            <IonText color="danger">{value ?? ""}</IonText>
                        </p>
                    );
                } else {
                    return <div key={index}></div>;
                }
            })}
        </div>
    );
};

export const isMobileNumber = (phone: string) => {
    const regex = /^(06|36|0036|\+36)?(20|30|50|70)([0-9]{7})$/;
    let match = phone.replace(/\s/g, "").match(regex);
    return Boolean(match !== null && match.length > 0);
};

export const GetBasicPlatforms = (): string => {
    if (isPlatform("desktop") || isPlatform("mobileweb")) {
        return "web";
    } else {
        if (isPlatform("android")) {
            return "android";
        } else if (isPlatform("ios")) {
            return "ios";
        }
    }
};

export const getCurrentVersion = () => {
    return packageJson.version ?? "0.0.0";
};

export const hasNewVersion = (ver: string) => {
    if (semver.valid(ver)) {
        if (semver.gt(ver, getCurrentVersion())) {
            return true;
        }
    }
    return false;
};

export const getInvoicePrice = (related: any) => {
    let price = related.DoCTotal ? parseInt(related.DoCTotal) : 0;
    if (related.PaidToDate) {
        price = price - parseInt(related.PaidToDate);
    }
    return <NumberFormat value={price} displayType={'text'} thousandSeparator={" "} suffix={' Ft'} decimalScale={0} />;
};
