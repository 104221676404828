import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { IonAlert, IonButton, IonCard, IonCardContent, IonInput, IonItem, IonLabel, IonLoading, IonSelect, IonSelectOption, useIonLoading } from "@ionic/react";

import { useAuth } from "../../context/Auth";
import PageWrapper from "../layouts/PageWrapper";
import EmptyText from "../../components/EmptyText";
import { deleteRelation, fetchRelationsForBasedata } from "../../services/RelationServices";

import "./DatamodPage.scss";
import useFormValidation from "../../helpers/FormValidationHelper";
import { addApiErrors, ErrorsList, hasError, setInitError, setLoading } from "../../helpers/GlobalHelpers";
import ValidateCancel from "../../helpers/validators/ValidateCancel";
import { postCancelUser } from "../../services/GlobalServices";

const INIT_STATE = {
  password: "",
};

const CancelPage: React.FC = () => {
  const { handleChange, values } = useFormValidation(INIT_STATE);
  const { checkAxiosError } = useAuth();
  const [presentLoading, dismissLoading] = useIonLoading();

  const [showAlert, setShowAlert] = useState<string | null>(null);
  const [status, setStatus] = useState({
    loading: false,
    error_list: INIT_STATE,
  });

  const handlePostCancel = async () => {
    setLoading(true, setStatus);
    setInitError(INIT_STATE, setStatus);

    const errors = ValidateCancel(values);
    if (hasError(errors)) {
      setLoading(false, setStatus);
      addApiErrors(errors, setStatus);
    } else {
      postCancelUser(values.password)
        .then((res: any) => {
          setLoading(false, setStatus);
          setShowAlert("Regisztráció visszavonási igényét elfogadtuk. Munkatársunk hamarosan felveszi Önnel a kapcsolatot.");
        })
        .catch((err: any) => {
          setLoading(false, setStatus);
          addApiErrors(err.response.data.message, setStatus);
          checkAxiosError(err);
        });
    }
  };

  return (
    <PageWrapper>
      <div className="hasButton">
        <h1 className="pageTitle">Regisztráció visszavonása</h1>
      </div>
      <p>Kérlek add meg a jelszavad a a regisztáció visszavonásának megerősítéséhez...</p>

      <IonCard className="datamodList ion-margin-bottom">
        <IonCardContent className="isOpen">
          <form className="form">
            <div>
              <IonItem>
                <IonLabel position="floating">Jelszó</IonLabel>
                <IonInput title="Jelszó" onIonInput={handleChange} clearOnEdit={false} type="password" pattern="password" name="password" value={values.password} required></IonInput>
              </IonItem>
            </div>
          </form>
        </IonCardContent>
      </IonCard>

      <ErrorsList errors={status.error_list} />

      <IonButton onClick={() => handlePostCancel()}>Regisztráció visszavonása</IonButton>

      <IonLoading isOpen={status.loading} message={"Kérem várjon..."} />

      <IonAlert
        isOpen={showAlert !== null}
        onDidDismiss={() => setShowAlert(null)}
        header={"Siker"}
        message={showAlert}
        buttons={[
          {
            text: "Rendben",
            role: "cancel",
          },
        ]}
      />
    </PageWrapper>
  );
};

export default CancelPage;
