import React from "react";

import {
    IonItem,
    IonDatetime,
} from "@ionic/react";

import { FieldModel } from "../../../models/GlobalModels";

interface Props {
    field: FieldModel;
    onChange: (field: FieldModel, value: string) => void;
    isLoading: boolean;
    valueOverwrite?: string;
}

const DateControl: React.FC<Props> = ({ field, onChange, isLoading, valueOverwrite }) => {
    return (
        <>
            <IonItem lines="none">
                <IonDatetime
                    value={valueOverwrite ?? field.formatted_value}
                    onIonChange={(e: any) => {
                        if (e.target.value !== field.value) {
                            onChange(field, e.target.value);
                        }
                    }}
                    doneText="Kész"
                    cancelText="Mégse"
                    disabled={isLoading || !field.is_editable}
                    displayFormat="YYYY. MM. DD."
                    pickerFormat="YYYY. MM. DD."
                    placeholder={field.label??""}
                    monthShortNames={[
                        "Jan",
                        "Feb",
                        "Már",
                        "Ápr",
                        "Máj",
                        "Jún",
                        "Júl",
                        "Aug",
                        "Szep",
                        "Okt",
                        "Nov",
                        "Dec",
                    ]}
                />
            </IonItem>
        </>
    );
};
export default DateControl;
