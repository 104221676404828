import React from "react";

import {
    IonInput,
    IonItem,
} from "@ionic/react";

import { FieldModel } from "../../../models/GlobalModels";

interface Props {
    field: FieldModel;
    onChange: (field: FieldModel, value: string) => void;
    isLoading: boolean;
    valueOverwrite?: string;
}

const TextControl: React.FC<Props> = ({ field, onChange, isLoading, valueOverwrite }) => {
    const getType = () => {
        switch (field.type) {
            case "email":
                return "email";
            case "tel":
                return "tel";
            case "number":
                return "number";
            default:
                return "text";
        }
    }

    const type = getType();

    return (
        <>
            <IonItem lines="none">
                <IonInput
                    value={valueOverwrite ?? field.value}
                    disabled={isLoading || !field.is_editable}
                    onIonBlur={(e: any) => {
                        if (e.target.value !== (valueOverwrite ?? field.value)) {
                            onChange(field, e.target.value);
                        }
                    }}
                    type={type}
                />
            </IonItem>
        </>
    );
};
export default TextControl;
